import React, { useState, useEffect, Suspense } from 'react';
//import PropTypes from "prop-types";
import { Link } from '@reach/router';

import GridSuspenseImage from '../common/GridSuspenseImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import ErrorBoundary from '../error_handling/ErrorBoundary';

import '../../xstyles/venue.css';
import BookingForm from './BookingForm';
import VenueMap from './VenueMap';
import { venNameToVID } from '../../commons/utils';
import Img from '../common/Img';
import iconResolver from '../venue/IconResolver';
import { useWindowWidth } from '../../commons/mobilehooks';
import { Helmet } from 'react-helmet';
import suspense from '../../xres/placeholders/suspense.svg';

// const getCardImage = (label) => {
// 	switch (label) {
// 		case 'value':
// 			return null;
// 		default:
// 			return null;
// 	}
// };

const Gallery = (props) => {
	const { images, handleClick } = props;
	const width = '4rem';
	const height = '3rem';
	return (
		<ul className='property_details_overlay_gallery_tabs'>
			{images.map((image, index) => (
				<Suspense
					delay={`1ms`}
					key={index}
					fallback={
						<img
							src={suspense}
							height={height}
							width={width}
							alt={`property pic no. ${index}`}
							onClick={() => handleClick(image.pic_src)}
						/>
					}>
					<img
						src={image.pic_src}
						height={height}
						width='100%'
						alt={`property pic no. ${index}`}
						className='tab_image'
						loading='lazy'
						decoding='async'
						onClick={() => handleClick(image.pic_src)}
					/>
				</Suspense>
			))}
		</ul>
	);
};

const Content = (props) => {
	const {
		desc,
		ammenities,
		rules,
		cancellation_policy,
		other_charges,
		// lat,
		// lang,
		// gmap_link,
	} = props;
	return (
		<div className='property_content'>
			<section className='venue_section venue_amenities'>
				<h3 className='property_content_subheading'>Amenities</h3>
				<div className='ammenity_container'>
					{ammenities.map((amm, index) => (
						<div key={index} className='ammenity_card'>
							<Img
								src={iconResolver(amm.label)}
								alt={amm.label}
								className='amm_icon'
								width='100%'
								height='100%'
							/>
							<p className='ammenity_label'>{amm.label}</p>
						</div>
					))}
				</div>
			</section>

			<section className='venue_section venue_decription'>
				<h3 className='property_content_subheading'>Property Details</h3>
				<p>{desc}</p>
			</section>

			<section className='venue_section venue_house_rules'>
				<h3 className='property_content_subheading'>House Rules</h3>
				<div className='rule_container'>
					{rules.map((rule, index) => (
						<div key={index} className='rule'>
							<Img
								src={iconResolver(rule.label)}
								alt={rule.label}
								className='rule_icon'
								width='100%'
								height='100%'
							/>
							<div className='rule_desc'>
								<p className='rule_label'>{rule.label + ': '}</p>
								<p className='rule_value'>{rule.value}</p>
							</div>
						</div>
					))}
				</div>
			</section>

			{other_charges && other_charges.length > 0 && (
				<section className='venue_section venue_charges_others'>
					<h3 className='property_content_subheading'>Other Charges</h3>
					<ul className='charges_container'>
						{other_charges.map((charges, index) => (
							<li key={index} className='ven_other_charges'>
								<b role='img' className='charges_indicator'>
									➤
								</b>{' '}
								<span className='charges_label'>
									{charges.charge_name + ': '}
								</span>{' '}
								<span className='charge_value'>₹ {charges.charge_value}</span>
							</li>
						))}
					</ul>
				</section>
			)}

			<div className='venue_section venue_cancellation_policy'>
				<h3 className='property_content_subheading'>Cancellation Policy:</h3>
				{cancellation_policy}
			</div>
		</div>
	);
};

Content.defaultProps = {
	ammenities: [{ icon: '', value: 'Kitchen' }],
	rules: [
		{ icon: '', value: 'Valid Govt ID' },
		{ icon: '', value: 'No Illegal Substances' },
	],
};

const PropertyDetails = (props) => {
	let { index, property, page } = props;
	let {
		pics,
		property_name,
		property_code_number,
		address,
		description,
		amenities,
		house_rules,
		pricing,
		bookings,
		cancellation_policy,
	} = property;

	// console.log('Venue Details: ', property);

	const [transition, setTransition] = useState('property_details_container');
	const [activeImage, setActiveImage] = useState(pics[0].pic_src);

	useEffect(() => {
		setActiveImage(pics[0].pic_src);
		setTransition('property_details_container enter');
		setTimeout(() => setTransition('property_details_container'), 300);
	}, [property, pics]);

	let venue_link = venNameToVID(property);
	const isMobile = useWindowWidth();

	return (
		<div className={transition}>
			<section className='property_details_section'>
				{/* <div className='property_image_detail'>
				
					<GridSuspenseImage src={activeImage} />
				</div> */}
				<section className='property_details_gallery_section'>
					<Suspense
						delay={`1ms`}
						fallback={
							<img
								src={suspense}
								height='100%'
								width='4rem'
								alt={`Loading cover pic for ${property_name} ${property_code_number}`}
							/>
						}>
						<img
							src={activeImage}
							height='100%'
							width='100%'
							alt={property_name + ' ' + property_code_number}
							className='property_image_detail'
							loading='lazy'
							decoding='async'
						/>
					</Suspense>
					<Gallery images={pics} handleClick={setActiveImage} />
				</section>

				{page === 'Details' ? (
					<>
						{property_name === 'PepFarm' ? (
							<Helmet>
								<title>Farmhouse in Delhi for party | Pepspot.com</title>
								<meta
									name='description'
									content='Find best and budget friendly farmhouse in Delhi NCR for a memorable party. Visit our website for more information about our farmhouse in Delhi NCR at best price'
								/>
								<meta name='keywords' content='Farmhouse in delhi for party' />
							</Helmet>
						) : null}
						{isMobile && (
							<article className='venue_id_mobile'>
								<h1 className='head_bold'>
									{property_name + ' ' + property_code_number}
								</h1>
								<p className='head_mini'>
									<FontAwesomeIcon icon={faMapMarkerAlt} />
									&nbsp;
									{address.area + ', ' + address.city}
									<br />
								</p>
							</article>
						)}
						<Content
							desc={description}
							ammenities={amenities}
							rules={house_rules}
							cancellation_policy={cancellation_policy}
							other_charges={pricing.misc}
							// lat={address.latitude}
							// lang={address.longitude}
							// gmap_link={address.gmaps_link}
						/>
					</>
				) : null}
			</section>
			<section className='property_details_form_container'>
				<h1 className='head_bold'>
					{property_name + ' ' + property_code_number}
				</h1>
				<p className='head_mini'>
					<FontAwesomeIcon icon={faMapMarkerAlt} />
					&nbsp;
					{address.area + ', ' + address.city}
					<br />
				</p>
				{page !== 'Details' && (
					<Link className='outer_link' to={`/venue/${venue_link}`}>
						Venue Details
					</Link>
				)}
				<h2 className='prop_price_detail'>
					From <span className='price_high'>₹ {pricing.base}</span>/Night
				</h2>
				<div className='booking_form'>
					<ErrorBoundary>
						{property === props.property && (
							<BookingForm
								venue={props.property}
								bookings={bookings}
								pricing={pricing}
								property_index={index}
							/>
						)}
					</ErrorBoundary>
				</div>
			</section>
			{/* <>
				{page === 'Details' ? (
					<section className='venue_map_container'>
						<p className='property_content_subheading'>
							<b className=''>Map View: </b>
							<FontAwesomeIcon icon={faMapMarkerAlt} />{' '}
							<i>
								{property_name + ' ' + property_code_number},{' '}
								{address.area + ', ' + address.city}
							</i>
						</p>

						<VenueMap
							lat={address.latitudes}
							lang={address.longitudes}
							label={property_name + ' ' + property_code_number}
						/>
					</section>
				) : null}
			</> */}
		</div>
	);
};

PropertyDetails.propTypes = {};

export default PropertyDetails;
