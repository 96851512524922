import React, { lazy, Suspense } from 'react';
import { Link /*, navigate*/ } from '@reach/router';
import { useSelector } from 'react-redux';
// import ImgUnsplash from '../../components/common/ImgUnsplash';

const RequestedBookings = lazy(() =>
	import('../../components/admin/RequestedBookingsCard')
);

const PayPendingBookings = lazy(() =>
	import('../../components/admin/PayPendingBookings')
);

// const TeamCard = lazy(() => import('../../components/admin/PepTeamCard'));

const AdminDashboard = (props) => {
	//#region Local viarbles and hooks
	// const user = props.user;
	const venues_list = useSelector((state) => state.properties.venues_list);
	const bookings_list = useSelector((state) => state.admin.bookings_list);
	//#endregion
	let venues_count = venues_list.length;
	let bookings_count = bookings_list.length;

	let quick_actions = [
		{
			id: '01',
			label: 'Party Venues',
			count: venues_count,
			color: 'var(--col_pep_3)',
			action_primary_link: 'venues/new',
			action_primary_label: '+ Add New Venue',
			action_secondary_link: 'venues',
			action_secondary_label: '# Browse All',
		},
		{
			id: '02',
			label: 'Incoming Bookings',
			count: bookings_count,
			color: '#004bfe',
			action_primary_link: 'bookings/new',
			action_primary_label: '+ Add Guest Booking',
			action_secondary_link: 'bookings',
			action_secondary_label: '# Browse All',
		},
		// {
		// 	id: '03',
		// 	label: 'Incoming Revenue',
		// 	count: '₹969k',
		// 	color: '#00bb5d',
		// 	action_primary_link: 'revenue/new',
		// 	action_primary_label: '+ Update Revenue',
		// 	action_secondary_link: 'revenue',
		// 	action_secondary_label: '# Browse All',
		// },
	];
	return (
		<div className='admin_dash_container'>
			<div className='width_container adm_dash_sections_container'>
				<h4>Quick Actions</h4>
				<section className='adm_dash_action_card_container'>
					{quick_actions.map((card, i) => (
						<div className='qa_card_container' key={i}>
							<div className='qa_card_main'>
								<div
									className='qa_card_icon'
									style={{ backgroundColor: card.color }}
								/>
								<div className='qa_card_info'>
									<h2 className='qa_card_count'>{card.count}</h2>
									<p className='qa_card_label'>{card.label}</p>
								</div>
							</div>
							<div className='qa_card_actions'>
								<Link
									to={card.action_secondary_link}
									className='qa_card_cta_secondary admin_links'>
									{card.action_secondary_label}
								</Link>
								<Link
									to={card.action_primary_link}
									className='qa_card_cta_primary admin_links'
									style={{ color: card.color }}>
									{card.action_primary_label}
								</Link>
							</div>
						</div>
					))}
				</section>
				<Suspense fallback={<h4>Loading admin panel data...</h4>}>
					<RequestedBookings />
					<PayPendingBookings />
					{/* <TeamCard /> */}
				</Suspense>
			</div>
		</div>
	);
};

AdminDashboard.propTypes = {};

AdminDashboard.defaultProps = {};

export default AdminDashboard;
