import React, { Suspense, lazy, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Router, navigate, Link } from '@reach/router';
import { useSelector, useDispatch, batch } from 'react-redux';
import { Helmet } from 'react-helmet';

import '../../xstyles/admin_home.css';
import AdminDashboard from './AdminDashboard';
import NavLink from '../../components/nav/NavLink';
import ImgUnsplash from '../../components/common/ImgUnsplash';
import { fetchBookings, fetchUsers } from '../../store/actions/actions_admin';
import Loader from '../../components/common/Loader';

// const AdminLogin = lazy(() => import('./AdminLogin'));
const ManageVenues = lazy(() => import('./ManageVenues'));
const ManageBookings = lazy(() => import('./ManageBookings'));
const ManageUsers = lazy(() => import('./ManageUsers'));
const ManageTeam = lazy(() => import('./ManageTeam'));
const ManageVenueForm = lazy(() => import('./ManageVenueForm'));
const ManageBookingForm = lazy(() => import('./ManageBookingForm'));
const ManageUserForm = lazy(() => import('./ManageUserForm'));
const ManageTeamForm = lazy(() => import('./ManageTeamForm'));
const NotFound = lazy(() => import('../NotFound'));
// const ManageRevenue = lazy(() => import('./ManageRevenue'));

//#region Admin Routes
export const routes_admin = {
	home: { name: 'Admin Home', link: '/admin_home', route: '/admin_home' },
	admin_login: {
		name: 'Admin Login',
		link: 'admin_login',
		route: 'admin_login',
	},
	manage_venues: {
		name: 'Manage Venues',
		link: 'venues',
		route: 'venues',
	},
	new_venue: {
		name: 'New Venue',
		link: 'venues_new',
		link_outer: 'venues/venues_new',
		route: 'venues_new',
	},
	manage_users: {
		name: 'Users',
		link: 'users',
		route: 'users',
	},
	new_user: {
		name: 'New User',
		link: 'users_new',
		link_outer: 'users/users_new',
		route: 'users_new',
	},
	manage_team: { name: 'Team', link: 'team', route: 'team' },
	new_team: {
		name: 'New Team',
		link: 'team_new',
		link_outer: 'team/team_new',
		route: 'team_new',
	},
	manage_bookings: {
		name: 'bookings',
		link: 'bookings',
		route: 'bookings',
	},
	new_booking: {
		name: 'New Booking',
		link: 'booking_new',
		link_outer: 'bookings/booking_new',
		route: 'bookings_new',
	},
	manage_payment_pending: {
		name: 'Team',
		link: 'bookings/payment_pending',
		route: 'payment_pending',
	},
	manage_booking_requests: {
		name: 'Team',
		link: 'bookings/booking_requests',
		route: 'booking_requests',
	},
};
//#endregion Admin Routes

const AdminPanel = (props) => {
	const user = useSelector((state) => state.user);
	const userAuth = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();

	// const bookings_list = useSelector((state) => state.admin.bookings_list);
	useEffect(() => {
		if (user !== null && user.role && user.role !== null) {
			if (user.role === 'admin' || user.role === 'caesar') {
				batch(() => {
					dispatch(fetchBookings());
					dispatch(fetchUsers());
				});
				console.log(
					'[AdminPanel.js] > user authorized to acess admin panel. User is a:',
					user.role
				);
			} else {
				console.log(
					'[AdminPanel.js] > user not authorized to acess admin panel. User is a:',
					user.role
				);
				navigate('/');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, user]);

	// let loading_user = !user.uid && user.fetchingUserData;
	// console.log('Is loading user? :', loading_user);

	// if (!user.uid) {
	// 	console.log('User Id seems to be null: ', { user, user_role: user.role });
	// 	return (
	// <div className='admin_home_container width_container'>
	// 	<Loader size={80} text='...Awaiting User Data...' />
	// </div>
	// 	);
	// } else {

	// }

	// if (
	// 	!user.fetchingUserData &&
	// 	user.uid &&
	// 	(user.role === 'team' || user.role === 'admin' || user.role === 'caesar')
	// ) {

	return (
		<div className='admin_home_container'>
			<Helmet>
				<title>Peppy Admin Panel</title>
				<meta
					name='description'
					content='OmniChannel Home for Pepspot Admins'
				/>
				<meta name='theme-color' content='#008f68' />
			</Helmet>
			<div className='admin_dash_header'>
				<ImgUnsplash
					className='admin_dash_header_pic'
					src={props.img}
					alt='Fun House Party'
				/>
				<div className='admin_header_content'>
					<div className='admin_header_info'>
						<h1 className='head_mini'>PEPSPOT</h1>
						<h2 className='adm_welcome head_bold'>
							Welcome {user.name_given !== null ? user.name_given : 'admin'},
						</h2>
						<h3 className='adm_header_info_subtitle head_thin'>
							We've hosted <span className='head_bold'>400+</span> parties.
						</h3>
						<div className='adm_stat_view_container '>
							Go to:{' '}
							<Link to={'/admin_home'} className='admin_links'>
								Admin Dashboard
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className='adm_links_container'>
				<NavLink to={'/admin_home'}>Admin Dashboard</NavLink> |{'  '}
				<NavLink to={'/admin_home/venues'}>Manage Venues</NavLink> |{'  '}
				<NavLink to={'/admin_home/bookings'}>Manage Booking</NavLink> |{'  '}
				<NavLink to={'/admin_home/bookings/pending_approval'}>
					Manage Booking Requests
				</NavLink>{' '}
				|{'  '}
				<NavLink to={'/admin_home/bookings/pending_payment'}>
					Manage Pending Payments
				</NavLink>{' '}
				|{'  '}
				<NavLink to={'/admin_home/users'}>Manage Users</NavLink> |{'  '}
				<NavLink to={'/admin_home/team'}>Manage Team</NavLink>
			</div>
			<Suspense
				fallback={
					<div className='admin_home_container width_container'>
						<Loader size={80} text='...Loading Page Data...' />
					</div>
				}>
				<Router className='admin_sections_container'>
					<AdminDashboard path={'/'} user={user} />
					{/* <AdminLogin path={routes_admin.admin_login.route} /> */}
					<ManageVenues path={'venues'} />
					<ManageVenueForm path={'venues/:venueid'} />
					{/* if Id is new, don't	fetch data */}
					<ManageBookings path={'bookings'} />
					<ManageBookings path={'bookings/pending_approval'} />
					<ManageBookings path={'bookings/pending_payment'} />
					<ManageBookingForm path={'bookings/:bookingid'} />
					<ManageUsers path={'users'} />
					<ManageUsers path={'users/authenticated'} />
					<ManageUsers path={'users/admin'} />
					<ManageUsers path={'team'} />
					<ManageUserForm path={'users/:userid'} />
					<ManageUserForm path={'team/:userid'} />
					{/* if Id is new, don't	fetch data */}
					{/* <ManageTeam path={'teams'} />
					<ManageTeamForm path={'teams/:teamid'} /> */}
					{/* if Id is new, don't	fetch data */}

					<NotFound default />
				</Router>
			</Suspense>
		</div>
	);
	// }

	// else {
	// 	console.group(
	// 		"Uh-oh! Seems like you're trying to access a page that doesn't exist!"
	// 	);
	// 	alert(
	// 		"Uh-oh! Seems like you're trying to access a page that doesn't exist!"
	// 	);
	// 	navigate('/');
	// 	return null;
	// }
	// if(user.role !== 'team' &&
	// user.role !== 'admin' &&
	// user.role !== 'caesar')

	// // user is done loading && user doens't have required authorization
	// if (
	// 	!loading_user &&

	// )
	// // user is done loading && user has required authorization
	// else  else {

	// }
};

AdminPanel.propTypes = {};

AdminPanel.defaultProps = {
	img:
		'https://images.unsplash.com/photo-1517457373958-b7bdd4587205?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600',
};

export default AdminPanel;

// useEffect(() => {
// 	if (user.uid !== null) {
// 		if (
// 			user.role !== 'team' &&
// 			user.role !== 'admin' &&
// 			user.role !== 'caesar'
// 		) {
// 			console.group(
// 				'[AdminPanel.js] > user not authorized to acess admin panel'
// 			);
// 			console.log('User Access is: ', user.role);
// 			console.log('Authorized Access is: ', 'team' || 'admin' || 'caesar');
// 			navigate(`./`);
// 		}
// 	}
// }, [user]);

// else {
// 	if (
// 		user.role !== 'team' &&
// 		user.role !== 'admin' &&
// 		user.role !== 'caesar'
// 	) {
// 		console.log('User Access is: ', user.role);
// 		console.log('Authorized Access is: ', 'team' || 'admin' || 'caesar');
// 		navigate(`./`);
// 		return null;
// 	} else if (
// 		user.role === 'team' ||
// 		user.role === 'admin' ||
// 		user.role === 'caesar'
// 	) {

// 	}
// }
// if (
// 	user &&
// 	user.role &&
// 	(user.role === 'team' || user.role === 'admin' || user.role === 'caesar')
// ) {

// }
// } else if (
// 	user &&
// 	user.role &&
// 	(user.role === 'authenticated' || user.role === 'visitor')
// ) {
// 	console.log(
// 		'User not authorized to access this page. Returning to home.',
// 		user.role
// 	);
// 	navigate('/');
// 	return null;
//}
// else {
// 	console.log(user, user.role);
// 	return <div className='Admin Loader'>...Awaiting User Data...</div>;
// }

/* 
	<ManageVenues path={routes_admin.manage_venues.route}>
		<ManageVenueForm path={routes_admin.new_venue.route} />
		<ManageVenueForm path={':venueid'} />
	</ManageVenues>
	<BookingsContainer path={'bookings'}>
		<ManageBookings path={'/'} />
		<ManageBookings path={':managerParams'} />
	</BookingsContainer>
	<BookingItem path={'booking'}>
		<ManageBookingForm path={'/'} />
		<ManageBookingForm path={':bookingid'} />
	</BookingItem> 
	*/
/* 
	<ManageBookingForm
		path={
			routes_admin.manage_bookings.route +
			'/' +
			routes_admin.new_booking.route
		}
	/>
	<ManageBookingForm
		path={routes_admin.manage_bookings.route + '/:bookingid'}
	/> 
*/
/* 
	<ManageUsers path={routes_admin.manage_users.route}>
		<ManageUserForm path={routes_admin.new_user.route} />
		<ManageUserForm path={':userid'} />
	</ManageUsers>
	<ManageTeam path={routes_admin.manage_team.route}>
		<ManageTeamForm path={routes_admin.new_team.route} />>
		<ManageTeamForm path={':teamid'} />>
	</ManageTeam> 
*/
