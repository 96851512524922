import { createAction, createReducer } from '@reduxjs/toolkit';
// import { timeLogger } from '../commons';
// import { batch } from 'react-redux';
// import { getAuthRef, gProvider, usersCollectionRef } from '../../services/fb';
export const gotUserInitData = createAction('New User Data Fetched');

export const fetchingUserData = createAction('user data fetch: initialized');
export const gotUserData = createAction('user data fetched');
export const errorGettingUserData = createAction('user data fetch: ERROR');

export const updatingUserData = createAction('USER_DATA_UPDATE_INIT');
export const updatedUserData = createAction('user data update: Success');
export const errorUpdatingUserData = createAction('user data update: ERROR');

export const gotUserEmail = createAction('USER_DATA_GOT_EMAIL');
export const gotUserPhone = createAction('USER_DATA_GOT_PHONE');
export const gotFirstTimeUser = createAction('USER_DATA_GOT_FIRST_TIMER');

export const isFirstTimeUser = createAction('USER_FIRST_INIT_DATA'); //Log account creation date
export const isReturningUser = createAction('USER_FIRST_INIT_DATA'); //Log lastest login date

export const creatingUserData = createAction('USER_DATA_CREATE_INIT');
export const createdUserData = createAction('USER_DATA_CREATED');
export const errorCreatingUserData = createAction('USER_DATA_CREATION_ERROR');

export const addingUserAddress = createAction(
	'Adding new user address: initialized'
);
export const addedUserAddress = createAction(
	'Adding new user address: completed'
);
export const errorAddingUserAddress = createAction(
	'Adding new user address: Error'
);

export const editingUserAddress = createAction(
	'Editing new user address: initialized'
);
export const editedUserAddress = createAction(
	'Editing new user address: completed'
);
export const errorEditingUserAddress = createAction(
	'Editing new user address: Got Error'
);

export const gotNewBooking = createAction('User initiated new Booking');
// export const
export const checkout_unconfirmed = createAction('Booking added to checkout');
export const checkout_confirmed = createAction(
	'Booking confirmed from checkout'
);
//check_out confirmed contains the data for payment and the payment link

export const bookingCreationStarted = createAction('Booking Creation Started');
export const bookingCreated = createAction('Booking Creation Completed');
export const bookingCreationFailed = createAction('Booking Creation Failed');

export const clearCart = createAction('Cleaning cart on completed Transaction');

export const bookings_data_fetching = createAction('Fetching User Bookings');
export const bookings_data_fetched = createAction('Fetched User Bookings');
export const bookings_data_fetch_error = createAction(
	'Got Error in Fetching User Bookings'
);

export const order_payLink_generating = createAction(
	'Generating Payment Link for order'
);
export const order_payLink_generated = createAction(
	'Generated Payment Link for order'
);
export const order_payLink_generate_error = createAction(
	'Got Error in Generating Payment Link for order'
);

const initialState = {
	// status verbs
	creatingUser: false,
	fetchingUserData: false,

	// status flags
	is_new_user: false,
	is_returning_user: false,
	is_updating_user: false,
	is_logged_in: false,
	is_fetching_bookings_data: false,
	is_generating_pay_link: false,
	is_fetching_order_data: false,

	// login data
	firstLogin: null,
	lastLogin: null,

	// user data
	uid: null,
	email_id: null,
	phone_number: null,

	name_given: null,
	name_family: null,
	company: null,

	addresses: [
		// '01': {
		// 	id: null,
		// 	label: null, //Type of address
		// 	flat_num: null,
		// 	locality: null,
		// 	area: null,
		// 	pincode: null,
		// 	city: null,
		// 	state: null,
		// 	country: null,
		// },
	],
	isPayingCustomer: false,
	role: 'visitor',
	access: 'free',

	comments: {},

	cart: [
		// {
		// 	check_in: '2020-10-16T12:00:00+05:30',
		// 	check_out: '2020-10-17T12:00:00+05:30',
		// 	guest_count: 5,
		// 	cost_breakdown: {
		// 		days: 1,
		// 		weekends: 1,
		// 		specials: 0,
		// 		extra_guests: -10,
		// 		base_cost: 1800,
		// 		coupon: '',
		// 		discount_total: 0,
		// 		tax_rate: '.12',
		// 		total_tax: 216,
		// 		total_cost: 2016,
		// 	},
		// 	booking_id: '',
		// 	venue_requiresAdminApproval: false,
		// 	venue_docref: 'New_Delhi_1590147239947',
		// 	venue_selected: {
		// 		venue_id: 'New_Delhi_1590147239947',
		// 		venue_pic:
		// 			'https://images.unsplash.com/photo-1589812308278-391d648491c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80',
		// 		venue_name: 'PepVilla 501',
		// 		venue_location: 'Vasant Vihar, New Delhi',
		// 		venue_pricing_onBooking: {
		// 			guest_limit_lower: 15,
		// 			date_range: [
		// 				{
		// 					end_date: '2020-05-28',
		// 					price: '2000',
		// 					start_date: '2020-05-25',
		// 					message: 'High Demand',
		// 				},
		// 			],
		// 			base: '1600',
		// 			misc: [
		// 				{
		// 					charge_value: '300',
		// 					charge_name: 'Extra Mattresses',
		// 				},
		// 			],
		// 			weekend: '1800',
		// 			cost_per_guest: 500,
		// 			tax_rate: '.12',
		// 		},
		// 	},
		// },
	],

	checkout_cart: {
		// customer_id: 'Greg_House',
		// bookingDocRef: 'Greg_House_1590751769576',
		// booking_id: 'Greg_House_1590751769576',
		// booked_on: '1590751769576',
		// booking_verified_by_user: false,
		// booked_by: 'Greg House',
		// booking_note: `Booked by Greg House on 1590751769576`,
		// status: 'pending_approval',
		// isApproved: true,
		// check_in: '2020-06-03T12:00:00+05:30',
		// check_out: '2020-06-04T12:00:00+05:30',
		// guest_count: 10,
		// cost_breakdown: {
		// 	days: 1,
		// 	weekends: 0,
		// 	specials: 0,
		// 	extra_guests: -5,
		// 	base_cost: 1600,
		// 	coupon: '',
		// 	discount_total: 0,
		// 	tax_rate: '.12',
		// 	total_tax: 192,
		// 	total_cost: 1792,
		// },
		// venue_docref: 'New_Delhi_1590147239947',
		// venue_selected: {
		// 	venue_id: 'New_Delhi_1590147239947',
		// 	venue_pic:
		// 		'https://images.unsplash.com/photo-1589812308278-391d648491c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80',
		// 	venue_name: 'Pep Villa 501',
		// 	venue_location: 'Vasant Vihar, New Delhi',
		// 	venue_bookings: [],
		// 	venue_pricing_onBooking: {
		// 		weekend: '1800',
		// 		guest_limit_lower: 15,
		// 		tax_rate: '.12',
		// 		date_range: [
		// 			{
		// 				message: 'High Demand',
		// 				price: '2000',
		// 				start_date: '2020-05-25',
		// 				end_date: '2020-05-28',
		// 			},
		// 		],
		// 		misc: [
		// 			{
		// 				charge_name: 'Extra Mattresses',
		// 				charge_value: '300',
		// 			},
		// 		],
		// 		base: '1600',
		// 		cost_per_guest: 500,
		// 	},
		// },
		// billing: {
		// 	cust_id: 'Greg_House',
		// 	customer_name: 'Greg House',
		// 	phone: '+917011467321',
		// 	email: 'aditya.mishra@dynamix.co.in',
		// 	company: 'FGC Ltd.',
		// 	flat_num: '1105',
		// 	locality: 'DDS SFA Flats',
		// 	area: 'Pocket 1, Sector 512, Dwarka',
		// 	city: 'New Delhi',
		// 	pincode: '110075',
		// 	state: 'NCR',
		// 	country: 'India',
		// },
	},
	confirmed_cart: {},
	completed_cart: {},

	bookings: [],
	bookings_list: [],
	transactions: [],
	// hasOrders: [],

	gotError: false,
	error_code: false,
	error_message: null,
};

const userReducer = createReducer(initialState, {
	[fetchingUserData.type]: (state) => {
		// console.log('[user_reducer] > Auth Process Started');
		state.fetchingUserData = true;
		state.is_new_user = true;
	},

	[gotUserInitData.type]: (state, action) => {
		state.fetchingUserData = false;
		console.log(
			'[user_reducer] > Got User Initial Data payload: ',
			action.payload
		);
		state.is_new_user = action.payload.is_new_user;
		state.uid = action.payload.newUser.uid;
		state.email_id = action.payload.newUser.email;
		state.phone_number = action.payload.newUser.phoneNum;
		state.name_given = action.payload.newUser.displayName;
	},

	[createdUserData.type]: (state, action) => {
		// console.log(
		// 	'[user_reducer > createdUserData > 1] > Got User Data payload: ',
		// 	action.payload
		// );
		let { user, docRef } = action.payload;
		let newState = { ...state, ...user, userDocRef: docRef };
		// console.log('[user_reducer > createdUserData > 2] > New State is: ', newState);
		return newState;
	},

	[gotUserData.type]: (state, action) => {
		// console.log(
		// 	'[user_reducer > gotUserData > 1] > Got User Data: ',
		// 	action.payload
		// );
		let { user, docRef } = action.payload;
		let newState = {
			...state,
			...user,
			userDocRef: docRef,
			is_returning_user: true,
			fetchingUserData: false,
			is_new_user: false,
		};
		// console.log('[user_reducer > gotUserData > 2] > New State is: ', newState);
		return newState;
	},

	[updatingUserData.type]: (state) => {
		// console.log(
		// 	'[user_reducer > createdUserData] > Got User Address payload: ',
		// 	action.payload
		// );
		state.is_updating_user = true;
	},

	[updatedUserData.type]: (state, action) => {
		const user_updated = action.payload;

		console.log(
			'[user_reducer > updatedUserData] > Updated User Data: ',
			user_updated
		);

		let newState = {
			...state,
			...user_updated,
			is_updating_user: false,
		};

		return newState;
	},

	[errorUpdatingUserData.type]: (state, action) => {
		console.log(
			'[user_reducer > errorUpdatingUserData] > Got Error Updating User data:',
			action.payload
		);

		state.is_updating_user = false;
		state.gotError = true;
		state.error_code = true;
		state.error_message = action.payload;
	},

	[bookings_data_fetching.type]: (state) => {
		state.is_fetching_bookings_data = true;
	},

	[bookings_data_fetched.type]: (state, action) => {
		state.bookings_list = action.payload;
		state.is_fetching_bookings_data = false;
	},

	[bookings_data_fetch_error.type]: (state, action) => {
		state.error_message = action.payload;
		state.is_fetching_bookings_data = false;
	},

	//#region User Address Reducer Slice
	[addingUserAddress.type]: (state, action) => {
		console.log('Adding New address to user');
		return {
			...state,
			addingNewAddress: true,
		};
	},

	[addedUserAddress.type]: (state, action) => {
		const { addrs } = action.payload;
		console.log(
			'[user_reducer > createdUserData] > Got User Address payload: ',
			action.payload
		);
		state.addresses = addrs;
	},

	[editedUserAddress.type]: (state, action) => {
		const { addrs } = action.payload;
		console.log(
			'[user_reducer > createdUserData] > Got User Address payload: ',
			action.payload
		);
		state.addresses = addrs;
	},
	//#endregion

	//#region User Booking Reducer Slice
	[gotNewBooking.type]: (state, action) => {
		// console.log(
		// 	'[user_reducer > gotNewBooking] > Got Booking payload: ',
		// 	action.payload
		// );
		const { book } = action.payload;
		// state.cart = book;
		state.cart.push(book);
		// console.log(
		// 	'[user_reducer > gotNewBooking] > Got New Cart Item: ',
		// 	state.cart
		// );
	},
	[checkout_unconfirmed.type]: (state, action) => {
		let new_booking = action.payload;
		console.log(
			'[reducer_user > checkout_uncf] 0. Checkout Booking Data is: ',
			new_booking
		);

		state.checkout_cart = {
			...new_booking,
		};
	},

	[checkout_confirmed.type]: (state, action) => {
		let confirmed_booking = action.payload;
		console.log(
			'[checkout_confirmed] Checkout Booking Data Confirmed: ',
			confirmed_booking
		);

		state.confirmed_cart = {
			...confirmed_booking,
		};
	},

	[bookingCreationStarted.type]: (state) => {
		state.booking_db_status = true;
	},

	[bookingCreated.type]: (state, action) => {
		let { new_booking } = action.payload;
		state.booking_db_status = false;
		console.log(
			'[reducer_user > bookingCreated] Incoming Booking:',
			new_booking
		);

		state.bookings.push({ ...new_booking });
	},

	[bookingCreationFailed.type]: (state, action) => {
		let { payload } = action;
		state.venue_list_status = 'Error Creating Booking';

		state.booking_db_status = false;
		state.bookings_errors = payload;
		console.log('[user_reducer] > ', state.booking_db_status, ': ', payload);
	},

	[clearCart.type]: (state) => {
		state.cart = [];
		state.checkout_cart = {};
		state.confirmed_cart = {};
	},
	//#endregion
});

export default userReducer;
// [gotErrorAuthenticatingUser.type]: (state, action) => {
// 	state.authing = false;
// 	state.gotError = true;
// 	state.error = action.payload.error;
// },
// [gettingUserData.type]: (state) => {
// 	state.getting = true;
// },
// [gotUserExists.type]: (state, action) => {
// 	state.getting = false;
// 	// state.user = action.payload.newUser;
// 	// state.userProfileId = action.payload.newUser.profileId;
// },
// [gotErrorCheckingUserData.type]: (state, action) => {
// 	state.getting = false;
// 	state.gotError = true;
// 	state.error = action.payload.error_code;
// },
// [creatingUser.type]: (state) => {
// 	state.creatingUser = true;
// 	state.authing = true;
// },
// [createdUser.type]: (state, action) => {
// 	state.authing = false;
// },
// [gotErrorCreatingUser.type]: (state, action) => {
// 	state.authing = false;
// 	state.gotError = true;
// 	state.error = action.payload.error_code;
// },
// {
// booking_id: 'Greg_House_1590751769576',
// booked_on: 1590751769576,
// check_in: '2020-06-03T12:00:00+05:30',
// check_out: '2020-06-04T12:00:00+05:30',
// guest_count: 10,
// cost_breakdown: {
// 	days: 1,
// 	weekends: 0,
// 	specials: 0,
// 	extra_guests: -5,
// 	base_cost: 1600,
// 	coupon: '',
// 	discount_total: 0,
// 	tax_rate: '.12',
// 	total_tax: 192,
// 	total_cost: 1792,
// },
// billing: {
// 	cust_id: 'Greg_House',
// 	customer_name: 'Greg House',
// 	company: 'FGC Ltd.',
// 	email: 'aditya.mishra@dynamix.co.in',
// 	phone: '+917011467321',
// 	flat_num: '1105',
// 	locality: 'DDS SFA Flats',
// 	area: 'Pocket 1, Sector 512, Dwarka',
// 	city: 'New Delhi',
// 	pincode: '110075',
// 	state: 'NCR',
// 	country: 'India',
// },
// status: 'pending_approval',
// isApproved: false,
// propertyDocRef: 'New_Delhi_1590147239947',
// property_id: 'New_Delhi_1590147239947',
// property_name: 'Pep Villa 501',
// property_location: 'Vasant Vihar, New Delhi',
// property_pic:
// 	'https://images.unsplash.com/photo-1589812308278-391d648491c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80',
// property_pricing_onBooking: {
// 	weekend: '1800',
// 	guest_limit_lower: 15,
// 	tax_rate: '.12',
// 	date_range: [
// 		{
// 			message: 'High Demand',
// 			price: '2000',
// 			start_date: '2020-05-25',
// 			end_date: '2020-05-28',
// 		},
// 	],
// 	misc: [
// 		{
// 			charge_name: 'Extra Mattresses',
// 			charge_value: '300',
// 		},
// 	],
// 	base: '1600',
// 	cost_per_guest: 500,
// },
// bookingDocRef: 'Greg_House_1590751769576',
// }

// {
// 	booking_id: '',
// 	status: '', //paid, confirmed, cancelled
// 	isApproved: false, //boolean

// 	property_id: '',
// 	property_docref: '',
// 	property_name: '',
// 	property_location: '',
// 	property_pic: '',
// 	property_req_approval: '',
// 	property_bookings: [],
// 	property_pricing: {},

// 	check_in: '', //select form react-dates
// 	check_out: '', //select form react-dates
// 	guest_count: '', //Numeric input by admin

// 	billing: {
// 		cust_id: '', //AutoGenerate in handleSubmitNewBooking
// 		customer_name: '', //Input by admin
// 		company: '', //Input by admin
// 		email: '', //Input by admin
// 		phone: '', //Input by admin

// 		flat_num: '', //Input by admin
// 		locality: '', //Input by admin
// 		area: '', //Input by admin
// 		city: '', //Input by admin
// 		pincode: '', //Input by admin
// 		state: '', //Input by admin
// 		country: 'India',
// 	},
// 	cost_breakdown: {
// 		days: 0,
// 		weekends: 0,
// 		specials: 0,
// 		extra_guests: 0,
// 		base_cost: 0,
// 		coupon: '',
// 		discount_total: '',
// 		tax_rate: '',
// 		total_tax: '',
// 		total_cost: '',
// 	},
// 	payment: {
// 		status: 'new',
// 		transaction_id: '', //auto-gen from booking
// 		currency: 'INR',
// 		method: '', //get response from cashfree
// 		date_paid: '', //get response from cashfree
// 		amount_paid: '', //get response from cashfree
// 		payment_link: '', //generate (hopefully) for redirect.
// 	},
// },

// cart :[
// 	// {
// 	// 	check_in: '2020-06-03T12:00:00+05:30',
// 	// 	check_out: '2020-06-04T12:00:00+05:30',
// 	// 	guest_count: 10,
// 	// 	cost_breakdown: {
// 	// 		days: 1,
// 	// 		weekends: 0,
// 	// 		specials: 0,
// 	// 		extra_guests: -5,
// 	// 		base_cost: 1600,
// 	// 		coupon: '',
// 	// 		discount_total: 0,
// 	// 		tax_rate: '.12',
// 	// 		total_tax: 192,
// 	// 		total_cost: 1792,
// 	// 	},
// 	// 	booking_id: '',
// 	// 	status: '',
// 	// 	isApproved: false,
// 	// 	property_id: 'New_Delhi_1590147239947',
// 	// 	property_pic:
// 	// 		'https://images.unsplash.com/photo-1589812308278-391d648491c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80',
// 	// 	property_name: 'Pep Villa 501',
// 	// 	property_location: 'Vasant Vihar, New Delhi',
// 	// 	property_bookings: [
// 	// 		{
// 	// 			booked_by: 'Owner',
// 	// 			booked_by_name: '',
// 	// 			booked_for_days: '',
// 	// 			message: '3rd party booking',
// 	// 			booked_on: '',
// 	// 			booked_by_id: '',
// 	// 			check_out: '2020-06-02',
// 	// 			check_in: '2020-05-29',
// 	// 			id: '',
// 	// 		},
// 	// 		{
// 	// 			booked_by_name: null,
// 	// 			booked_on: 1590312448869,
// 	// 			booked_by_id: '',
// 	// 			check_out: '2020-05-26T12:00:00+05:30',
// 	// 			check_in: '2020-05-24T12:00:00+05:30',
// 	// 			id: '',
// 	// 		},
// 	// 	],
// 	// 	property_pricing: {
// 	// 		weekend: '1800',
// 	// 		guest_limit_lower: 15,
// 	// 		tax_rate: '.12',
// 	// 		date_range: [
// 	// 			{
// 	// 				message: 'High Demand',
// 	// 				price: '2000',
// 	// 				start_date: '2020-05-25',
// 	// 				end_date: '2020-05-28',
// 	// 			},
// 	// 		],
// 	// 		misc: [
// 	// 			{
// 	// 				charge_name: 'Extra Mattresses',
// 	// 				charge_value: '300',
// 	// 			},
// 	// 		],
// 	// 		base: '1600',
// 	// 		cost_per_guest: 500,
// 	// 	},
// 	// 	property_req_approval: true,
// 	// 	property_docref: 'New_Delhi_1590147239947',
// 	// 	billing: {
// 	// 		cust_id: '',
// 	// 		customer_name: '',
// 	// 		company: '',
// 	// 		email: '',
// 	// 		phone: '',
// 	// 		flat_num: '',
// 	// 		locality: '',
// 	// 		area: '',
// 	// 		city: '',
// 	// 		pincode: '',
// 	// 		state: '',
// 	// 		country: 'India',
// 	// 	},
// 	// 	payment: {
// 	// 		status: 'new',
// 	// 		transaction_id: '',
// 	// 		currency: 'INR',
// 	// 		method: '',
// 	// 		date_paid: '',
// 	// 		amount_paid: '',
// 	// 		payment_link: '',
// 	// 	},
// 	// },
// ]

// {
// 	check_in: '2020-08-04T12:00:00+05:30',
// 	check_out: '2020-08-05T12:00:00+05:30',
// 	guest_count: 8,
// 	cost_breakdown: {
// 		days: 1,
// 		weekends: 0,
// 		specials: 0,
// 		extra_guests: -7,
// 		base_cost: 1600,
// 		coupon: '',
// 		discount_total: 0,
// 		tax_rate: '.12',
// 		total_tax: 192,
// 		total_cost: 1792,
// 	},
// 	booking_id: '',
// 	venue_requiresAdminApproval: false,
// 	venue_docref: 'New_Delhi_1590147239947',
// 	venue_selected: {
// 		venue_id: 'New_Delhi_1590147239947',
// 		venue_pic:
// 			'https://images.unsplash.com/photo-1589812308278-391d648491c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80',
// 		venue_name: 'PepVilla 501',
// 		venue_location: 'Vasant Vihar, New Delhi',
// 		// venue_bookings: [
// 		// 	{
// 		// 		check_in: '2020-07-29T12:00:00+05:30',
// 		// 		booked_on: 1594342243784,
// 		// 		booked_by_name: 'Greg House',
// 		// 		id: 1594342243784,
// 		// 		booked_by: 'User',
// 		// 		booked_by_id: 'Greg_House',
// 		// 		check_out: '2020-07-30T12:00:00+05:30',
// 		// 		booked_pricing: {
// 		// 			coupon: '',
// 		// 			discount_total: 0,
// 		// 			base_cost: 1600,
// 		// 			tax_rate: '.12',
// 		// 			total_tax: 192,
// 		// 			days: 1,
// 		// 			extra_guests: -7,
// 		// 			specials: 0,
// 		// 			total_cost: 1792,
// 		// 			weekends: 0,
// 		// 		},
// 		// 		message:
// 		// 			'Venue "PepVilla 501" booked by Greg House on Fri, 10th Jul, 2020, 6:20:43 AM',
// 		// 		guest_count: 8,
// 		// 	},
// 		// 	{
// 		// 		check_in: '2020-07-28T12:00:00+05:30',
// 		// 		guest_count: 6,
// 		// 		id: 1594343741935,
// 		// 		check_out: '2020-07-29T12:00:00+05:30',
// 		// 		booked_by_id: 'Greg_House',
// 		// 		booked_pricing: {
// 		// 			total_tax: 192,
// 		// 			total_cost: 1792,
// 		// 			tax_rate: '.12',
// 		// 			discount_total: 0,
// 		// 			extra_guests: -9,
// 		// 			specials: 0,
// 		// 			base_cost: 1600,
// 		// 			coupon: '',
// 		// 			days: 1,
// 		// 			weekends: 0,
// 		// 		},
// 		// 		message:
// 		// 			'Venue "PepVilla 501" booked by Greg House on Fri, 10th Jul, 2020, 6:45:41 AM',
// 		// 		booked_by: 'User',
// 		// 		booked_on: 1594343741935,
// 		// 		booked_by_name: 'Greg House',
// 		// 	},
// 		// 	{
// 		// 		booked_by_name: 'Greg House',
// 		// 		guest_count: 14,
// 		// 		check_in: '2020-07-30T12:00:00+05:30',
// 		// 		check_out: '2020-07-31T12:00:00+05:30',
// 		// 		booked_by: 'User',
// 		// 		booked_on: 1594343959480,
// 		// 		booked_by_id: 'Greg_House',
// 		// 		booked_pricing: {
// 		// 			coupon: '',
// 		// 			total_cost: 1792,
// 		// 			base_cost: 1600,
// 		// 			discount_total: 0,
// 		// 			tax_rate: '.12',
// 		// 			days: 1,
// 		// 			extra_guests: -1,
// 		// 			weekends: 0,
// 		// 			specials: 0,
// 		// 			total_tax: 192,
// 		// 		},
// 		// 		id: 1594343959480,
// 		// 		message:
// 		// 			'Venue "PepVilla 501" booked by Greg House on Fri, 10th Jul, 2020, 6:49:19 AM',
// 		// 	},
// 		// 	{
// 		// 		booked_on: 1594872529041,
// 		// 		guest_count: 6,
// 		// 		booked_for_email: 'aditya.mishra@dynamix.co.in',
// 		// 		booked_by_name: 'Greg House',
// 		// 		booked_for_name: 'Greg House',
// 		// 		booked_pricing: {
// 		// 			base_cost: 1800,
// 		// 			weekends: 1,
// 		// 			total_cost: 2016,
// 		// 			specials: 0,
// 		// 			days: 1,
// 		// 			extra_guests: -9,
// 		// 			discount_total: 0,
// 		// 			total_tax: 216,
// 		// 			coupon: '',
// 		// 			tax_rate: '.12',
// 		// 		},
// 		// 		booked_by: 'User',
// 		// 		booked_for_phone: '+917011467321',
// 		// 		booked_for_id: 'Greg_House',
// 		// 		check_in: '2020-07-25T12:00:00+05:30',
// 		// 		message:
// 		// 			'Venue "PepVilla 501" booked by Greg House on Thu, 16th Jul, 2020, 9:38:49 AM',
// 		// 		id: 1594872529041,
// 		// 		check_out: '2020-07-26T12:00:00+05:30',
// 		// 	},
// 		// ],
// 		venue_pricing_onBooking: {
// 			guest_limit_lower: 15,
// 			cost_per_guest: 500,
// 			base: '1600',
// 			weekend: '1800',
// 			tax_rate: '.12',
// 			date_range: [
// 				{
// 					message: 'High Demand',
// 					end_date: '2020-05-28',
// 					price: '2000',
// 					start_date: '2020-05-25',
// 				},
// 			],
// 			misc: [
// 				{
// 					charge_value: '300',
// 					charge_name: 'Extra Mattresses',
// 				},
// 			],
// 		},
// 	},
// },
