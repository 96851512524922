import React from 'react';
// import PropTypes from 'prop-types';

function ErrorFallback({ error }) {
	return (
		<div>
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
		</div>
	);
}

ErrorFallback.propTypes = {};

export default ErrorFallback;
