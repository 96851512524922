//#region Main Libs
import React, {
	Suspense,
	// useState
} from 'react';
//import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBuilding,
	faPhoneAlt,
	faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
//#endregion

//#region project components
import NavLink from './NavLink';
// import { useWindowWidth } from '../../commons/mobilehooks';
import { prop_owner_routes } from '../../App';
//#endregion

//#region styles and image resources
import logo_wm from '../../xres/pepspot_wordmark.svg';
import male_avatar from '../../xres/avatars/male_jacket_shades.svg';
// import house_icon from '../../xres/icons/pep_house_01_optm.svg';
// import { signOut } from '../../store/actions/actions_auth';
import {
	useSelector,
	// useDispatch
} from 'react-redux';
//#endregion

//#region additional components - to be refactored
function UserAvatar(props) {
	const { user } = props;
	// console.log('UserAvatar > props: ', props);

	if (user) {
		return (
			<div
				className='user_avatar_container'
				// onClick={() => props.test_authUser()}
			>
				<p>User</p>
				<Suspense delay={`10ms`} fallback={<h4>Loading the Cool stuff</h4>}>
					<img
						src={male_avatar}
						width='60px'
						height='40px'
						className='user_avatar'
						alt='logo'
						loading='lazy'
						decoding='async'
					/>
				</Suspense>
			</div>
		);
	} else {
		return (
			<div
				className='user_avatar_container'
				// onClick={() => props.test_authUser()}
			>
				<p>Login</p>
				<Suspense delay={`10ms`} fallback={<h4>Loading the Cool stuff</h4>}>
					<img
						src={male_avatar}
						className='user_avatar user_avatar_noauth'
						width='60px'
						height='40px'
						alt='logo'
						loading='lazy'
						decoding='async'
					/>
				</Suspense>
			</div>
		);
	}
}

UserAvatar.propTypes = {};
// export default UserAvatar;

const NavLinksAuth = (props) => {
	//#region hooks and variables
	const { routes } = props;
	// const dispatch = useDispatch();
	//#endregion

	return (
		<nav className='app_nav_links'>
			<div className='authed_links'>
				<NavLink to={routes.home.path}>{routes.home.name}</NavLink>
				<NavLink to={routes.venues.path}>{routes.venues.name}</NavLink>
				<NavLink to={routes.cart.path}>{routes.cart.name}</NavLink>
			</div>
			<NavLink to={routes.user.path}>
				<UserAvatar
					user={true}
					// test_authUser={props.test_authUser}
				/>
			</NavLink>
			{/* <button
				style={{
					backgroundColor: '#FFFFFF00',
					color: 'rgb(255, 89, 89)',
					borderRadius: '20px',
					border: 'solid 2px currentColor',
				}}
				onClick={() => {
					dispatch(signOut());
				}}>
				X
			</button> */}
		</nav>
	);
};

const NavLinksNoAuth = (props) => {
	//#region hooks and variables
	const { routes } = props;
	// console.log('NavLinksNoAuth > props: ', props);
	//#endregion
	return (
		<>
			<a href='mailto:contact@thepepspot.com' className='nav_link nav_email'>
				<FontAwesomeIcon
					icon={faEnvelope}
					aria-label={'email us icon'}
					className='nav_action_icon'
					style={{ width: '1em', height: '1em' }}
				/>
				mail us
			</a>
			<a href='tel:+919996696651' className='nav_link nav_contact'>
				<FontAwesomeIcon
					icon={faPhoneAlt}
					aria-label={'call us icon'}
					className='nav_action_icon'
					style={{ width: '1em', height: '1em' }}
				/>
				+91 999 669 6651
			</a>
			{/* <a href='tel:011-4119-7779' className='nav_link nav_contact'>
				<FontAwesomeIcon
					icon={faPhoneAlt}
					aria-label={'call us icon'}
					className='nav_action_icon'
					style={{ width: '1em', height: '1em' }}
				/>
				011 4119 7779
			</a> */}
			{/* <Link to={routes.contact.path} className='nav_link nav_contact'>
				011 4119 7779
			</Link> */}
			<nav className='app_nav_links_noauth'>
				<NavLink to={routes.login.path} className='nav_link nav_login'>
					<UserAvatar
						user={false}
						// test_authUser={props.test_authUser}
					/>
				</NavLink>
			</nav>
		</>
	);
};

// function MobileNav() {
// 	return (
// 		<div>

// 		</div>
// 	)
// }

// function WidescreenNav() {
// 	return (
// 		<div>

// 		</div>
// 	)
// }

//#endregion

const Navbar = (props) => {
	// const [user, setuser] = useState(false);

	const user = useSelector((state) => state.auth.is_user_authenticated);
	// const handleUserAuth = () => {
	// 	console.log('Authenticatin User');
	// 	setuser(!user);
	// };

	return (
		<header className='app_header'>
			<Link to='/' className='logo_container'>
				<img
					src={logo_wm}
					className='app_logo'
					alt='logo'
					width='60px'
					height='40px'
					loading='lazy'
					decoding='async'
				/>
			</Link>
			<Link
				to={prop_owner_routes.landing.link}
				className='link_prop_owners'
				style={{ display: 'inline-block' }}>
				<div className=''>
					<FontAwesomeIcon
						icon={faBuilding}
						aria-label={'list my building icon'}
						className='nav_action_icon'
						style={{ width: '1em', height: '1em' }}
					/>
					List a property
				</div>
			</Link>
			<section className='links_container'>
				{user ? (
					<NavLinksAuth
						routes={props.routes}
						user={user}
						// test_authUser={handleUserAuth}
					/>
				) : (
					<NavLinksNoAuth
						routes={props.routes}
						// test_authUser={handleUserAuth}
					/>
				)}
			</section>
		</header>
	);
};

Navbar.propTypes = {};

export default Navbar;
