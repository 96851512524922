//#region Main Libs
import React, { Suspense, lazy, useEffect } from 'react';
import {
	Router,
	navigate,
	// createHistory
	globalHistory,
} from '@reach/router';
import { useDispatch, useSelector, batch } from 'react-redux';
import { getAuthRef, usersCollectionRef } from './services/fb';
//#endregion

//#region project components
import Navbar from './components/nav/Navbar';
import Home from './pages/Home';
import Footer from './components/nav/Footer';
import { ScrollToTop } from './components/common/ScrollToTop';
import { gotUserAuthenticated } from './store/reducers/reducer_auth';
import { getBlogs } from './store/actions/actions_blog';
import { blogs } from './api/requests';
import { getVenues } from './store/actions/propertyActions';
// import { initProperties } from './store/reducers/propertyReducer';
import {
	gotUserInitData,
	fetchingUserData,
	gotUserData,
	errorGettingUserData,
} from './store/reducers/reducer_user';

import { routes_admin } from './pages/admins/AdminPanel';
import Loader from './components/common/Loader';
// import { getUserData } from './store/actions/actions_user';
//#endregion

//#region styles and image resources
import './xstyles/app.css';

//#endregion

//#region lazily loaded components
const Discover = lazy(() => import('./pages/Discover'));
const VenuePage = lazy(() => import('./pages/VenuePage'));
const Auth = lazy(() => import('./pages/Auth'));
const User = lazy(() => import('./pages/ProfilePage'));
const Cart = lazy(() => import('./pages/Cart'));
const Checkout = lazy(() => import('./pages/Checkout'));
const PayConfirm = lazy(() => import('./pages/PaymentConf'));
const PayConfReturn = lazy(() => import('./pages/PayConfReturn'));
const Contact = lazy(() => import('./pages/Contact'));
const Media = lazy(() => import('./pages/Media'));
const Blog = lazy(() => import('./pages/Blog'));
const About = lazy(() => import('./pages/About'));
const Tnc = lazy(() => import('./pages/legal/Tnc'));
const Faqs = lazy(() => import('./pages/legal/FAQs'));
const Privacy = lazy(() => import('./pages/legal/Privacy'));
const Refunds = lazy(() => import('./pages/legal/Refunds'));
const NotFound = lazy(() => import('./pages/NotFound'));

// Routes for Property owners
const OwnerLanding = lazy(() => import('./pages/propowners/OwnerLanding'));

// Routes for Admins
const AdminPanel = lazy(() => import('./pages/admins/AdminPanel'));
//#endregion

//#region additional components - to be refactored
// const Invoice = () => {
// 	const params = useParams();
// 	console.log(params);
// 	return (
// 		<div>
// 			{params.invoiceId === '42' ? (
// 				<h1>
// 					You've found the answer to Life, universe and everything with Invoice
// 					id: {params.invoiceId}
// 				</h1>
// 			) : (
// 				<h1>Invoice {params.invoiceId}</h1>
// 			)}
// 		</div>
// 	);
// };

export const routes_nav = {
	home: { name: 'Home', path: '/' },
	venues: { name: 'Venues', path: '/venues' },
	venue_details: { name: 'Venue Page', path: '/venues/:vid' },
	login: { name: 'Login', path: '/login' },
	user: { name: 'Profile', path: '/profile' },
	cart: { name: 'Cart', path: '/cart/' },
	contact: { name: 'Contact', path: '/contact' },
	blog: { name: 'Blog', path: '/blog' },
	media: { name: 'Media', path: '/media' },
};

const routes_sys = {
	faq: { name: 'FAQs', path: '/faqs/' },
	about: { name: 'About', path: '/about' },
	privacy: { name: 'Privacy Policy', path: '/privacy_policy' },
	refunds: { name: 'Refund Policy', path: '/refund_policy' },
	tnc: { name: 'Terms & Conditions', path: '/tnc' },
	checkout: { name: 'Checkout', path: '/checkout/' },
	invoices: { name: 'Invoices', path: '/payment_status' },
	invoice: { name: 'Invoices', path: '/payment_status/:tid' },
};

export const prop_owner_routes = {
	landing: {
		name: 'Landing',
		link: '/propertyOwners',
		route: 'propertyOwners',
	},
};

const links_about = [
	{ name: 'Blogs', to: routes_nav.blog.path },
	{ name: 'Contact Us', to: routes_nav.contact.path },
	{ name: 'About Us', to: routes_sys.about.path },
	{ name: 'Pics Gallery', to: routes_nav.media.path },
];

const links_extras = [
	{ name: 'FAQs', to: routes_sys.faq.path },
	{ name: 'Terms & Conditions', to: routes_sys.tnc.path },
	{ name: 'Privacy Policy', to: routes_sys.privacy.path },
	{ name: 'Refund Policy', to: routes_sys.refunds.path },
];
//#endregion

function App() {
	//#region hooks and variables
	// const [ploading, setPloading] = useState(true);
	const dispatch = useDispatch();

	// const _fetchProperties = async () => {
	// 	const property_list = await getProperties(property);
	// 	//.then(async res => setLoading(false))
	// 	//.catch(err => console.log(err));
	// 	dispatch(initProperties(property_list));
	// 	setPloading(false);
	// };

	useEffect(() => {
		// _fetchProperties();
		batch(() => {
			// dispatch(getBlogs(blogs, 20));
			dispatch(getVenues());
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);
	//#endregion

	//#region Testing Auth
	// let isNew = useSelector((state) => state.user.isFirstTimeUser);
	const authUser = useSelector((state) => state.auth.user);
	const activeUser = useSelector((state) => state.user);
	const serviced_cities = useSelector(
		(state) => state.properties.serviced_cities
	);

	const links_filter =
		serviced_cities.length > 0
			? serviced_cities.map((served_city) => ({
					name: served_city,
					to: `/venues/${served_city}`,
			  }))
			: [
					{ name: 'Delhi', to: '/venues/delhi' },
					{ name: 'Noida', to: '/venues/noida' },
					{ name: 'Gurugram', to: '/venues/gurugram' },
			  ];

	useEffect(() => {
		getAuthRef().onAuthStateChanged((user) => {
			if (user) {
				batch(() => {
					dispatch(gotUserAuthenticated());
					dispatch(fetchingUserData());
				});

				// Dispatch action to get user data matching auth from users collection from db
				usersCollectionRef
					.where('uid', '==', user.uid)
					.get()
					.then((querySnapshot) => {
						if (querySnapshot.empty) {
							// console.log(
							// 	"[App.js > onAuthStateChanged > getUserData] > User doesn't exist on DB, Create a New User"
							// );
							//
							// User has signed in for the first time.
							let newUser = {
								uid: user.uid,
								email: user.email,
								displayName: user.displayName,
								phoneNum: user.phoneNumber,
							};
							// console.group(
							// 	'[App.js > onAuthStateChanged]: User Logged in for the first time.'
							// );
							// // console.group('User Data is: ');
							// // console.log('Auth returned: ', user);
							// console.log('-> UID: ', newUser.uid);
							// console.log('-> Name: ', newUser.displayName);
							// console.log('-> Email: ', newUser.email);
							// console.log('-> Phone Number: ', newUser.phoneNum);
							// console.groupEnd();

							dispatch(
								gotUserInitData({ newUser: newUser, is_new_user: true })
							);

							//Navigate to profile. where fields {email/mobile num} with intial data are disabled to edit by default.
							navigate(`/profile`);

							// create user profile.
							// dispatch(createUser(user));
						} else {
							querySnapshot.forEach((doc) => {
								const incomingData = doc.data();
								// console.log(
								// 	'[App.js > onAuthStateChanged > getUserData] > User Exists with id: ',
								// 	doc.id,
								// 	' & has data: ',
								// 	incomingData
								// );
								dispatch(gotUserData({ user: incomingData, docRef: doc.id }));
								// User is signed in and user exists.
								// console.group(
								// 	'[App.js > onAuthStateChanged]: New Authentication Detected'
								// );
								// console.log('-> Authenticated user:', user.uid);
								// console.log('-> Existing User: ', activeUser.uid);
								activeUser.uid !== null &&
									(user.uid === activeUser.uid
										? console.log('-> User already logged in')
										: console.log('-> Logging out the Active User'));
								// console.groupEnd();
							});
						}
					})
					.catch((error) => {
						const simple_error = {
							error_code: error.code,
							error_msg: error.message,
						};
						console.error(
							'[App.js > onAuthStateChanged] > Error checking user ref: ',
							simple_error
						);
						dispatch(errorGettingUserData({ error: simple_error }));
					});
				// dispatch(getUserData(user));
				// if (activeUser.uid !== null) {
				// } else if (activeUser.uid === null) {
				// }
			} else {
				// User is signed out.
				// dispatch action to set active user as null which in turn should change auth states and only fetch cached posts from fbfs
			}
		});

		// return () => {
		// 	cleanup
		// };
	}, [authUser, dispatch, activeUser.uid]);

	// logic to set up accessible routes for different types of users
	// useEffect(() => {
	// 	if (activeUser && !isNew) {
	// 		console.log('From App.js/  User logged in and is returning user');
	// 		setActiveRoutes(routes_returning_auth);
	// 	} else if (activeUser && isNew) {
	// 		console.log('From App.js/  User logged in and is first time user');
	// 		setActiveRoutes(routes_first_auth);
	// 	} else {
	// 		console.log('From App.js/  User logged out');
	// 		setActiveRoutes(routes_no_auth);
	// 	}
	// 	// return () => {
	// 	// 	cleanup
	// 	// };
	// }, [activeUser, authUser, isNew]);
	//#endregion

	globalHistory.listen(async ({ location }) => {
		await window.ga('send', 'pageview');
		// or use the new gtag API
		window.gtag('config', 'GA_MEASUREMENT_ID', {
			page_path: location.pathname,
		});
	});

	// let history = createHistory(window);
	// useEffect(() => {
	// 	console.log('[App.js]> navigation history: ', history);
	// }, [history]);
	return (
		<div className='app'>
			<Navbar routes={routes_nav} />
			<Suspense
				delay={`50ms`}
				fallback={
					<div style={{ height: '100vh', width: '100%' }}>
						<div className='width_container'>
							<Loader size={100} text='...Fetching Peppy Party Wonders...' />
						</div>
					</div>
				}>
				<Router className='pages_container' primary={false}>
					<ScrollToTop path='/'>
						<Home path='/' />
						<Home path='/peppyparties' />
						<Discover path={'/venues'} />
						<Discover path={'/venues/:loc'} />
						<VenuePage path={'/venue/:vid'} />
						<Auth path={routes_nav.login.path} />
						<User path={routes_nav.user.path + '/*'} />
						<Cart path={routes_nav.cart.path} />
						<Checkout path={routes_sys.checkout.path} />
						<PayConfirm path={routes_sys.invoices.path} />
						<PayConfReturn path={routes_sys.invoice.path} />
						<Contact path={routes_nav.contact.path} />
						<Media path={routes_nav.media.path} />
						<About path={routes_sys.about.path} />
						<Blog path={routes_nav.blog.path} />
						<Tnc path={routes_sys.tnc.path} />
						<Faqs path={routes_sys.faq.path} />
						<Privacy path={routes_sys.privacy.path} />
						<Refunds path={routes_sys.refunds.path} />
						<OwnerLanding path={prop_owner_routes.landing.route} />
						<AdminPanel path={routes_admin.home.route + '/*'} />
						<NotFound default />
					</ScrollToTop>
				</Router>
			</Suspense>
			<Footer
				filters={links_filter}
				about={links_about}
				extras={links_extras}
			/>
		</div>
	);
}

export default App;
