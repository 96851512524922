import { createAction, createReducer } from '@reduxjs/toolkit';
// import { timeLogger } from '../commons';
// import { batch } from 'react-redux';
// import { getAuthRef, gProvider, usersCollectionRef } from '../../services/fb';

export const gotUserAuthenticated = createAction('User Logged In');
export const gotUserUnAuthenticated = createAction('User Logged Out');

export const authenticatingUser = createAction('USER_AUTH_INIT');
export const authenticatedUserOnEmail = createAction(
	'User Auth via email : DONE'
);
export const authenticatedUserOnMobile = createAction(
	'User Auth via email : DONE'
);
export const gotErrorAuthenticatingUser = createAction('USER_AUTH_ERROR');

export const gettingUserData = createAction('USER_DATA_CHECK');
export const gotUserExists = createAction('USER_DATA_EXISTS');
export const gotErrorCheckingUserData = createAction('USER_DATA_CHECK_ERROR');

export const creatingUser = createAction('USER_DATA_INITIALIZING');
export const createdUser = createAction('USER_DATA_INITIALIZED');
export const gotErrorCreatingUser = createAction('USER_DATA_INIT_ERROR');

const initialState = {
	is_user_authenticated: false,
	authing: false,
	creatingUser: false,
	gettingData: false,
	userProfileId: null,
	user: null,
	gotError: false,
	error: false,
};

const authReducer = createReducer(initialState, {
	[authenticatingUser.type]: (state) => {
		console.log('[reducer_auth] > Auth Process Started');
		state.authing = true;
	},
	[gotUserAuthenticated.type]: (state, action) => {
		// console.log('[reducer_auth] > ', gotUserAuthenticated.type);
		// console.log('[reducer_auth] > action data: ', action);
		state.is_user_authenticated = true;
	},
	[gotUserUnAuthenticated.type]: (state, action) => {
		console.log('[reducer_auth] > ', gotUserUnAuthenticated.type);
		state.is_user_authenticated = false;
	},
	[authenticatedUserOnEmail.type]: (state, action) => {
		state.authing = false;
		let { newUser } = action.payload;
		state.user = newUser;
		state.userProfileId = newUser.profileId;
	},
	[authenticatedUserOnMobile]: (state, action) => {
		state.authing = false;
		let { newUser } = action.payload;
		state.user = newUser;
		state.uid = newUser.uid;
	},
	[gotErrorAuthenticatingUser.type]: (state, action) => {
		state.authing = false;
		state.gotError = true;
		state.error = action.payload.error;
	},
	[gotUserExists.type]: (state, action) => {
		state.getting = false;
		// state.user = action.payload.newUser;
		// state.userProfileId = action.payload.newUser.profileId;
	},
	// [gettingUserData.type]: (state) => {
	// 	state.getting = true;
	// },

	// [gotErrorCheckingUserData.type]: (state, action) => {
	// 	state.getting = false;
	// 	state.gotError = true;
	// 	state.error = action.payload.error_code;
	// },
	// [creatingUser.type]: (state) => {
	// 	state.creatingUser = true;
	// 	state.authing = true;
	// },
	// [createdUser.type]: (state, action) => {
	// 	state.authing = false;
	// },
	// [gotErrorCreatingUser.type]: (state, action) => {
	// 	state.authing = false;
	// 	state.gotError = true;
	// 	state.error = action.payload.error_code;
	// },
});

export default authReducer;
