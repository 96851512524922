import React from 'react';
import animatedcube from '../../xres/placeholders/animatedcube.svg';

export default function Loader(props) {
	return (
		<div style={{ textAlign: 'center', margin: '3rem' }}>
			<img
				src={animatedcube}
				height={props.size}
				width={props.size}
				alt='Loading'
			/>
			<p>{props.text}</p>
		</div>
	);
}
