//import { createAction } from "@reduxjs/toolkit";
import {
	//gettingProperties,
	//initProperties,
	gettingTest,
	gettingVenues,
	gotErrorGettingVenues,
	gotVenues,
} from '../reducers/propertyReducer';
//import { gotProperties } from "./../reducers/propertyReducer";
import { request_url, api_instance } from './../../api/requests';
// import {  } from '../../api/endpoints';
import { venuesCollectionRef } from '../../services/fb';

export const getVenues = (cac = false) => {
	let admin_venues = [];
	let venues = [];

	return (dispatch) => {
		dispatch(gettingVenues());
		// if (cac)
		// let getOptions = {
		// 	source: 'cache',
		// };
		venuesCollectionRef
			.get()
			.then(function (querySnapshot) {
				// console.log(
				// 	'[propertyActions > getVenues] incoming snapshot: ',
				// 	querySnapshot.doc //undefined
				// );
				querySnapshot.forEach((doc) => {
					const incoming_venue = doc.data();
					admin_venues.push(incoming_venue);
					//
					const pics = incoming_venue.pics;
					const cover_pic = incoming_venue.cover_pic;
					const new_pics = [{ ...cover_pic }, ...pics];
					const processed_venue = { ...incoming_venue, pics: new_pics };
					//
					venues.push(processed_venue);
				});
				// console.log('[propertyActions > getVenues] arrayList: ', venues);
				dispatch(
					gotVenues({
						venues: admin_venues,
						properties: venues,
					})
				);
			})
			.catch((err) => {
				console.log('[propertyActions > getVenues] got Error: ', err.message);
				dispatch(gotErrorGettingVenues(JSON.stringify(err)));
			});
		//
	};
};

export const test = () => {
	return (dispatch) => dispatch(gettingTest());
};

export const getProperties = async (path) => {
	const ammenities_keys = [
		'Karaoke_System',
		'Living-Lounge_Area',
		'green_lawnlandscape_area',
		'music_system',
		'kitchen_',
		'Hookah',
		'air_conditioner',
		'Open_Terrace',
		'Smoking_Allowed',
	];
	const meta_data_keys = [
		'parking',
		'no_of_rooms',
		'cancellation_policy_',
		'event_location',
		'address_of_the_property',
	];
	const property_list = [];
	// console.log("Hi, i am getproperties");
	//console.log("[propertyActions.js] > Starting to fetch properties");
	//return dispatch => {
	//dispatch(gettingProperties());
	const response = await api_instance({
		method: 'get',
		url: request_url(path),
		params: { per_page: 33 },
	});

	response.data.map((property, index) => {
		let ammenities = {};
		let meta = {};
		const {
			id,
			price,
			name,
			images,
			short_description,
			meta_data,
			categories,
		} = property;
		meta_data.map(async (data, index) => {
			if (ammenities_keys.includes(data.key)) {
				ammenities[data.key] = data.value;
			}
			if (meta_data_keys.includes(data.key)) {
				meta[data.key] = data.value;
			}
			return null;
		});
		return property_list.push({
			id: id,
			price: price,
			name: name,
			location: categories[0].name,
			short_description: short_description.replace(/<[^>]*>|\n|&nbsp/g, ''),
			ammenities: ammenities,
			meta_data: meta,
			images: images,
		});
	});

	//.then(async response => {
	//console.log("[propertyActions.js] > Got properties: ", property_list);
	//const properties = [];
	//response.data.map((property, index) => properties.push({}) )
	//await dispatch(initProperties(response.data));
	//dispatch(gotProperties());
	//return response.data;
	//})
	//.catch(err => console.log("REQUEST FAILED FOR:", path, err));
	// };
	return property_list;
};
