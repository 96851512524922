export const venNameToVID = (venue) => {
	// console.log('[venNameToVID] got Venue:', venue);
	return (
		venue.property_name +
		'_' +
		venue.property_code_number +
		'_' +
		venue.address.city
	);
};

export const vidToVenue = (vid, venues_list) => {
	let [property_name, property_code_number, venue_city] = vid.split('_');
	let venue_queried = venues_list.filter(
		(v) =>
			v.property_name === property_name &&
			v.property_code_number === property_code_number &&
			// v.address.city == venue_city
			v.address.city.includes(venue_city)
	)[0];
	// console.log('[vidToVenue] Got Values: ', vid);
	// console.log('[vidToVenue] Got Query Result: ', venue_queried);

	return venue_queried;
};

export const venNameToVIDMini = (venue) => {
	// console.log('[venNameToVID] got Venue:', venue);
	let name_array = venue.venue_name.split(' ');
	let loc_array = venue.venue_location.split(', ');
	let city = loc_array[loc_array.length - 1];
	// console.log('[venNameToVIDMini] City:', city);

	let id_for_venue = name_array[0] + '_' + name_array[1] + '_' + city;
	// console.log('[venNameToVIDMini] City:', id_for_venue);
	return id_for_venue;
};

export const saveToLS = (key, data) => {
	try {
		console.log('Saving data: ', data);
		const serializedData = JSON.stringify(data);
		localStorage.setItem(key, serializedData);
	} catch (err) {
		console.log('[Utils] -> [saveToLS()] Error saving Booking Data', err);
	}
};

export const readFromLS = (key) => {
	try {
		const serializedData = localStorage.getItem(key);
		if (serializedData === null) {
			return undefined;
		}
		return JSON.parse(serializedData);
	} catch (err) {
		console.log('[Utils] -> [readFromLS()] Error loading Booking Data', err);
		return undefined;
	}
};
