import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import placeholder from '../../xres/pepspot_fullmark.svg';

const Img = (props) => {
	const { src, alt, height = '400px', width = '480px', className } = props;
	return (
		<Suspense
			fallback={
				<h3 styles={{ height: height, width: width }} className={className}>
					Loading a cool image for {alt}
				</h3>
				// <img
				// 	src={image.src + '&q=10'}
				// 	height={image.height}
				// 	width='100%'
				// 	alt={image.alt}
				// 	className='hero_img'
				// 	loading='lazy'
				// 	decoding='async'
				// />
			}>
			<img
				src={src !== null ? src : placeholder}
				height={height}
				width={width}
				alt={alt}
				className={className}
				loading='lazy'
				decoding='async'
			/>
		</Suspense>
	);
};

Img.propTypes = {
	// src: PropTypes.string.isRequired,
	alt: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	className: PropTypes.string,
};

export default Img;
