import { createAction, createReducer } from '@reduxjs/toolkit';
// import { timeLogger } from '../commons';
// import { batch } from 'react-redux';
// import { getAuthRef, gProvider, usersCollectionRef } from '../../services/fb';

export const ad_bookingCreationStarted = createAction(
	'Booking Creation Started by Admin'
);
export const ad_bookingCreated = createAction(
	'Booking Creation Completed by Admin'
);
export const ad_bookingCreationFailed = createAction(
	'Booking Creation Failed by Admin'
);

export const ad_bookingUpdationStarted = createAction(
	'Booking Updation Started by Admin'
);
export const ad_bookingUpdated = createAction(
	'Booking Updation Completed by Admin'
);
export const ad_bookingUpdationFailed = createAction(
	'Booking Updation Failed by Admin'
);

export const ad_bookingsFetching = createAction(
	'Started Fetching Bookings by Admin'
);
export const ad_bookingsFetched = createAction(
	'Succeded in Fetching Bookings by Admin'
);
export const ad_bookingsFetchFailed = createAction(
	'Failed Fetching Bookings by Admin'
);

export const ad_users_fetching = createAction(
	'Started fetching users for Admin'
);
export const ad_users_fetched = createAction('Fetched users for Admin');
export const ad_users_fetchFailed = createAction(
	'Failed to Fetch users for Admin'
);

export const ad_users_updating = createAction(
	'Started updating user for Admin'
);
export const ad_users_updated = createAction('Updated user for Admin');
export const ad_users_updateFailed = createAction(
	'Failed to Update user for Admin'
);

export const booking_ds_template = {
	booking_id: '',
	status: '', //paid, confirmed, cancelled
	isApproved: '', //boolean

	property_id: '', //Select from property selector
	property_name: '', //Select from property selector
	property_bookings: '', //Map all existing bookings from the property
	property_pricing: '', //Select from property selector

	check_in: '', //select form react-dates
	check_out: '', //select form react-dates
	guest_count: '', //Numeric input by admin

	billing: {
		cust_id: '', //AutoGenerate in handleSubmitNewBooking
		cust_name_first: '', //Input by admin
		cust_name_last: '', //Input by admin
		company: '', //Input by admin
		email: '', //Input by admin
		phone: '', //Input by admin

		flat_num: '', //Input by admin
		locality: '', //Input by admin
		area: '', //Input by admin
		city: '', //Input by admin
		pincode: '', //Input by admin
		state: '', //Input by admin
		country: 'India',
	},
	cost_breakdown: {
		base_cost: '', //calculated by costCalculator
		discount_total: '',
		prices_include_tax: '',
		total_tax: '',
		total_cost: '',
	},
	payment: {
		transaction_id: '', //auto-gen from booking
		currency: 'INR',
		method: '', //get response from cashfree
		date_paid: '', //get response from cashfree
		amount_paid: '', //get response from cashfree
		payment_link: '', //generate (hopefully) for redirect.
	},
};

const initialState = {
	bookingsList_fetching: null,
	bookingsList_error: null,
	booking_db_status: '',
	bookings_list: [],
	bookings_errors: null,
	teams_db_status: '',
	teams_list: [],
	users_db_fetching: false,
	users_db_status: '',
	users_list: [],
};

const adminReducer = createReducer(initialState, {
	[ad_bookingsFetching.type]: (state) => {
		state.bookingsList_fetching = true;
	},
	[ad_bookingsFetched.type]: (state, action) => {
		state.bookings_list = action.payload.bookings;
		state.bookingsList_fetching = false;
	},
	[ad_bookingsFetchFailed.type]: (state, action) => {
		state.bookingsList_error = action.payload;
		state.bookingsList_fetching = false;
	},
	[ad_bookingCreationStarted.type]: (state) => {
		state.booking_db_status = 'Creation Started';
	},
	[ad_bookingCreated.type]: (state, action) => {
		let { payload } = action;
		state.booking_db_status = 'Creation Completed';
		state.bookings_list.push(payload);
	},
	[ad_bookingCreationFailed.type]: (state, action) => {
		let { payload } = action;
		state.venue_list_status = 'Error Fetching Venues';

		state.booking_db_status = 'Creation Failed';
		state.bookings_errors = console.log(
			'[adminReducer] > ',
			state.booking_db_status,
			': ',
			{ payload }
		);
	},
	[ad_users_fetching.type]: (state) => {
		state.users_db_fetching = true;
		state.users_db_status = 'initializing';
	},
	[ad_users_fetched.type]: (state, action) => {
		state.users_db_fetching = false;
		state.users_db_status = 'fetched';
		state.users_list = action.payload;
	},
	[ad_users_fetchFailed.type]: (state, action) => {
		state.users_db_fetching = false;
		state.users_db_status = action.payload;
	},

	[ad_users_updating.type]: (state, action) => {},
	[ad_users_updated.type]: (state, action) => {},
	[ad_users_updateFailed.type]: (state, action) => {},
});

export default adminReducer;
