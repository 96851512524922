import axios from 'axios';
import moment from 'moment';
import { bookingsCollectionRef, mailTriggerColRef } from './fb';
import {
	bookingCreationFailed,
	bookingCreated,
	checkout_confirmed,
} from '../store/reducers/reducer_user';
import { navigate } from '@reach/router';
import { saveToLS } from '../commons/utils';

let URL_BASE = process.env.REACT_APP_BACKURL_PROD;

const URL_NEW_ORDER = URL_BASE + '/api/pay/newOrder';
const URL_PAY_EMAIL = URL_BASE + '/api/pay/triggerMail';
const URL_PAY_STATUS = URL_BASE + '/api/pay/getOrderStatus';
// const URL_PAY_GET_LINK = URL_BASE + '/api/pay/getPayLink';
// const URL_PAY_GET_DETAILS = URL_BASE + '/api/pay/getOrderDetails';

const URL_SMS_SEND = URL_BASE + '/api/sms/sendSMS';
const URL_WAM_SEND = URL_BASE + '/api/wam/sendWAM';

const apiErrorHandler = (error, source) => {
	if (error.response) {
		// The request was made. the server responded
		// with a status code that falls out of the range of 2xx
		console.log(
			source,
			'!! Got Error in Response > Data : ',
			error.response.data
		);
		console.log(
			source,
			'!! Got Error in Response > Status : ',
			error.response.status
		);
		console.log(
			source,
			'!! Got Error in Response > Headers : ',
			error.response.headers
		);
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest
		// is an instance of http.ClientRequest in node.js
		console.log(source, '!! Got Error in Request : ', error.request);
	} else {
		// Something happened in setting up the request
		// that triggered an Error
		console.log(
			source,
			'>> !! Got Unexpected Error > Message : ',
			error.message
		);
	}
	console.log(source, '>> !! Unhandled Error > Config : ', error.config);
	console.error(source, '>> !! Unhandled Error : ', error);
	return error;
};

export const getPayLink = async (book_data) => {
	console.log('[back.js > getPayLink] API called with data: ', book_data);

	let transact_id = book_data.transaction_id;
	let amount = book_data.cost_breakdown.total_cost;
	let order_note =
		'To book' +
		book_data.venue_selected.venue_name +
		' for ' +
		moment(book_data.check_in).format('ddd, Do MMM, YYYY') +
		' by ' +
		book_data.billing.customer_name;

	let customerName = book_data.billing.customer_name;
	let customerEmail = book_data.billing.email;
	let customerPhone = book_data.billing.phone;

	let data_stringed = JSON.stringify({
		orderId: transact_id, //'Greg_House_1590800371159_test001',
		orderAmount: amount, //'999',
		orderNote: order_note, //'User Initiated venue booking',
		customerName: customerName, //'Pepspot Dev',
		customerPhone: customerPhone, //'7011467321',
		customerEmail: customerEmail, //'devpepspot@gmail.com',
	});

	console.log('[back.js > getPayLink] Stringed data: ', data_stringed);

	let config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		let response = await axios.post(URL_NEW_ORDER, data_stringed, config);
		console.log('[back.js > getPayLink] Response: ', response);

		// return only the relevant parts of the response sent for the request
		return await response.data;
	} catch (error) {
		apiErrorHandler(error, '[back.js > getPayLink]');
	}

	// let config = {
	// 	method: 'post',
	// 	url: URL_NEW_ORDER,
	// 	headers: {
	// 		'Content-Type': 'application/json',
	// 	},
	// 	data: data_stringed,
	// };
	// axios(config)
	// 	.then((response) => {
	// 		console.log(
	// 			'[back.js > getPayLink] Response:',
	// 			JSON.stringify(response.data)
	// 		);
	// 		return response.data;
	// 	})
	// 	.catch((error) => {
	// 		console.error('[back.js > getPayLink] Error:', error);
	// 		return error.message;
	// 	});
};

// let unchecked_booking = {
// 	customer_id: 'Greg_House',
// 	bookingDocRef: 'Greg_House_1590751769576',
// 	booking_id: 'Greg_House_1590751769576',
// 	booked_on: '1590751769576',

// 	booked_by: 'Greg House',
// 	booking_note: `Booked by Greg House on 1590751769576`,

// 	status: 'pending_approval',
// 	isApproved: true,
// 	booking_verified_by_user: false,
// 	check_in: '2020-06-03T12:00:00+05:30',
// 	check_out: '2020-06-04T12:00:00+05:30',
// 	guest_count: 10,
// 	cost_breakdown: {
// 		days: 1,
// 		weekends: 0,
// 		specials: 0,
// 		extra_guests: -5,
// 		base_cost: 1600,
// 		coupon: '',
// 		discount_total: 0,
// 		tax_rate: '.12',
// 		total_tax: 192,
// 		total_cost: 1792,
// 	},

// 	venue_docref: 'New_Delhi_1590147239947',
// 	venue_selected: {
// 		venue_id: 'New_Delhi_1590147239947',
// 		venue_pic:
// 			'https://images.unsplash.com/photo-1589812308278-391d648491c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80',
// 		venue_name: 'Pep Villa 501',
// 		venue_location: 'Vasant Vihar, New Delhi',
// 		venue_bookings: [],
// 		venue_pricing_onBooking: {
// 			weekend: '1800',
// 			guest_limit_lower: 15,
// 			tax_rate: '.12',
// 			date_range: [
// 				{
// 					message: 'High Demand',
// 					price: '2000',
// 					start_date: '2020-05-25',
// 					end_date: '2020-05-28',
// 				},
// 			],
// 			misc: [
// 				{
// 					charge_name: 'Extra Mattresses',
// 					charge_value: '300',
// 				},
// 			],
// 			base: '1600',
// 			cost_per_guest: 500,
// 		},
// 	},

// 	billing: {
// 		cust_id: 'Greg_House',
// 		customer_name: 'Greg House',
// 		phone: '+917011467321',
// 		email: 'aditya.mishra@dynamix.co.in',
// 		company: 'FGC Ltd.',
// 		flat_num: '1105',
// 		locality: 'DDS SFA Flats',
// 		area: 'Pocket 1, Sector 512, Dwarka',
// 		city: 'New Delhi',
// 		pincode: '110075',
// 		state: 'NCR',
// 		country: 'India',
// 	},
// };

// let checked_booking = {};

export const generatePaymentLink = async (v_book) => {
	console.log('3.b [back.js] > Inside generatePaymentLink ', v_book);
	// 1. activates after it is checked that the booking does not require approval from admin
	// 2. generates a transaction_id by using the customer_id and the time of execution (new Date()).
	// 3. hits the backend to get a payment link based on the transaction_id
	// 4. stores both the payment link and the transaction id in an updated booking object
	// 5. passes the updated booking object to _writeBookingDatatoDB() to handle db writes

	let time = new Date();
	let pay_info = v_book.payment;
	const transac_start_time = time.getTime();
	const transaction_id = v_book.customer_id + '_' + transac_start_time;

	let booking_with_transaction_id = {
		...v_book,
		transaction_id,
		status: 'pending_payment',
	};

	console.log(
		'3.b [back.js > generatePaymentLink]',
		booking_with_transaction_id
	);

	let payment_obj = null;
	try {
		payment_obj = await getPayLink(booking_with_transaction_id);
		console.log('3.c [back.js > generatePaymentLink] > Response:', payment_obj);
	} catch (error) {
		console.log(
			'3.err [back.js > generatePaymentLink] > Error:',
			error.message,
			'on: ',
			payment_obj
		);
	}

	if (payment_obj.result.status === 'ERROR') {
		console.error(
			'3. err [back.js > generatePaymentLink] > Got Error:',
			payment_obj.result.message
		);
		return new Error(payment_obj.result.message);
	} else {
		let payment_link = payment_obj.result.paymentLink;

		let booking_with_payment_link = {
			...booking_with_transaction_id,
			payment: {
				...pay_info,
				payment_link,
			},
		};
		console.log(
			'3.d [back.js > generatePaymentLink] > DB Ready Obj:',
			booking_with_payment_link
			// JSON.stringify(booking_with_payment_link)
		);

		return await booking_with_payment_link;
	}
};

// let expected_DB_ready_data = {
// 	customer_id: 'Greg_House',
// 	bookingDocRef: 'Greg_House_1590751769576',
// 	booking_id: 'Greg_House_1590751769576',
// 	booked_on: '1590751769576',
// 	booking_verified_by_user: true,
// 	booked_by: 'Greg House',
// 	booking_note: 'Booked by Greg House on 1590751769576',
// 	status: 'pending_approval',
// 	isApproved: true,
// 	check_in: '2020-06-03T12:00:00+05:30',
// 	check_out: '2020-06-04T12:00:00+05:30',
// 	guest_count: 10,
// 	cost_breakdown: {
// 		days: 1,
// 		weekends: 0,
// 		specials: 0,
// 		extra_guests: -5,
// 		base_cost: 1600,
// 		coupon: '',
// 		discount_total: 0,
// 		tax_rate: '.12',
// 		total_tax: 192,
// 		total_cost: 1792,
// 	},
// 	venue_docref: 'New_Delhi_1590147239947',
// 	venue_selected: {
// 		venue_id: 'New_Delhi_1590147239947',
// 		venue_pic:
// 			'https://images.unsplash.com/photo-1589812308278-391d648491c5…1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80',
// 		venue_name: 'Pep Villa 501',
// 		venue_location: 'Vasant Vihar, New Delhi',
// 		venue_bookings: [],
// 		venue_pricing_onBooking: {
// 			weekend: '1800',
// 			guest_limit_lower: 15,
// 			tax_rate: '.12',
// 			date_range: [
// 				{
// 					message: 'High Demand',
// 					price: '2000',
// 					start_date: '2020-05-25',
// 					end_date: '2020-05-28',
// 				},
// 			],
// 			misc: [
// 				{
// 					charge_name: 'Extra Mattresses',
// 					charge_value: '300',
// 				},
// 			],
// 			base: '1600',
// 			cost_per_guest: 500,
// 		},
// 	},
// 	billing: {
// 		cust_id: 'Greg_House',
// 		customer_name: 'Greg House',
// 		phone: '+917011467321',
// 		email: 'aditya.mishra@dynamix.co.in',
// 		company: 'FGC Ltd.',
// 		flat_num: '1105',
// 		locality: 'DDS SFA Flats',
// 		area: 'Pocket 1, Sector 512, Dwarka',
// 		city: 'New Delhi',
// 		pincode: '110075',
// 		state: 'NCR',
// 		country: 'India',
// 	},
// 	payment: {
// 		date_paid: '',
// 		amount_paid: 1792,
// 		currency: 'INR',
// 		method: '',
// 		payment_link:
// 			'https://payments-test.cashfree.com/order/#Xkr9fJ9VzaDbjL1uJudU',
// 		status: 'new',
// 	},
// 	transaction_id: 'Greg_House_1594306438669',
// };

export const writeBookingDatatoDB = (book) => {
	// 1. gets a booking object with payment link and transaction_id
	// 2. users booking reference to perform a merge operation on the db

	console.log('4.a Writing to DB');
	return (dispatch) => {
		bookingsCollectionRef
			.doc(book.bookingDocRef)
			.set(book, { merge: true })
			.then((res) => {
				console.log(
					'4.b [back.js > writeBookingDatatoDB] > DB Update Success > with data: ',
					res
				);
				dispatch(bookingCreated({ book }));
				dispatch(checkout_confirmed(book));

				if (book.status === 'pending_approval') {
					alert('Booking saved! Admins Notified.');
				} else {
					alert('Booking Saved! Make the payment to complete the booking!');
				}
				saveToLS(book.transaction_id, book);
				// saveToLS
			})
			.catch((error) => {
				let incoming_error = {
					e_code: error.code,
					e_message: error.message,
				};
				console.error(
					'4.err [back.js handleBooking] > Error writing document: ',
					incoming_error,
					JSON.stringify(error)
				);
				dispatch(bookingCreationFailed(incoming_error));
			});
	};
};

export const sendPaymentEmail = async (transact_id) => {
	console.log(
		'[back.js > sendPaymentEmail] 1. API called with data: ',
		transact_id
	);

	let data_stringed = JSON.stringify({
		orderId: transact_id,
	});

	console.log('[back.js > sendPaymentEmail] 2. Stringed data: ', data_stringed);

	let config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		let response = await axios.post(URL_PAY_EMAIL, data_stringed, config);
		console.log('[back.js > sendPaymentEmail] 3. Backend Response: ', response);
		alert('Payment Reminder Email Sent to User');

		// return only the relevant parts of the response sent for the request
		// return await response.data;
	} catch (error) {
		apiErrorHandler(error, '[back.js > sendPaymentEmail] 4.err');
	}
};

export const checkPaymentStatus = async (transact_id) => {
	console.log(
		'[back.js > checkPaymentStatus] 1. API called with data: ',
		transact_id
	);

	let data_stringed = JSON.stringify({
		orderId: transact_id,
	});

	console.log(
		'[back.js > checkPaymentStatus] 2. Stringed data: ',
		data_stringed
	);

	let config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		let response = await axios.post(URL_PAY_STATUS, data_stringed, config);
		console.log(
			'[back.js > checkPaymentStatus] 3. Backend Response: ',
			response
		);
		alert(
			`Order Payment Status: ${response.data.orderStatus} as: ${response.data.txStatus}`
		);
		return response.data.orderStatus;
		// return only the relevant parts of the response sent for the request
		// return await response.data;
	} catch (error) {
		apiErrorHandler(error, '[back.js > checkPaymentStatus] 4.err');
	}
};

export const sendConfirmationSms = async (booking) => {
	console.log(
		'[back.js > sendConfirmationSms] 1. Backend Req with Data: ',
		booking
	);
	let customer_name = booking.billing.customer_name;

	let msg_check_in = moment(booking.check_in).format('ddd, Do'); //Just the DD out of DD-MM-YYYY of Checkin
	let msg_check_out = moment(booking.check_out).format('ddd, Do MMM'); //DD-MM-YY of Chekcout
	let venue_name = booking.venue_selected.venue_name;
	let customer_phone = booking.billing.phone;

	if (customer_phone.length > 10) {
		customer_phone = customer_phone.slice(3);
		console.log(
			'Number has Country code!! Trimmed User Number is:',
			customer_phone
		);
	}

	// let sms_msg = `Hey ${customer_name}, Your party has been booked at ${venue_name} for ${msg_check_in}-${msg_check_out} by Pepspot`;
	let sms_msg = `Hey ${customer_name}, Your party venue has been booked at ${venue_name} for ${msg_check_in}, ${msg_check_out} by Pepspot`;
	let sms_sent = '';
	let data = {
		dest_number: customer_phone,
		msg: sms_msg,
	};

	let data_stringed = JSON.stringify(data);

	console.log(
		'[back.js > sendConfirmationSms] 2. Request Data: ',
		data_stringed
	);

	let config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	//Make sms API Request
	try {
		sms_sent = await axios.post(URL_SMS_SEND, data_stringed, config);
		console.log(
			'[back.js > sendConfirmationSms] 3. Backend Response: ',
			sms_sent
		);
		alert('Booking Confirmation SMS Sent to User');

		// return only the relevant parts of the response sent for the request
		// return await response.data;
	} catch (error) {
		apiErrorHandler(error, '[back.js > sendConfirmationSms] 4.err');
		alert('Booking Confirmation SMS Not Sent to User: ', error.message);
	}
};

export const sendConfirmationEmail = (booking) => {
	console.log(
		'[back.js > sendConfirmationEmail] 1. FS Req with Data: ',
		booking
	);
	//Notification Data
	let booking_id = booking.booking_id
		? booking.booking_id
		: 'bad_admin_booking_' + Date.now();
	let customer_name = booking.billing.customer_name;
	let msg_check_in = moment(booking.check_in).format('ddd, Do'); //Just the DD out of DD-MM-YYYY of Checkin
	let msg_check_out = moment(booking.check_out).format('ddd, Do MMM'); //DD-MM-YY of Chekcout
	let venue_name = booking.venue_selected.venue_name;
	let venue_location = booking.venue_selected.venue_location;
	let venue_landmark = booking.venue_selected.venue_landmark
		? booking.venue_selected.venue_landmark
		: 'Not Specified';

	let customer_email = booking.billing.email;
	let email_msg = `Hey ${customer_name}, Your party has been booked at ${venue_name}, ${venue_location} (landmark: ${venue_landmark}) for ${msg_check_in}-${msg_check_out} via Pepspot`;
	console.log(
		'[back.js > sendConfirmationEmail] 2. email: [',
		customer_email,
		'] Msg:',
		email_msg,
		' for ID: [',
		booking_id,
		'].'
	);

	//Mail Trigger
	//'devpepspot@gmail.com',
	// sendMail(
	// 	new_booking.billing.email,
	// 	'[Pepspot] Party venue has been booked',
	// 	new_booking
	// );

	let mail_sent = '';
	mailTriggerColRef
		.add({
			to: customer_email,
			message: {
				subject: '[Pepspot] Party venue has been booked',
				html: email_msg,
			},
		})
		.then((res) => {
			mail_sent = res.id;
			console.log(
				'[back.js > sendConfirmationEmail] 3. Response from FS: ',
				mail_sent
			);
			alert('Booking Confirmation Email Sent to User');
		})
		.catch((error) => {
			mail_sent = {
				msg: 'Got Error Sending Email',
				error,
			};
			console.error(
				'[back.js > sendConfirmationEmail] 4.err Error from FS: ',
				mail_sent.msg,
				error
			);
			alert(
				'Booking Confirmation Email Not Sent to User',
				mail_sent.error.message
			);
		});
};

export const sendMail = (target, msg) => {
	console.log('[SendMail] To:', target, 'Msg Body:', msg);

	let mail_sent = '';

	mailTriggerColRef
		.add({
			to: target,
			message: msg,
		})
		.then((res) => {
			mail_sent = res.id;
			console.log('[back.js > sendMail] 3. Response from FS: ', mail_sent);
			alert('Message Sent, Please wait for our team to reach out to you.');
			navigate('/');
		})
		.catch((error) => {
			mail_sent = {
				msg: 'Got Error Sending Email',
				error,
			};
			console.log(
				`[back.js > sendMail] 4.err Error from FS: "${error.message}"`
				// mail_sent.msg,
			);

			if (error.message !== 'Missing or insufficient permissions.') {
				// alert('Message Not Sent! Try again in a few seconds!', mail_sent.error);
				alert(error.message);
			} else {
				alert('Please Sign In & try again in 2 minutes.');
			}
		});
};

// const venue_ref = venuesCollectionRef.doc(new_booking.propertyDocRef);
// const user_ref = usersCollectionRef.doc(user.userDocRef);
// bookingsCollectionRef
// 	.doc(book.bookingDocRef)
// 	.set(book, { merge: true })
// 	.then((res) => {

// venue_ref.update({
// 	bookings: firebase.firestore.FieldValue.arrayUnion(mini_booking),
// });
// user_ref.update({
// 	bookings: firebase.firestore.FieldValue.arrayUnion(mini_booking),
// });

// 	})
// .catch((error) => {
// 	let incoming_error = {
// 		e_code: error.code,
// 		e_message: error.message,
// 	};
// 	console.error(
// 		'[Checkout.js > handleBooking > 4:Error ] > Error writing document: ',
// 		incoming_error,
// 		JSON.stringify(error)
// 	);
// 	dispatch(bookingCreationFailed(incoming_error));
// 	});

// dispatch(writeBookingDatatoDB(booking_with_payment_link, dispatch));
// if (booking_with_payment_link.payment.payment_link) {
// 	console.log('Triggering DB Write');
// 	writeBookingDatatoDB(booking_with_payment_link, dispatch);
// 	// return (dispatch) => {
// 	//   dispatch(writeBookingDatatoDB(booking_with_payment_link));
// 	// };
// }

// if (booking_with_payment_link.payment.payment_link) {
//   console.log(
//     '[actions_user > handleOrder_user] Final Clientside Data:',
//     booking_with_payment_link
//   );

// }
// transaction_id,

export const sendConfirmationWAM = async (booking) => {
	console.log(
		'[back.js > sendConfirmationWAM] 1. Backend Req with Data: ',
		booking
	);
	let customer_name = booking.billing.customer_name;

	let msg_check_in = moment(booking.check_in).format('ddd, Do'); //Just the DD out of DD-MM-YYYY of Checkin
	let msg_check_out = moment(booking.check_out).format('ddd, Do MMM'); //DD-MM-YY of Chekcout
	let venue_name = booking.venue_selected.venue_name;
	let customer_phone = booking.billing.phone;

	if (customer_phone.length > 10) {
		customer_phone = customer_phone.slice(3);
		console.log(
			'Number has Country code!! Trimmed User Number is:',
			customer_phone
		);
	}

	// let sms_msg = `Hey ${customer_name}, Your party has been booked at ${venue_name} for ${msg_check_in}-${msg_check_out} by Pepspot`;
	let wam_msg = `Hey ${customer_name}, Your party venue has been booked at ${venue_name} for ${msg_check_in}, ${msg_check_out} by Pepspot`;
	let wam_sent = '';
	let data = {
		dest_number: customer_phone,
		msg: wam_msg,
	};

	let data_stringed = JSON.stringify(data);

	console.log(
		'[back.js > sendConfirmationSms] 2. Request Data: ',
		data_stringed
	);

	let config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	//Make sms API Request
	try {
		wam_sent = await axios.post(URL_WAM_SEND, data_stringed, config);
		console.log(
			'[back.js > sendConfirmationSms] 3. Backend Response: ',
			wam_sent
		);
		alert('Booking Confirmation SMS Sent to User');

		// return only the relevant parts of the response sent for the request
		// return await response.data;
	} catch (error) {
		apiErrorHandler(error, '[back.js > sendConfirmationSms] 4.err');
		alert('Booking Confirmation SMS Not Sent to User: ', error.message);
	}
};
