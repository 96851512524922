import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';

import PropertyCardList from '../property/PropertyCardList';
import PropertyDetails from '../property/PropertyDetails';

import '../../xstyles/property.css';
import Loader from '../common/Loader';
import { Link } from '@reach/router';
import { vidToVenue } from '../../commons/utils';

/**
 * Gets a list of avaialable venues from redux
 * Get a vid when accessed from VenuePage - tha page that shows all detials of the property
 *
 * Allows filtering venues based on city, locality, property type, property name by using text inputs and buttons why set the text input
 * Allows sorting through the venue by price
 * Shows 1 active venue. sets url based on the active property.
 * Set the active venue as the first venue in the arraylist of filtered and sorted venues.
 * Is aware of the next and previous venues based on the index of the active venue in the filtered and sorter arraylist
 *
 */

export const SearchBar = (props) => {
	let {
		venue_type_filter,
		venue_city_filter,
		sorted_list,
		setvenue_type_filter,
		setvenue_city_filter,
		setSortedList,
	} = props;
	// console.log('[SearchBar] props:', props);
	return (
		<section className='venue_searchbar_container'>
			<p className='venue_searchbar_label'>
				<b>Search venues by your preferences:</b>
			</p>
			<div className='search_fields'>
				<div className='search_fields_main'>
					<div className='venue_searchbar_field_container'>
						<TextField
							name='filter_venue_city'
							label='Location'
							placeholder='Location'
							helperText='eg: Delhi, Gurugram, Noida'
							type='text'
							variant='outlined'
							fullWidth
							value={venue_city_filter}
							onChange={(event) => {
								setvenue_city_filter(event.target.value.toLowerCase());
							}}
						/>
					</div>
					<div className='venue_searchbar_field_container'>
						<TextField
							name='filter_venue_city'
							label='Venue Type'
							placeholder='Venue Type'
							helperText='eg: 1-6BHK, Villa, Farmhouse, Bunglow'
							type='text'
							variant='outlined'
							fullWidth
							value={venue_type_filter}
							onChange={(event) => {
								setvenue_type_filter(event.target.value.toLowerCase());
							}}
						/>
					</div>
					<ul></ul>
				</div>

				<div className='venue_searchbar_field_container'>
					<span className=''>Sort by: </span>{' '}
					<button
						className={
							sorted_list
								? 'venue_sort_btn venue_sort_btn_active'
								: 'venue_sort_btn'
						}
						onClick={() => setSortedList(true)}>
						Price ▲
					</button>
					<button
						className={
							sorted_list
								? 'venue_sort_btn'
								: 'venue_sort_btn venue_sort_btn_active'
						}
						onClick={() => setSortedList(false)}>
						Price ▼
					</button>
				</div>
			</div>
			<div className='venue_searchbar_section searchbar_active'>
				<p className='venue_searchbar_active_label'>Active Filters: </p>
				{venue_city_filter !== '' ? (
					<div className='venue_searchbar_active_filter'>
						Location: "<b>{venue_city_filter}</b>"
					</div>
				) : null}
				{venue_type_filter !== '' ? (
					<div className='venue_searchbar_active_filter'>
						Type: "<b>{venue_type_filter}</b>"
					</div>
				) : null}
				{sorted_list === false ? (
					<div className='venue_searchbar_active_filter'>
						Sorted by: "<b>Price High to Low</b>"
					</div>
				) : (
					<div className='venue_searchbar_active_filter'>
						Sorted by: "<b>Price Low to High</b>"
					</div>
				)}
			</div>
		</section>
	);
};

const VenueViewer = (props) => {
	const venues_list_all = useSelector(
		(state) => state.properties.properties_list
	);
	const serviced_cities = useSelector(
		(state) => state.properties.serviced_cities
	);
	const venue_types = useSelector((state) => state.properties.property_types);

	let venues_list = venues_list_all;

	// console.log('[VenueViewer] Props:', props);

	const [venue_type_filter, setvenue_type_filter] = useState('');
	const [venue_city_filter, setvenue_city_filter] = useState('');
	const [sorted_list, setSortedList] = useState(true);
	const [venue_active, setvenue_active] = useState(
		venues_list.length > 0 ? venues_list[0] : null
	);

	if (venue_type_filter !== '' || venue_city_filter !== '') {
		venues_list = venues_list
			.filter((s) => s.property_type.toLowerCase().includes(venue_type_filter))
			.filter((s) => {
				return (
					s.address.locality.toLowerCase().includes(venue_city_filter) ||
					s.address.area.toLowerCase().includes(venue_city_filter) ||
					s.address.city.toLowerCase().includes(venue_city_filter) ||
					s.address.state.toLowerCase().includes(venue_city_filter)
				);
			});
	}
	// else if (
	// 	(venue_type_filter !== '' || venue_city_filter !== '') &&
	// 	sorted_list
	// ) {
	// 	venues_list = venues_list
	// 		.filter((s) => s.property_type.includes(venue_type_filter))
	// 		.filter((s) => s.address.city.includes(venue_city_filter))
	// 		.sort((a, b) => parseInt(b.pricing.base) - parseInt(a.pricing.base));
	// }
	if (venues_list.length > 0) {
		if (sorted_list) {
			venues_list = venues_list
				.slice()
				.sort((a, b) => parseInt(a.pricing.base) - parseInt(b.pricing.base));
		} else {
			venues_list = venues_list
				.slice()
				.sort((a, b) => parseInt(b.pricing.base) - parseInt(a.pricing.base));
		}
	}

	useEffect(() => {
		if (venues_list.length > 0 && props.vid) {
			let venue = vidToVenue(props.vid, venues_list);
			setvenue_active(venues_list[venues_list.indexOf(venue)]);
		} else if (venues_list.length > 0) {
			setvenue_active(venues_list[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [venues_list.length]);

	useEffect(() => {
		// console.log('[VenueViewer] Serviced Cities:', serviced_cities);
		// console.log('[VenueViewer] includes:', serviced_cities.includes(props.loc));
		// console.log('[VenueViewer] indexOf:', serviced_cities.indexOf(props.loc));
		if (serviced_cities.includes(props.loc)) {
			// console.log('[VenueViewer] Accessed City:', props.loc);
			setvenue_city_filter(props.loc.toLowerCase());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.loc]);

	// console.log('Venues List Length: ', venues_list.length);

	// console.log('Page Source: ', props.page);

	if (venues_list_all.length < 1) {
		return (
			<div className='width_container viewer_container'>
				<h1 className='head_thin'>Discover</h1>
				<h2 className='head_bold'>the best venues</h2>
				<p className='head_mini'>
					Party to your heart's content in unique places, flavored with themes
					of your choice.
				</p>
				<div className='loader_container'>
					{/* <div className=''>'Peppy Venues On Their Way To You 💙'</div> */}
					<Loader size='75' text='Peppy Venues On Their Way To You 💙' />
				</div>
			</div>
		);
	} else {
		return (
			<div className='viewer_container'>
				<div className='venue_list_viewer'>
					{venues_list && props.page !== 'Details' && (
						<>
							<h1 className='head_thin'>Discover</h1>
							<h2 className='head_bold'>the best venues</h2>
							<p className='head_mini'>
								Party to your heart's content in unique places, flavored with
								themes of your choice.
							</p>
							<SearchBar
								venue_type_filter={venue_type_filter}
								setvenue_type_filter={setvenue_type_filter}
								venue_city_filter={venue_city_filter}
								setvenue_city_filter={setvenue_city_filter}
								sorted_list={sorted_list}
								setSortedList={setSortedList}
								serviced_cities={serviced_cities}
								venue_types={venue_types}
							/>

							<PropertyCardList
								properties={venues_list}
								page={props.page ? props.page : 'home'}
								current={venues_list.indexOf(venue_active)}
								selectVenue={setvenue_active}
							/>
						</>
					)}
					{venue_active ? (
						props.page !== 'Discover' && (
							<PropertyDetails
								property={venue_active}
								index={venues_list.indexOf(venue_active)}
								length={venues_list.length}
								page={props.page ? props.page : 'home'}
							/>
						)
					) : props.page !== 'Details' ? (
						<div className='venue_error'>
							<hr />
							<div className='venue_error_msg'>
								Uh-oh! Looks{' '}
								<span className='emoji' aria-label='emoji' role='img'>
									👀
								</span>{' '}
								like we lost that venue.
							</div>
							<div className='venue_error_emoji'>
								<span className='emoji' aria-label='emoji' role='img'>
									🥺
								</span>
							</div>
							<div className='venue_error_resolve'>
								Select from the above list or{' '}
								<Link
									className='outer_link venue_error_resolve_link'
									to={`/venues`}>
									<u>Discover Page</u>
									<span className='emoji' aria-label='emoji' role='img'>
										🏃
									</span>
								</Link>
								<span className='emoji' aria-label='emoji' role='img'>
									🏃
								</span>
							</div>
						</div>
					) : (
						<div className='venue_error'>
							<hr />
							<div className='venue_error_msg'>
								Uh-oh! Looks{' '}
								<span className='emoji' aria-label='emoji' role='img'>
									👀
								</span>{' '}
								like we lost that venue.
							</div>
							<div className='venue_error_emoji'>
								<span className='emoji' aria-label='emoji' role='img'>
									🥺
								</span>
							</div>
							<div className='venue_error_resolve'>
								Go{' '}
								<Link className='outer_link venue_error_resolve_link' to={`/`}>
									<u>Back to Home Page</u>{' '}
									<span className='emoji' aria-label='emoji' role='img'>
										🏃
									</span>
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
};

export default VenueViewer;
