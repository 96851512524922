import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import suspense from '../../xres/placeholders/suspense.svg';
export default function GridSuspenseImage(props) {
	const { src, height = '4rem', alt } = props;
	return (
		<Suspense
			delay={`1ms`}
			fallback={<img src={suspense} height={height} width='4rem' alt={alt} />}>
			<img
				src={src}
				height={height}
				width='100%'
				alt={alt}
				className='property_image'
				loading='lazy'
				decoding='async'
			/>
		</Suspense>
	);
}

GridSuspenseImage.propTypes = {
	src: PropTypes.string,
	height: PropTypes.string,
	alt: PropTypes.string,
};

GridSuspenseImage.defaultProps = {
	src: '',
	height: '100%',
	alt: '',
};
