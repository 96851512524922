import React from 'react';
// import PropTypes from 'prop-types';

const CostBreakdown = (props) => {
	const { basic_cost, discounted_amount, tax_rate, taxes, total_cost } = props;
	return (
		<div className='cbreak_container'>
			<p className='cbreak_pre_discount'>
				Basic Cost: <span className='cbreak_value'>₹{basic_cost}</span>
			</p>
			<p className='cbreak_discount_amount'>
				Applied Discount:{' '}
				<span className='cbreak_value'>₹{discounted_amount}</span>
			</p>
			<p className='cbreak_taxes'>
				GST ({tax_rate * 100}%): <span className='cbreak_value'>₹{taxes}</span>
			</p>
			<p className='cbreak_final'>
				Final Cost:{' '}
				<span className='cbreak_value price_high'>₹{total_cost}</span>
			</p>
		</div>
	);
};

export default CostBreakdown;
