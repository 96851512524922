//#region Main Libs
import React, { /*useEffect,*/ Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
//import { useDispatch } from "react-redux";
//import PropTypes from "prop-types";
//#endregion

//#region Project Components
import Header from '../components/common/Header';
import Loader from '../components/common/Loader';
import VenueViewer from '../components/venue/VenueViewer';
// import Heroform from '../components/home/Heroform';
// import PropertyViewer from '../components/property/PropertyViewer';
//#endregion

//#region Styles & Image Resources
import '../xstyles/home.css';
//https://unsplash.com/collections/9608840/cms
//#endregion

//#region Actions & Functions
//import { makeGetRequest } from "./../api/requests";
//import { properties } from "../api/endpoints";
//import { getProperties } from "./../store/actions/propertyActions";
//import { test } from "./../store/actions/propertyActions";
//#endregion

//#region Lazily Loaded components
const Testimonials = lazy(() => import('../components/home/Testimonials'));
const Blogger = lazy(() => import('../components/home/Blogger'));
// const BlogMini = lazy(() => import('../components/blog/BlogMini'));
//#endregion

//#region additional components - to be refactored
//#endregion

const Home = (props) => {
	//#region hooks and variables
	const imagesrc =
		'https://images.unsplash.com/flagged/photo-1574319523332-4cadaa531506?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200';

	const image = {
		src: imagesrc,
		alt: 'A couple who met for the first time at a house party',
		height: '400px',
	};

	const header = {
		mini: 'PEPSPOT',
		thin: 'good and great memories',
		bold: 'A bridge between',
	};

	//const dispatch = useDispatch();
	//useEffect(() => dispatch(getProperties(properties)), [dispatch]);
	//#endregion

	return (
		<div className='home_container'>
			<Helmet>
				<title>House Party Places in Delhi NCR and Goa | Thepepspot.com</title>
				<meta
					name='description'
					content='Looking for best house party places in Delhi NCR? Pepspot helps you in finding unique and budget-friendly party venues in Delhi NCR and Goa '
				/>
				<meta
					name='keywords'
					content='party places in Gurgaon, house party in Delhi, party places in Noida, party places in Delhi, Party Places in Goa, House party in goa, Villas in Goa, Beach house in Goa '
				/>
				<meta name='robots' content='index, follow' />
				<meta name='copyright' content='thepepspot.com' />
				<meta name='language' content='EN' />

				<meta name='theme-color' content='#008f68' />
			</Helmet>
			<Header image={image} header={header} textConstraint='title_constraint' />
			{/* <button className='test_button' onClick={() => dispatch(test())}>
				TEST
			</button> */}
			{/* <Heroform /> */}
			<Suspense
				fallback={<Loader size={100} text='Bringing our Best Party Places' />}>
				{/* <PropertyViewer /> */}
				<VenueViewer page='Home' />
				<Testimonials />
				{/* <Blogger show_count={3} /> */}
			</Suspense>
		</div>
	);
};

Home.propTypes = {};

export default Home;
