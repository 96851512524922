import React from 'react';
import PropTypes from 'prop-types';
import DefaultFallback from './ErrorFallback';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null };
	}

	// static propTypes = {
	// 	prop: PropTypes.func,
	// };

	// static defaultProps = {
	// 	FallbackComponent: DefaultFallback,
	// };

	static getDerivedStateFromError(error) {
		return { error };
	}

	render() {
		const { error } = this.state;
		if (error) {
			if (this.props.FallbackComponent) {
				return <this.props.FallbackComponent error={error} />;
			} else {
				return <DefaultFallback error={error} />;
			}
		}
		return this.props.children;
	}
}

export default ErrorBoundary;

// //#region example
// // function Bomb() {
// //   throw new Error('💥 CABOOM 💥')
// //   return null
// // }
// // function App() {
// //   const [explode, setExplode] = React.useState(false)
// //   return (
// //     <div>
// //       <div>
// //         <button onClick={() => setExplode(true)}>💣</button>
// //       </div>
// //       <div>
// //         <ErrorBoundary FallbackComponent={ErrorFallback}>
// //           {explode ? <Bomb /> : 'Push the button Max!'}
// //         </ErrorBoundary>
// //       </div>
// //     </div>
// //   )
// // }
// //#endregion
