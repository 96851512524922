import { createReducer, createAction } from '@reduxjs/toolkit';

export const initPropertyState = {
	// testing: false,
	fetchingBlogList: false,
	blogs_list: [
		{
			id: '',
			date: '',
			title: '',
			link: '',
			img: '',
		},
	],
};

// export const gettingTest = createAction('GETTING_TEST');
export const gettingBlogs = createAction('FETCHING_BLOG_LIST');
export const gotBlogs = createAction('FETCHED_BLOG_LIST');
export const initBlogs = createAction('INITIALIZE_BLOGS');
export const errorGettingBlogs = createAction('ERROR_GETTING_BLOGS');

export const reducer_blog = createReducer(initPropertyState, {
	[gettingBlogs]: (state) => {
		state.fetchingBlogList = true;
	},
	[gotBlogs]: (state) => {
		state.fetchingBlogList = false;
	},
	[initBlogs]: (state, action) => {
		state.blogs_list = action.payload;
	},
	[errorGettingBlogs]: (state, action) => {
		console.group('[reducer_blog.js] Error getting blogs');
		console.log('Error: ', JSON.stringify(action.payload.err));
		console.log('on Link: ', JSON.stringify(action.payload.path));
		console.groupEnd();
	},
	// [gettingTest]: (state) => {
	// 	state.testing = true;
	// },
});

export default reducer_blog;
