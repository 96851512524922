import React from 'react';
import PropTypes from 'prop-types';
import {
	FormControl,
	InputLabel,
	NativeSelect,
	FormHelperText,
	// Select,
	// makeStyles,
} from '@material-ui/core';
import { useField } from 'formik';

// const useStyles = makeStyles((theme) => ({
// 	formControl: {
// 		margin: theme.spacing(1),
// 		minWidth: 120,
// 	},
// 	selectEmpty: {
// 		marginTop: theme.spacing(2),
// 	},
// }));

const MUISelector = (props) => {
	// const classes = useStyles();
	const [field, meta] = useField(props);
	// console.log('Field Props are: ', field);
	const { placeholder, opts } = props;
	// console.log('[MUISelector] > Field Props: ', field);
	return (
		<FormControl className={props.className}>
			<InputLabel shrink htmlFor='age-native-label-placeholder'>
				{placeholder}
			</InputLabel>
			<NativeSelect
				{...props}
				inputProps={{
					name: field.name,
				}}
				{...field}
				variant='outlined'>
				{/* <option value='' disabled>
					None
				</option> */}
				{opts &&
					opts.map((opt, index) => (
						<option
							key={index}
							name={`${field.name}.${index}.value`}
							value={opt.value}>
							{opt.value}
						</option>
					))}
			</NativeSelect>
			{meta.touched && meta.error ? (
				<FormHelperText
					className='error_message'
					style={{ color: 'var(--col_red_deep)' }}>
					{meta.error}
				</FormHelperText>
			) : (
				<FormHelperText>{props.helper}</FormHelperText>
			)}
		</FormControl>
	);
};

MUISelector.propTypes = {
	label: PropTypes.string,
};

export default MUISelector;
