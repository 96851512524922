import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

const prod_config = {
	apiKey: process.env.REACT_APP_apiKey,
	authDomain: process.env.REACT_APP_authDomain,
	databaseURL: process.env.REACT_APP_databaseURL,
	projectId: process.env.REACT_APP_projectId,
	storageBucket: process.env.REACT_APP_storageBucket,
	messagingSenderId: process.env.REACT_APP_messagingSenderId,
	appId: process.env.REACT_APP_appId,
	measurementId: process.env.REACT_APP_measurementId,
};

// Your web app's Firebase configuration
const firebaseConfig = prod_config;

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const app = firebase.initializeApp(firebaseConfig);
// app.analytics();

// console.log('App data is: ', app);
// console.log('App name is: ', app.name);

/* #### User Authentication Related Code #### */
export const getAuthRef = () => {
	return app.auth();
};

export const getPhoneAuthProvider = () => {
	return app.auth.PhoneAuthProvider;
};

// export const authRef = app.auth()
export const gProvider = new firebase.auth.GoogleAuthProvider();

export const reCap = (id) => {
	const reCapRef = new firebase.auth.RecaptchaVerifier(id, {
		size: 'invisible',
		callback: function (response) {
			// reCAPTCHA solved, allow signInWithPhoneNumber.
			// onSignInSubmit();
			// console.log('[fb.js] > reCaptcha response: ', response);
			if (response !== null) {
				console.log('[fb.js] > reCaptcha generated successfully');
			}
		},
	});

	return reCapRef;
};

/** Function to get the detials of the user that's currently logged in*/
export const getUser = () => {
	return app.auth().currentUser;
};

/* ---- End of User Authentication Related Code ---- */

/* #### FireStore DB Related Code #### */
const db = app.firestore();

export const expDB = db.FieldValue;

export const adminsCollectionRef = db.collection('admins');
export const usersCollectionRef = db.collection('users');
export const venuesCollectionRef = db.collection('venues');
export const venuesPastColRef = db.collection('venues_past');
export const bookingsCollectionRef = db.collection('bookings');
export const bookingsPastColRef = db.collection('bookings_past');
export const mailTriggerColRef = db.collection('mail');
export const mailTemplateColRef = db.collection('templates');

export const feedbackCollectionRef = db.collection('feedback');

export const getUserDoc = (uid) =>
	usersCollectionRef
		.where('uid', '==', `${uid}`)
		.get()
		.then(function (querySnapshot) {
			querySnapshot.forEach(function (doc) {
				// doc.data() is never undefined for query doc snapshots
				console.log(doc.id, ' => ', doc.data());
			});
		})
		.catch(function (error) {
			console.log('Error getting documents: ', error);
		});

/* ---- End of FireStore DB Related Code ---- */

export default app;
