import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import TextField from '@material-ui/core/TextField';
import {
	FormControl,
	FormHelperText,
	// InputLabel
} from '@material-ui/core';

const MUITextField = ({ label, variant, ...props }) => {
	const [field, meta] = useField(props);
	// console.log('MUITextField Meta "', field.name, '": ', meta);
	return (
		<FormControl className={props.className}>
			{/* <InputLabel shrink htmlFor='age-native-label-placeholder'>
				{props.placeholder}
			</InputLabel> */}
			<TextField
				label={label}
				variant={variant}
				error={meta.touched && meta.error}
				{...props}
				{...field}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			{meta.touched && meta.error ? (
				<FormHelperText
					className='error_message'
					style={{ color: 'var(--col_red_deep)' }}>
					{meta.error}
				</FormHelperText>
			) : (
				<FormHelperText>{props.helper}</FormHelperText>
			)}
		</FormControl>
	);
};

MUITextField.propTypes = {
	label: PropTypes.string,
};

MUITextField.defaultProps = {
	variant: 'outlined',
};
export default MUITextField;
