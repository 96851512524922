import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import placeholder from '../../xres/pepspot_fullmark.svg';

const ImgUnsplash = (props) => {
	const { src, alt, height = '400px', width = '480px', className } = props;
	return (
		<Suspense
			fallback={
				<img
					src={src + '&q=10'}
					height={height}
					width='100%'
					alt={alt}
					className={className}
					loading='lazy'
					decoding='async'
				/>
			}>
			<img
				src={src !== null ? src : placeholder}
				height={height}
				width={width}
				alt={alt}
				className={className}
				loading='lazy'
				decoding='async'
				role={props.role ? props.role : 'img'}
				onClick={props.onClick ? props.onClick : null}
			/>
		</Suspense>
	);
};

ImgUnsplash.propTypes = {
	// src: PropTypes.string.isRequired,
	alt: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	className: PropTypes.string,
};

export default ImgUnsplash;
