// import React from 'react'
// import Img from '../../components/common/Img'

import kitchen from '../../xres/amenities/Self Service Kitchen.svg';
import bedrooms from '../../xres/amenities/Bedrooms.svg';
import karaoke from '../../xres/amenities/Karaoke.svg';
import living_area from '../../xres/amenities/Living Area.svg';
import music_sys from '../../xres/amenities/Music System.svg';
import parking from '../../xres/amenities/Parking.svg';
import pool from '../../xres/amenities/Pool Table.svg';
import swimming from '../../xres/amenities/Swimming Pool.svg';

import checkin from '../../xres/house_rules/checkin.svg';
import id from '../../xres/house_rules/validid.svg';
import ref_deposit from '../../xres/house_rules/refundablesecurity.svg';
import max_guests from '../../xres/house_rules/maxgathering.svg';
import music_timeout from '../../xres/house_rules/musictimeout.svg';
import cleaning_charges from '../../xres/house_rules/cleaningcharges.svg';
import outside_catering from '../../xres/house_rules/outsidecatering.svg';
import byob from '../../xres/house_rules/byob.svg';
import smoking from '../../xres/house_rules/smoking.svg';
import rules from '../../xres/house_rules/rules.svg';

const iconResolver = (iconlabel) => {
	let icon = iconlabel.toLowerCase();
	// console.log('[iconResolver] > icon is:', icon);
	switch (icon) {
		//Amenities
		case 'kitchen':
			return kitchen;
		case 'self help kitchen':
			return kitchen;
		case 'self service kitchen':
			return kitchen;
		case 'karaoke':
			return karaoke;
		case 'living area':
			return living_area;
		case 'music system':
			return music_sys;
		case 'sound system':
			return music_sys;
		case 'swimming pool':
			return swimming;
		case 'parking':
			return parking;
		case 'pool table':
			return pool;
		//Rules
		case 'bedrooms':
			return bedrooms;
		case 'music limit':
			return music_timeout;
		case 'music timeout':
			return music_timeout;
		case 'smoking':
			return smoking;
		case 'smoking zone':
			return smoking;
		case 'byob':
			return byob;
		case 'check in':
			return checkin;
		case 'check out':
			return checkin;
		case 'check in time':
			return checkin;
		case 'check out time':
			return checkin;
		case 'id':
			return id;
		case 'valid id':
			return id;
		case 'gov id':
			return id;
		case 'security deposit':
			return ref_deposit;
		case 'refundable security deposit':
			return ref_deposit;
		case 'max guests':
			return max_guests;
		case 'cleaning charges':
			return cleaning_charges;
		case 'outside catering':
			return outside_catering;
		case 'rules':
			return rules;
		case 'rule book':
			return rules;
		default:
			return rules;
	}
};

export default iconResolver;
