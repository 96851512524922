import React, { useState /*, useEffect*/ } from 'react';
// import PropTypes from 'prop-types'
import firebase from '@firebase/app';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
// import firebase from '@firebase/app';
// import ComboBoxMUI from '../../components/forms/ComboBoxMUILab';
import {
	TextField,
	// FormHelperText,
	// FormControl,
	// InputLabel,
} from '@material-ui/core';

import TextFieldMUI from '../../components/forms/TextFieldMUI';
import DateRangePicker from '../../components/forms/DateRangePicker';
import ScreenDebugger from '../../components/error_handling/ScreenDebugger';
import { phoneRegExp_2, pincodeRegExp } from '../../commons/constants';

import '../../xstyles/manage_bookings.css';
import Img from '../../components/common/Img';
import {
	bookingsCollectionRef,
	usersCollectionRef,
	// mailTriggerColRef,
	venuesCollectionRef,
	// expDB,
} from '../../services/fb';

// import { sendSMS, dispatchSMS } from '../../services/m91';
import CostBreakdown from '../../components/common/CostBreakdown';
import MUISelector from '../../components/forms/MUISelector';
// import { property_types } from './ManageVenueForm';

import {
	ad_bookingCreationStarted,
	ad_bookingCreated,
	ad_bookingCreationFailed,
	ad_bookingUpdated,
	ad_bookingUpdationFailed,
	ad_bookingUpdationStarted,
} from '../../store/reducers/reducer_admin';

// import {
// 	// sendMail,
// 	// getSMSMsgForBooking,
// 	checkPayStatus,
// 	sendPayLinkMail,
// 	handleOrder_admin,
// } from '../../store/actions/actions_admin';

import {
	getPayLink,
	// sendPaymentEmail,
	checkPaymentStatus,
	sendConfirmationSms,
	sendConfirmationEmail,
} from '../../services/back';

/** Actions Allowed for Admins
Admins can generate new booking
Generate Payment Link
Trigger Payment Reminder Emails

Change booking status
Change Payment Status

Delete Booking
Mark Booking as completed
 */

const PropertySelector = (props) => {
	const prop_list = useSelector((state) => state.properties.venues_list);
	let {
		form: {
			setValues,
			// setFieldTouched,
			values,
		},
		preselected,
	} = props;
	// console.log('[PropertySelector] > Selected Venue Received:', preselected);
	// console.log('[PropertySelector] > Values Received:', values);

	let [active_venue, setactive_venue] = useState(
		preselected ? preselected : null
	);

	// useEffect(() => {
	// 	if (
	// 		values.venue_selected &&
	// 		values.venue_selected.venue_id &&
	// 		values.venue_selected.venue_id !== ''
	// 	) {
	// 		setactive_venue(values.venue_selected);
	// 	}
	// }, [values.venue_selected]);

	// let incoming_venue = null;
	// if (init_val.property_id !== '') {
	// 	incoming_venue = prop_list.filter(
	// 		(ven) => ven.id === init_val.property_id
	// 	)[0];
	// }

	// const incoming_venue = prop_list.filter(
	// 	(item) => item.id === values.property_id
	// )[0];

	let filtered_props = prop_list;

	const handleVenueSelect = (ven) => {
		setValues({
			...values,
			venue_docref: ven.venueDocRef,
			venue_selected: {
				venue_id: ven.id,
				venue_pic: ven.cover_pic.pic_src,
				venue_name: ven.property_name + ' ' + ven.property_code_number,
				venue_location:
					ven.address.flat_num +
					', ' +
					ven.address.locality +
					', ' +
					ven.address.area +
					', ' +
					ven.address.city,
				venue_landmark:
					'near ' +
					ven.address.landmark +
					(ven.address.nearest_metro && ven.address.nearest_metro !== ''
						? ',  ' + ven.address.nearest_metro
						: null),
				venue_bookings: ven.bookings,
				venue_pricing_onBooking: ven.pricing,
			},
		});
	};

	// useEffect(() => {

	// }, [selectedVenue]);

	const [propertyNameFilter, setPropertyNameFilter] = useState('');
	const [propertyTypeFilter, setPropertyTypeFilter] = useState('');
	const [propertyCityFilter, setPropertyCityFilter] = useState('');
	const [propertyAreaFilter, setPropertyAreaFilter] = useState('');

	if (
		propertyNameFilter !== '' ||
		propertyTypeFilter !== '' ||
		propertyCityFilter !== '' ||
		propertyAreaFilter !== ''
	) {
		filtered_props = prop_list
			.filter((s) => {
				return (
					s.property_name.toLowerCase().includes(propertyNameFilter) ||
					s.property_code_number.toLowerCase().includes(propertyNameFilter)
				);
			})
			.filter((s) => s.property_type.toLowerCase().includes(propertyTypeFilter))
			.filter((s) => {
				return (
					s.address.city.toLowerCase().includes(propertyCityFilter) ||
					s.address.state.toLowerCase().includes(propertyCityFilter)
				);
			})
			.filter((s) => {
				return (
					s.address.locality.toLowerCase().includes(propertyAreaFilter) ||
					s.address.area.toLowerCase().includes(propertyAreaFilter) ||
					s.address.landmark.toLowerCase().includes(propertyAreaFilter) ||
					s.address.region.toLowerCase().includes(propertyAreaFilter) ||
					s.address.pincode.toLowerCase().includes(propertyAreaFilter)
				);
			});
	}

	const count = filtered_props.length;

	return (
		<div className='venue_selector_container'>
			<section className='venue_selector_filters'>
				<div className='booki_search_bar'>
					<div className='booki_search_fields'>
						<p className=''>Search by: </p>
						<div className='booki_search_field_container'>
							<TextField
								name='property_name'
								label='Search by Property Name'
								placeholder='PepCasa'
								type='text'
								variant='outlined'
								fullWidth
								value={propertyNameFilter}
								onChange={(event) => {
									setPropertyNameFilter(event.target.value.toLowerCase());
								}}
							/>
						</div>
						<div className='booki_search_field_container'>
							<TextField
								name='property_type'
								label='Search by Property Type'
								type='text'
								variant='outlined'
								fullWidth
								value={propertyTypeFilter}
								onChange={(event) => {
									setPropertyTypeFilter(event.target.value.toLowerCase());
								}}
							/>
						</div>

						<div className='booki_search_field_container'>
							<TextField
								name='property_area'
								label='Search by Area'
								type='text'
								variant='outlined'
								fullWidth
								value={propertyAreaFilter}
								onChange={(event) => {
									setPropertyAreaFilter(event.target.value.toLowerCase());
								}}
							/>
						</div>
						<div className='booki_search_field_container'>
							<TextField
								name='property_city'
								label='Search by City'
								type='text'
								variant='outlined'
								fullWidth
								value={propertyCityFilter}
								onChange={(event) => {
									setPropertyCityFilter(event.target.value.toLowerCase());
								}}
							/>
						</div>
					</div>
					<div className='booki_search_section booki_search_active'>
						<div className='booki_search_active_label'>Active Filters: </div>
						{propertyNameFilter !== '' ? (
							<div className='booki_search_active_filter'>
								Name: "<b>{propertyNameFilter}</b>"
							</div>
						) : null}
						{propertyTypeFilter !== '' ? (
							<div className='booki_search_active_filter'>
								Type: "<b>{propertyTypeFilter}</b>"
							</div>
						) : null}
						{propertyAreaFilter !== '' ? (
							<div className='booki_search_active_filter'>
								Area: "<b>{propertyAreaFilter}</b>"
							</div>
						) : null}
						{propertyCityFilter !== '' ? (
							<div className='booki_search_active_filter'>
								City: "<b>{propertyCityFilter}</b>"
							</div>
						) : null}
					</div>
				</div>
			</section>
			<section className='venue_card_lister'>
				{count !== 0 &&
					filtered_props.map((ven, i) => (
						<div
							className={
								ven !== active_venue
									? 'venue_card_adm'
									: 'venue_card_adm venue_card_adm_selected'
							}
							key={i}
							onClick={() => {
								handleVenueSelect(ven);
								setactive_venue(ven);
							}}>
							<Img
								src={ven.cover_pic.pic_src}
								alt={
									`${ven.property_name}-${ven.property_code_number} | ` +
									ven.cover_pic.pic_label
								}
								width='120px'
								height='100px'
								className='venue_card_adm_pic'
							/>
							<div className='venue_card_adm_info'>
								<p className='venue_card_adm_name'>
									{ven.property_name} {ven.property_code_number}
								</p>
								<p className='venue_card_adm_loc'>
									{ven.address.locality}, {ven.address.area}, {ven.address.city}
								</p>
								<p className='venue_card_adm_type'>
									{ven.property_type}{' '}
									<span className='venue_card_adm_cost'>
										starting ₹{ven.pricing.base}/night
									</span>
								</p>
							</div>
						</div>
					))}
			</section>
		</div>
	);
};

const checkBetween = (
	date,
	range_start,
	range_end,
	diff = 'day',
	diff_includes = '[)'
) => {
	// console.log('[] > ', date)
	const day = moment(date);
	const start = moment(range_start);
	const end = moment(range_end);
	// const day_format = 'Do MMM, YYYY';
	let isBetween = moment(day).isBetween(
		start,
		end,
		diff, //If you want to limit the granularity to a unit other than milliseconds, pass the units as the third parameter. Year, month, week, isoWeek, day, hour, minute, and second. null defaults to miliseconds
		diff_includes //inclusivity. A [ indicates inclusion of a value. A ( indicates exclusion. If the inclusivity parameter is used, both indicators must be passed.
	);
	// console.log(
	//   day.format("Do MMM, YYYY"),
	//   isBetween ? "is between" : "isn't between: ",
	//   start.format("Do MMM, YYYY"),
	//   "-",
	//   end.format("Do MMM, YYYY")
	// );

	// if (isBetween) {
	// 	console.log(
	// 		'[checkBet] > ',
	// 		day.format(day_format),
	// 		'is between',
	// 		start.format(day_format),
	// 		'-',
	// 		end.format(day_format)
	// 	);
	// }

	return isBetween;
};

const getSum = (total, item) => {
	// console.log(
	//   "[costCalculator > getSum] > Current Price: ",
	//   total,
	//   " incoming price: ",
	//   item.price
	// );
	return total + parseInt(item.price, 10);
};

const getDiscount = (price, coupon) => {
	// console.log('[getDiscount] > Got Coupon with code: ', coupon);

	let coupon_val = 0;
	return price * coupon_val;
};

const getTaxes = (price, tax_rate) => {
	// console.log(
	// 	'[getTaxes] > Base price is: ',
	// 	price,
	// 	' thus applicable gst rate is: ',
	// 	tax_rate
	// );
	return price * tax_rate;
};

export const costCalculator = (
	checkIn,
	checkOut,
	guest_count = 1,
	venue_pricing,
	coupon = 'NoCode',
	setFieldValue
) => {
	let start_time = performance.now();
	const {
		base,
		weekend,
		guest_limit_lower,
		cost_per_guest,
		date_range = [],
		tax_rate,
	} = venue_pricing;

	// console.log(
	// 	'[costCalc] > Booking between:',
	// 	checkIn.format('Do MMM, YYYY'),
	// 	'-',
	// 	checkOut.format('Do MMM, YYYY')
	// );

	const check_in = moment(checkIn).clone();
	const check_out = moment(checkOut).clone();

	const num_days = check_out.diff(check_in, 'days');
	// console.log('[costCalc] > Number of days of booking: ', num_days);

	const booking_arr = Array.from(Array(num_days), (_, index) => {
		const new_date = check_in.clone().add(index, 'days');
		// console.log(
		// 	'[Booking Day',
		// 	index + 1,
		// 	'] >',
		// 	new_date.format('ddd, Do MMM, YYYY')
		// );

		return {
			date: new_date,
			date_formatted: new_date.format('ddd, Do MMM, YYYY'),
			day: new_date.isoWeekday(),
			price: base,
		};
	});

	// console.log('[costCalc] > Booking Array is: ', booking_arr);

	// let cost = base;
	let num_weekends = 0;
	let num_special_pricing_days = 0;
	const num_extra_guests = guest_count - guest_limit_lower;

	let final_booking_array = booking_arr.map((item, index) => {
		const isWeekend = item.day === 5 || item.day === 6 || item.day === 7;

		if (isWeekend) {
			num_weekends = num_weekends + 1;
			// console.log(
			// 	'[isWeekEnd] > ',
			// 	item.date_formatted,
			// 	' is weekend priced => price: ',
			// 	weekend
			// );

			item.price = weekend;
		}

		date_range.map((dr) => {
			if (checkBetween(item.date, dr.start_date, dr.end_date, 'day', '[]')) {
				num_special_pricing_days = num_special_pricing_days + 1;
				// console.log(
				// 	'[isSpecial] > ',
				// 	item.date_formatted,
				// 	' is specially priced => price: ',
				// 	dr.price
				// );

				item.price = dr.price;
			}
			return null;
		});

		return item;
	});

	// console.log('[costCalc] > Total number of weekends is: ', num_weekends);
	// console.log(
	// 	'[costCalc] > Total number of special priced days is: ',
	// 	num_special_pricing_days
	// );

	//Weekends are fridays, saturdays and sundays

	// filter date_Range to output an array of objects which have the same months as the month of the booking

	// console.log('[costCalc] > Final Booking Array is: ', { final_booking_array });
	const cost = booking_arr.reduce(getSum, 0);
	// console.log('[costCalc] > Cost due to booking dates: ', { cost });

	let extra_guest_cost = 0;
	if (num_extra_guests > 0) {
		// console.log(
		// 	'[costCalc > Extra Guests] Number of extra guests is: ',
		// 	num_extra_guests
		// );
		extra_guest_cost = num_extra_guests * cost_per_guest;
		// console.log(
		// 	'[costCalc > Extra Guests] Cost due to extra guests: INR',
		// 	extra_guest_cost
		// );
	}

	const basic_cost = cost + extra_guest_cost;
	// console.log('[costCalc] > Pre-Discount Price: ', basic_cost);

	let discounted_amount = getDiscount(basic_cost, coupon);
	// console.log('[costCalc] > Applied Discount: ', discounted_amount);

	let subtotal = basic_cost - discounted_amount;
	// console.log('[costCalc] > Pre-Tax Amount: ', subtotal);

	let taxes = getTaxes(subtotal, tax_rate);
	// console.log('[costCalc] > Applied Tax Amount: ', taxes);

	// Rounding to 2 decimal places
	// let total_cost = Math.round((subtotal + taxes) * 100) / 100;

	// Rounding up to nearest gretest integer
	let total_cost = Math.ceil(subtotal + taxes);
	// console.log('[costCalc] > Post-Tax Amount: ', total_cost);

	setFieldValue('cost_breakdown.days', num_days);
	setFieldValue('cost_breakdown.weekends', num_weekends);
	setFieldValue('cost_breakdown.specials', num_special_pricing_days);
	setFieldValue('cost_breakdown.extra_guests', num_extra_guests);
	setFieldValue('cost_breakdown.base_cost', basic_cost);
	setFieldValue('cost_breakdown.discount_total', discounted_amount);
	setFieldValue('cost_breakdown.tax_rate', tax_rate);
	setFieldValue('cost_breakdown.total_tax', taxes);

	let end_time = performance.now();
	// console.log('>> [costCalc] > took ' + (end_time - start_time) + 'ms.');

	return setFieldValue('cost_breakdown.total_cost', total_cost);
};

const getPayLink_admin = (values, setFieldValue) => {
	// console.log('1. [getPayLink_admin] values: ', values);

	const transac_start_time = Date.now();
	const transaction_id =
		'guest_' +
		values.billing.customer_name.replace(/ /g, '_') +
		'_' +
		transac_start_time;
	// console.log('2. [getPayLink_admin] transaction_id: ', transaction_id);

	let booking_with_transaction_id = {
		...values,
		transaction_id,
		status: 'pending_payment',
	};
	// console.log(
	// 	'3. [getPayLink_admin] transaction_id: ',
	// 	booking_with_transaction_id
	// );
	let link_gen = null;
	// let err = null;
	getPayLink(booking_with_transaction_id)
		.then((payob) => {
			link_gen = payob.result.paymentLink;
			// console.log(
			// 	'4. [getPayLink_admin] > getPayLink > Success > Link:',
			// 	link_gen
			// );
			setFieldValue('transaction_id', transaction_id);
			setFieldValue('payment.payment_link', link_gen);
			if (payob.result.status === 'ERROR') {
				// console.log(
				// 	'4.err [getPayLink_admin] > getPayLink > Error from 3P API > Message:',
				// 	payob.result.status,
				// 	' as ',
				// 	payob.result.reason
				// );
				setFieldValue('transaction_id', payob.result.status);
				setFieldValue('payment.payment_link', payob.result.reason);
			}
		})
		.catch((error) => {
			link_gen = error.message;
			// console.log(
			// 	'4.err [getPayLink_admin] > getPayLink > Error > Message:',
			// 	link_gen
			// );
			setFieldValue('transaction_id', transaction_id);
			setFieldValue('payment.payment_link', link_gen);
		});
	// return null;
};

// const sendConfirmationSMS = (values) => {};

const handleDeleteBooking = (values) => {
	values.venue_docref.update({
		bookings: firebase.firestore.FieldValue.arrayRemove({
			id: '',
			check_in: '',
			check_out: '',
			booked_on: '',
			booked_by_id: '',
			booked_by_name: '',
			booked_for_days: '',
		}),
	});
};
// userRef.update({
// 	"userevents": firebase.firestore.FieldValue.arrayRemove({"eventid": ..., "date": ..., "desc":..., "status":...})
// });
const updateBookingInVenue = (field_key, values) => {
	const venueDocRef = venuesCollectionRef.doc(values.venue_docref);
	// console.log('Got doc Ref', venueDocRef);
	let venueDoc = null;

	venueDocRef
		.get()
		.then((doc) => {
			if (doc.exists) {
				venueDoc = doc.data();
				// console.log('Venue data:', venueDoc);
			} else {
				// doc.data() will be undefined in this case
				// console.log('No such document!');
			}
		})
		.catch((error) => {
			// console.log('Error getting document:', error.message);
		});

	// get venue bookings
	let venue_bookings = venueDoc.bookings;
	// find corresponding booking in venue bookings and store it
	let current_booking = venue_bookings.filter(
		(book) => book.id === values.booked_on
	)[0];
	// console.log('Current Booking saved in Venue is:', current_booking);
	// remove the stored booking from bookings array in venue by using the stored booking
	// venueDocRef.update({
	// 	"bookings":firebase.firestore.FieldValue.arrayRemove()
	// })
};

const updateBookingInUser = (field_key, values) => {
	const phone = values.billing.phone;
	// console.log('Phone:', phone);
	usersCollectionRef
		.where('phone_number', '==', phone)
		.get()
		.then((snap) =>
			snap.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				console.log('User doc with id:', doc.id, ' => ', doc.data());
			})
		)
		.catch((error) => {
			console.log('Error getting documents: ', error.message);
		});
};

const delete_booking = (booking) => {
	// Check booking status and if a payment link exists
	// if payment link exists then update
	// 	1. venue Doc
	// 	2. user Doc (if user is not guest user ie: booking_verified_by_user = false)
	// 	3. booking Doc
	// then return with a success message
};

const approveBooking = (values, setFieldValue) => {
	setFieldValue('isApproved', true);
	setFieldValue('status', 'pending_payment');
	// console.log('[Booking Approved] New Values are:  ', values);
};

const cancelBooking = (values, setFieldValue) => {
	// check if the booking is confirmed
	if (values.status === 'Confirmed') {
		console.log('Cancelling Confirmed Booking');
		// if confirmed remove booking from Venue and User
		//update User > Bookings &&
		updateBookingInUser('bookings', values);
		//Venue >> Bookings
		updateBookingInVenue('bookings', values);
	}
	console.log('Cancelling Booking');
	setFieldValue('isApproved', false);
	setFieldValue('status', 'cancelled');
	console.log('[Booking Cancelled] New Values are:  ', values);
	alert('Remember to Save the Booking');
};

const checkPayStatus = (values, setFieldValue) => {
	let tid = values.transaction_id;
	let status = checkPaymentStatus(tid);
	setFieldValue('payment.status', status);
};

const forceConfirmPayment = (values, setFieldValue) => {
	let tid = values.transaction_id;
	setFieldValue('status', 'confirmed');
};

const validationRules = Yup.object({
	guest_count: Yup.string()
		.max(15, 'Must be 15 characters or less')
		.required('Required'),
	check_in: Yup.string().required('Required'),
	check_out: Yup.string().required('Required'),
	status: Yup.string().required('Required'),
	billing: Yup.object({
		customer_name: Yup.string()
			.min(4, 'Must be 4 characters or more')
			.max(50, 'Must be max 50 characters or less')
			.required('Required'),
		phone: Yup.string()
			.matches(phoneRegExp_2, 'Invalid Phone Number')
			.required('Required'),
		email: Yup.string()
			.min(5, 'Must be 5 characters or more')
			.required('Required'),
		flat_num: Yup.string()
			.max(25, 'Must be 25 characters or less')
			.required('Required'),
		locality: Yup.string()
			.max(50, 'Must be 50 characters or less')
			.required('Required'),
		area: Yup.string().max(50, 'Must be 50 characters or less'),
		city: Yup.string()
			.max(15, 'Must be 15 characters or less')
			.required('Required'),
		pincode: Yup.string()
			.matches(pincodeRegExp, 'Pincode is not valid')
			.min(6, 'Invalid Number Must be at least 10 characters')
			.max(6, 'Invalid Number Must be at most 10 characters')
			.required('Required'),
		// state: Yup.string()
		// 	.max(15, 'Must be 15 characters or less')
		// 	.required('Required'),
		// country: Yup.string()
		// 	.max(15, 'Must be 15 characters or less')
		// 	.required('Required'),
	}),
});

const booking_statuses = [
	{ id: '1', label: 'New', value: 'new' },
	{ id: '2', label: 'Approved', value: 'approved' },
	{ id: '3', label: 'Pre-Approved', value: 'pre_approved' },
	{ id: '4', label: 'Confirmed', value: 'Confirmed' },
	{ id: '5', label: 'Completed', value: 'Completed' },
	{ id: '6', label: 'Pending Approval', value: 'pending_approval' },
	{ id: '7', label: 'Pending Payment', value: 'pending_payment' },
	{ id: '8', label: 'Cancelled', value: 'cancelled' },
	{ id: '9', label: 'Rescheduled', value: 'rescheduled' },
];

const BookingsForm = (props) => {
	const { init_val } = props;

	// const dispatch = useDispatch();
	// const [finalprice, setFinalprice] = useState(0);

	return (
		<div className='booking_form_container'>
			{/* <DateRangePickerAdv bookings={null} check_in={null} check_out={null} /> */}
			<section className=''>
				<Formik
					initialValues={init_val}
					validationSchema={validationRules}
					onSubmit={(values, { setSubmitting }) => {
						props.handleSubmit(values);
						setSubmitting(false);
					}}>
					{(form_props) => (
						<Form className='booking_form'>
							<section className='booking_venue'>
								<b>Step 1: Select a Venue</b>
								<div className='form_field_container' style={{ zIndex: '200' }}>
									<Field
										component={PropertySelector}
										// name="DatePickerWithFormik"
										required
									/>
								</div>
							</section>
							{form_props.values.venue_selected &&
							form_props.values.venue_selected.venue_id === '' ? (
								<h4 className='no_booking'>
									Booking doesn't exist yet. Select a Venue to Continue
								</h4>
							) : (
								<section className='booking_details_container'>
									<section className='booking_details'>
										{form_props.values.venue_selected &&
										form_props.values.venue_selected.venue_id &&
										form_props.values.venue_selected.venue_id !== '' ? (
											<div className=''>
												<b>Venue Selected:</b>
												<div
													className={'venue_card_adm venue_card_adm_selected'}>
													<Img
														src={form_props.values.venue_selected.venue_pic}
														alt={form_props.values.venue_selected.venue_name}
														width='120px'
														height='100px'
														className='venue_card_adm_pic'
													/>
													<div className='venue_card_adm_info'>
														<p className='venue_card_adm_name'>
															{form_props.values.venue_selected.venue_name}
														</p>
														<p className='venue_card_adm_loc'>
															{form_props.values.venue_selected.venue_location}
														</p>
														<p className='venue_card_adm_type'>
															<span className='venue_card_adm_cost'>
																starting ₹
																{
																	form_props.values.venue_selected
																		.venue_pricing_onBooking.base
																}
																/night
															</span>
														</p>
													</div>
												</div>
												<br />
											</div>
										) : null}
										<b>Step 2: Select Booking Dates</b>
										<div className='form_field_container'>
											<Field
												component={DateRangePicker}
												bookings={
													form_props.values.venue_selected.venue_bookings
												}
												// name="DatePickerWithFormik"
												// disabled={true}
												required
											/>
										</div>
										<div className='form_field_container'>
											<TextFieldMUI
												label='Guest Count'
												name='guest_count'
												type='text'
												placeholder='15'
											/>
										</div>
										{/* 
										<h4>Select Checkin & Checkout Dates first</h4> */}
										<button
											className='form_submit_button'
											type='button'
											disabled={
												form_props.values.check_in === null ||
												form_props.values.check_out === null ||
												form_props.values.guest_count === ''
											}
											onClick={() =>
												costCalculator(
													form_props.values.check_in,
													form_props.values.check_out,
													form_props.values.guest_count,
													form_props.values.venue_selected
														.venue_pricing_onBooking,
													form_props.values.coupon,
													form_props.setFieldValue
												)
											}>
											Check Price
										</button>
										{form_props.values.check_in === '' ||
										form_props.values.check_out === '' ||
										form_props.values.guest_count === '' ? (
											<>
												<div className=''>
													To check prices you first need to fill :-
													<br />
													1. CheckIn Data <br />
													2. CheckOut Date & <br />
													3. Guest Count
												</div>
											</>
										) : (
											<>
												<CostBreakdown
													basic_cost={
														form_props.values.cost_breakdown.base_cost
													}
													discounted_amount={
														form_props.values.cost_breakdown.discount_total
													}
													tax_rate={form_props.values.cost_breakdown.tax_rate}
													taxes={form_props.values.cost_breakdown.total_tax}
													total_cost={
														form_props.values.cost_breakdown.total_cost
													}
												/>
											</>
										)}
									</section>

									<section className='booking_status_card'>
										{form_props.values.venue_selected &&
										form_props.values.venue_selected.venue_id &&
										form_props.values.venue_selected.venue_id === '' ? (
											<h4 className='no_booking'>
												Booking doesn't exist yet. <br />
												Select a Venue to Continue
											</h4>
										) : (
											<>
												<Img
													src={form_props.values.venue_selected.venue_pic}
													alt={form_props.values.venue_selected.venue_name}
													width='400px'
													height='320px'
													className='booking_status_pic'
												/>
												<div className='booking_status_venue_info'>
													<p className='venue_card_adm_name'>
														{form_props.values.venue_selected.venue_name}
													</p>
													<p className='venue_card_adm_loc'>
														{form_props.values.venue_selected.venue_location}
													</p>

													<p className='venue_card_adm_cost'>
														starting ₹
														{
															form_props.values.venue_selected
																.venue_pricing_onBooking.base
														}
														/night
													</p>
												</div>
												{form_props.values.check_in !== '' &&
												form_props.values.check_out !== '' ? (
													<div className='booking_status_booking_info'>
														<p className='books_booking_date'>
															On:{' '}
															{moment(form_props.values.check_in).format(
																'ddd, Do MMM'
															)}
															-
															{moment(form_props.values.check_out).format(
																'ddd, Do MMM'
															)}
														</p>{' '}
														<p className='books_booking_guests'>
															for {form_props.values.guest_count} guests
														</p>
													</div>
												) : (
													<h4 className='no_booking'>No Dates Selected</h4>
												)}

												<div className='booking_status_actions'>
													<div className='books_action_item'>
														<p className='books_action_label'>Booking Status</p>
														<div className='books_act_item_pair'>
															<div className='books_act_item_val'>
																<MUISelector
																	name='status'
																	type='select'
																	opts={booking_statuses}
																	placeholder='Booking Status'
																	helper='Set booking status'
																/>
															</div>
															{form_props.values.isApproved === false ? (
																<button
																	className='books_act_item_action'
																	type='button'
																	onClick={() =>
																		approveBooking(
																			form_props.values,
																			form_props.setFieldValue
																		)
																	}>
																	Approve
																</button>
															) : (
																<button
																	className='books_act_item_action books_act_item_warn'
																	type='button'
																	onClick={() =>
																		cancelBooking(
																			form_props.values,
																			form_props.setFieldValue
																		)
																	}>
																	Cancel Booking
																</button>
															)}
														</div>
													</div>
													<div className='books_action_item'>
														<p className='books_action_label'>Payment Status</p>
														<div className='books_act_item_pair'>
															<p className='books_act_item_val'>
																{form_props.values.payment.status}
															</p>
															{form_props.values.isApproved === false ? (
																<button
																	className='books_act_item_action books_act_item_action_disabled'
																	disabled={!form_props.values.isApproved}>
																	Requires Admin Approval
																</button>
															) : (
																<>
																	{/* {form_props.values.transaction_id !== '' && (
																		<button
																			className='books_act_item_action'
																			type='button'
																			onClick={() =>
																				sendPaymentEmail(
																					form_props.values.transaction_id
																				)
																			}>
																			Send Reminder
																		</button>
																	)} */}
																</>
															)}
														</div>
													</div>
												</div>
											</>
										)}
									</section>

									<section className='customer_pii'>
										<div className='customer_details_container'>
											<section className='customer_details'>
												<b>Step 3.a: Customer Contact Info</b>
												<div className='form_field_container'>
													{/* <FastField
																name={`bookings.${index}.message`}
																type='text'
																label='Message'
																helper='Remarks on Booking'
																value={item.message}
																component={MUITextFieldFast}
															/> */}
													<TextFieldMUI
														label='Customer Name'
														name='billing.customer_name'
														type='text'
														placeholder='Rahul Apte'
													/>
												</div>
												<div className='form_field_container'>
													<TextFieldMUI
														label='Contact Number'
														name='billing.phone'
														type='text'
														placeholder='1234560789'
														helper='Make sure to include a country code (+91)'
													/>
												</div>
												<div className='form_field_container'>
													<TextFieldMUI
														label='Email Address'
														name='billing.email'
														type='email'
														placeholder='jane@gmail.com'
													/>
												</div>
											</section>
											<section className='customer_address'>
												<b>Step 3.b: Customer Address</b>
												<TextFieldMUI
													label='Flat/Building'
													name='billing.flat_num'
													type='text'
													placeholder='Flat No. 1105'
												/>
												<TextFieldMUI
													label='Locality'
													name='billing.locality'
													type='text'
													placeholder='Random Apartments, Sector 12'
												/>
												<TextFieldMUI
													label='Region'
													name='billing.area'
													type='text'
													placeholder='Dwarka'
												/>
												<TextFieldMUI
													label='City'
													name='billing.city'
													type='text'
													placeholder='Delhi'
												/>
												<TextFieldMUI
													label='Pincode'
													name='billing.pincode'
													type='text'
													placeholder='110001'
												/>
												{/* <TextFieldMUI
													label='State'
													name='billing.state'
													type='text'
													placeholder='NCR'
												/>
												<TextFieldMUI
													label='Country'
													name='billing.country'
													type='text'
													placeholder='India'
												/> */}
											</section>
											<br />
											{(!form_props.values.booking_verified_by_user ||
												true) && (
												<section className='booking_meta_actions'>
													<b>Step 4: Generate Payment Link (optional)</b>

													<br />
													<button
														className='books_act_item_action get_pay_link'
														type='button'
														onClick={() => {
															getPayLink_admin(
																form_props.values,
																form_props.setFieldValue
															);
															alert('generating Payment link form API');
														}}>
														Generate Payment Link
													</button>
													<p className=''>
														Transaction Id:{' '}
														{form_props.values.transaction_id &&
															form_props.values.transaction_id}
													</p>
													<TextFieldMUI
														label='Payment Link'
														name='payment.payment_link'
														type='text'
														required={false}
														placeholder='https://'
													/>
													<p className='WARN'>
														NOTE: When generating a payment link, remember to
														save the booking, before sending the payment link to
														the user. Otherwise the user will be able to
														complete the payment but the system will not save
														that payment in this booking.
													</p>
													<br />
													{form_props.values.transaction_id &&
														form_props.values.transaction_id !== '' && (
															<div className='post_db_save'>
																<div className=''>
																	<b>Step 4.b Help User make the payment</b>
																	<br />
																	{/* <button
																		className='books_act_item_action'
																		type='button'
																		onClick={() =>
																			sendPaymentEmail(
																				form_props.values.transaction_id
																			)
																		}>
																		Send Payment Link via Email
																	</button> */}
																	<p className=''>
																		Payment Status:{' '}
																		{form_props.values.payment.status &&
																			form_props.values.payment.status}
																	</p>
																	<button
																		className='books_act_item_action'
																		type='button'
																		onClick={() =>
																			checkPayStatus(
																				form_props.values,
																				form_props.setFieldValue
																			)
																		}>
																		Check Payment Status
																	</button>
																</div>

																<br />
																<div className=''>
																	<b>
																		Step 4.c Notify User about Booking
																		Confirmation (Use After saving the booking)
																	</b>
																	<br />
																	<button
																		className='books_act_item_action'
																		type='button'
																		onClick={() =>
																			sendConfirmationSms(form_props.values)
																		}>
																		Send Confirmation SMS
																	</button>{' '}
																	<button
																		className='books_act_item_action'
																		type='button'
																		onClick={() =>
																			sendConfirmationEmail(form_props.values)
																		}>
																		Send Confirmation Email
																	</button>
																</div>
															</div>
														)}
													<button
														className='books_act_item_action books_act_item_warn'
														type='button'
														onClick={() =>
															forceConfirmPayment(form_props.values)
														}>
														⚠ Confirm 3rd Party Payment
													</button>
												</section>
											)}
										</div>

										<button className='form_submit_button' type='submit'>
											Save Booking
										</button>
									</section>

									<div className='form_debugger_container'>
										<ScreenDebugger
											source='Data for Current Form'
											values={{
												values: form_props.values,
											}}
											isOpen={false}
										/>
										<ScreenDebugger
											source='Errors in Current Form'
											values={{
												errors: form_props.errors,
											}}
											isOpen={true}
										/>
										<ScreenDebugger
											source='Data Loaded for Current Form'
											values={{
												init_values: form_props.initialValues,
											}}
											isOpen={false}
										/>
									</div>
								</section>
							)}
						</Form>
					)}
				</Formik>
			</section>
		</div>
	);
};

BookingsForm.propTypes = {};

// export default BookingForm;

const BookingFormExisting = (props) => {
	const { init_val } = props;
	const dispatch = useDispatch();

	const initial_values = {
		...init_val,
		check_in: moment(init_val.check_in),
		check_out: moment(init_val.check_out),
	};

	const handleUpdateExistingBooking = (values) => {
		dispatch(ad_bookingUpdationStarted());
		console.log(
			'[ManageBookingForm > BookingsForm] > values for updated booking:',
			values
		);
		let bookingDocRef = initial_values.bookingDocRef;
		let updated_booking = {
			...values,
			check_in: moment(values.check_in).format(),
			check_out: moment(values.check_out).format(),
		};
		console.log(
			'[ManageBookingForm > BookingExisting > handleUpdateExistingBooking > 2] > id for new booking:',
			updated_booking
		);

		bookingsCollectionRef
			.doc(bookingDocRef)
			.set(updated_booking, { merge: true })
			.then((res) => {
				console.log(
					'[ManageBookingForm > BookingExisting > handleUpdateExistingBooking > 3] > Document successfully written! > with data: ',
					res
				);
				dispatch(ad_bookingUpdated({ updated_booking }));
				alert('Booking Successfully Updated');
				navigate('/admin_home');
			})
			.catch((error) => {
				const simple_error = {
					code: error.code,
					msg: error.message,
				};
				console.error(
					'[ManageBookingForm > BookingExisting > handleUpdateExistingBooking > 4] > Error writing document: ',
					error.message
				);
				dispatch(ad_bookingUpdationFailed(simple_error));
				alert(simple_error.msg);
			});

		return null;
	};

	return (
		<div className='width_container booking_form_container'>
			<header className='subsec_header'>
				<h2 className='subsec_header_title'>
					Update Existing Booking #{initial_values.booking_id}
				</h2>
			</header>
			<BookingsForm
				init_val={initial_values}
				handleSubmit={handleUpdateExistingBooking}
			/>
		</div>
	);
};

BookingFormExisting.propTypes = {};

// export default ExistingBookingForm

const BookingFormNew = (props) => {
	const { init_val } = props;
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);

	const handleAddNewBooking = (values) => {
		dispatch(ad_bookingCreationStarted());
		console.log(
			'[ManageBookingForm > BookingsFormNew ] > values for new booking:',
			values
		);

		let customer_id =
			'guest_' + values.billing.customer_name.replace(/ /g, '_');
		let billing = { ...values.billing, cust_id: customer_id };
		let booked_on = Date.now();
		let new_booking_ID = customer_id + '_' + booked_on;
		let bookingDocRef = new_booking_ID;

		let formatted_check_in = moment(values.check_in).format();
		let formatted_check_out = moment(values.check_out).format();

		let status = 'pending_payment';
		let isApproved = true;
		// let transaction_id = customer_id + '_' + booked_on;
		let booking_note =
			'Booked by ' + values.billing.customer_name + ' on ' + new Date();
		let booking_verified_by_user = false;
		// { id: '1', label: 'New', value: 'new' },
		// { id: '2', label: 'Approved', value: 'approved' },
		// { id: '3', label: 'Paid', value: 'paid' },
		// { id: '4', label: 'Completed', value: 'completed' },
		// { id: '5', label: 'Pending Approval', value: 'pending_approval' },
		// { id: '6', label: 'Pending Payment', value: 'pending_payment' },
		// { id: '7', label: 'Cancelled', value: 'cancelled' },
		// { id: '8', label: 'Rescheduled', value: 'rescheduled' },

		// if (values.property_req_approval) {
		// 	isApproved = false;
		// 	status = 'pending_approval';
		// } else {
		// 	isApproved = true;
		// 	status = 'Approved';
		// }

		let new_booking = {
			booking_id: new_booking_ID,
			booked_on,
			check_in: formatted_check_in,
			check_out: formatted_check_out,
			guest_count: values.guest_count,

			cost_breakdown: values.cost_breakdown,
			billing: billing,
			payment: values.payment,

			status: status,
			isApproved: isApproved,

			venue_docref: values.venue_docref,
			venue_selected: values.venue_selected,

			bookingDocRef,
			transaction_id: values.transaction_id,
			booking_note,
			booking_verified_by_user,
		};

		const venue_ref = venuesCollectionRef.doc(values.venue_docref);

		let booking_object_for_venue = {
			booked_on: new_booking.booked_on,
			booked_by: 'Admin',
			booked_by_name: user.name_given + ' ' + user.name_family,
			booked_for_id: customer_id,
			booked_for_name: new_booking.billing.customer_name,
			booked_for_phone: new_booking.billing.phone,
			booked_for_email: new_booking.billing.email,
			check_in: new_booking.check_in,
			check_out: new_booking.check_out,
			guest_count: new_booking.guest_count,
			id: new_booking.booked_on,
			message: new_booking.booking_note,
			booked_pricing: new_booking.cost_breakdown,
		};

		console.log(
			'[ManageBookingForm > BookingNew > handleAddNewBooking > 2] > id for new booking:',
			new_booking
		);

		console.log(
			'[ManageBookingForm > BookingNew > handleAddNewBooking > 2b] > booking data for Venue:',
			booking_object_for_venue
		);

		bookingsCollectionRef
			.doc(bookingDocRef)
			.set(new_booking, { merge: true })
			.then((res) => {
				console.log(
					'[ManageBookingForm > BookingNew > handleAddNewBooking > 3] > Document successfully written! > with data: ',
					res
				);

				if (status === 'confirmed') {
					console.log(
						'[ManageBookingForm > BookingNew > handleAddNewBooking > 4] > Writing to Venue Doc as booking is confirmed by admin'
					);
					venue_ref.update({
						bookings: firebase.firestore.FieldValue.arrayUnion(
							booking_object_for_venue
						),
					});
				}

				//Mail Trigger
				//'devpepspot@gmail.com',
				// sendMail(
				// 	new_booking.billing.email,
				// 	'[Pepspot] Party venue has been booked',
				// 	new_booking
				// );

				// let msg = getSMSMsgForBooking(new_booking.status, new_booking);
				// console.log('SMS Contentis: ', msg);

				// sendSMS(new_booking.billing.phone, msg);

				dispatch(ad_bookingCreated({ new_booking }));
				alert('Booking Successfully Added');
				navigate('/admin_home');
			})
			.catch((error) => {
				let incoming_error = {
					e_code: error.code,
					e_message: error.message,
				};
				console.error(
					'[ManageBookingForm > BookingNew > handleAddNewBooking > 4] > Error writing document: ',
					incoming_error
				);
				dispatch(ad_bookingCreationFailed(incoming_error));
				alert(incoming_error.e_message);
			});

		return null;
	};

	return (
		<div className='width_container booking_form_container'>
			<header className='subsec_header'>
				<h2 className='subsec_header_title'>Create New Booking</h2>
			</header>
			<BookingsForm init_val={init_val} handleSubmit={handleAddNewBooking} />
		</div>
	);
};

BookingFormNew.propTypes = {};

// export default NewBookingForm

const ManageBookingForm = (props) => {
	const books = useSelector((state) => state.admin.bookings_list);
	const { bookingid, new_book } = props;
	//check if the booking id exists on firebase. if it doesn't return new booking form

	// let admin select a property - venue lookup via venue name
	// let admin select a user - default to guest - user lookup via mobile number
	// if selected user is guest have the admin input contact details and billing address
	// User Name contact info and address details are to be visible whether the user is a guest or exisitng user.
	// lookup for users and booking has to be done via firestore. // Note to self: Don't try to be over-smart about it.
	// Lookup for venues can be done via redux store since all venues have to be fetched anyways on initial app load.

	if (bookingid === 'new') {
		return <BookingFormNew init_val={new_book} />;
	} else {
		// if (bookingid !== 'new')
		const book = books.filter((book) => book.booking_id === bookingid)[0];
		console.log('Booking is: ', book);

		if (books.length > 0 && book !== undefined && book !== null) {
			const existing_book = { ...new_book, ...book };
			return (
				<BookingFormExisting bookid={bookingid} init_val={existing_book} />
			);
		} else {
			console.log(
				'user enterted booking id in url as: ',
				bookingid,
				" but id doesn't exist in DB store"
			);
			return <BookingFormNew init_val={new_book} />;
		}
	}
};

ManageBookingForm.propTypes = {};

ManageBookingForm.defaultProps = {
	new_book: {
		booking_id: 'cashfree_test_001',
		status: 'new', //paid, confirmed, cancelled
		isApproved: true, //boolean

		venue_docref: '',
		venue_selected: {
			venue_id: '',
			venue_pic: '',
			venue_name: '',
			venue_location: '',
			venue_bookings: [],
			venue_pricing_onBooking: {},
		},

		check_in: '', //select form react-dates
		check_out: '', //select form react-dates
		guest_count: '', //Numeric input by admin

		transaction_id: '', //Generated with payment link
		booking_note: '', //AutoGenerate in handleSubmitNewBooking like:'Booked by Greg House on ' + new Date();
		booking_verified_by_user: false,

		billing: {
			cust_id: '', //AutoGenerate in handleSubmitNewBooking
			customer_name: '', //Input by admin
			company: '', //Input by admin
			email: '', //Input by admin
			phone: '', //Input by admin

			flat_num: '', //Input by admin
			locality: '', //Input by admin
			area: '', //Input by admin
			city: '', //Input by admin
			pincode: '', //Input by admin
			state: '', //Input by admin
			country: 'India',
		},
		cost_breakdown: {
			days: 0,
			weekends: 0,
			specials: 0,
			extra_guests: 0,
			base_cost: 0,
			coupon: '',
			discount_total: '',
			tax_rate: '',
			total_tax: '',
			total_cost: '',
		},
		payment: {
			status: 'new',
			currency: 'INR',
			method: '', //get response from cashfree
			date_paid: '', //get response from cashfree
			amount_paid: '', //get response from cashfree
			payment_link: '', //generate (hopefully) for redirect.
		},
	},
};

export default ManageBookingForm;

/* <div className='booki_search_field_container'>
							<FormControl className={''}>
								<InputLabel shrink htmlFor='age-native-label-placeholder'>
									Search by Property Type
								</InputLabel>
								<NativeSelect
									name='property_type'
									label='Search by Property Type'
									type='select'
									fullWidth
									inputProps={{
										name: 'venue_filter.type',
									}}
									onChange={(event) => {
										setPropertyTypeFilter(event.target.value.toLowerCase());
									}}>
									<option value='' disabled>
										None
									</option>
									{property_types &&
										property_types.map((opt, index) => (
											<option
												key={index}
												name={`${'venue_filter.type'}.${index}.value`}
												value={opt.value}>
												{opt.label}
											</option>
										))}
								</NativeSelect>
								<FormHelperText>Select the type of property</FormHelperText>
							</FormControl>
						</div> */

/* <div className='test_button'>
											Testing Only:
											<br />
											<button
												className='form_submit_button'
												type='button'
												onClick={() =>
													sendSMS(
														'7011467321',
														'Your party venue has been booked'
													)
												}>
												Dispatch SMS
											</button>
											<button
												className='form_submit_button'
												type='button'
												onClick={() =>
													dispatch(
														dispatchSMS(
															'7011467321',
															'Your party venue has been booked'
														)
													)
												}>
												Send SMS
											</button>
											<hr />
											<button
												className='form_submit_button'
												type='button'
												onClick={() => {
													const order = {
														order_id: form_props.values.booking_id,
													};
													return getPayLink(order);
												}}>
												Get Payment Link
											</button>
											<button
												className='form_submit_button'
												type='button'
												onClick={
													() => checkPayStatus(form_props.values.booking_id)
													// checkPayStatus()
												}>
												Get Payment Status
											</button>
											<button
												className='form_submit_button'
												type='button'
												onClick={() =>
													sendPayLinkMail(form_props.values.booking_id)
												}>
												Send Reminder with Link
											</button>
										</div> */
