import axios from 'axios';

export const base_url = 'https://www.thepepspot.com/wp-json';

export const endpoint = {
	properties: '/wc/v3/products',
	customers: '/wc/v3/customers',
	blogs: '/wp/v2/posts?_embed&_fields=id,date,title,link,_links',
};

export const [property, customers, blogs] = [
	// "properties",
	// "customers",
	'blogs',
];

export const request_url = (path) => {
	return base_url + endpoint[path];
};

const api_keys = {
	username: 'ck_8bdadff7081454f92a792562d63ed60d0c939c84',
	password: 'cs_46ff331fedad6b6cb4b826acb4db1b8e5c1bb4e6',
};

export const api_instance = axios.create({
	baseURL: base_url,
	auth: { username: api_keys.username, password: api_keys.password },
});

export const makeGetRequest = async (path) => {
	api_instance({
		url: request_url(path),
		method: 'get',
		auth: { username: api_keys.username, password: api_keys.password },
		params: { context: 'edit', per_page: 100 },
		//params: params
	})
		.then(async (response) => {
			return response.data;
		})
		.catch((err) => console.log(err));
};
