import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import DateRangePicker from '../forms/DateRangePicker';
import MUITextField from '../forms/MUITextField';
import Img from '../common/Img';

import { costCalculator } from '../../pages/admins/ManageBookingForm';
import iconResolver from '../venue/IconResolver';
import { gotNewBooking } from '../../store/reducers/reducer_user';

// import ScreenDebugger from '../error_handling/ScreenDebugger';
// import ErrorBoundary from '../error_handling/ErrorBoundary';

// import TextFieldMUI from '../forms/TextFieldMUI';
// import CostBreakdown from '../common/CostBreakdown';

/*
<CostBreakdown
	basic_cost={form_props.values.cost_breakdown.base_cost}
	discounted_amount={
		form_props.values.cost_breakdown.discount_total
	}
	tax_rate={form_props.values.cost_breakdown.tax_rate}
	taxes={form_props.values.cost_breakdown.total_tax}
	total_cost={form_props.values.cost_breakdown.total_cost}
/>
*/

const BookingForm = (props) => {
	//#region form constants
	const dispatch = useDispatch();
	const cart = useSelector((state) => state.user.cart);

	let max_guests = props.venue.max_guests ? props.venue.max_guests : 50;

	const validation_rules = Yup.object().shape({
		guest_count: Yup.number()
			.min(1, "It's not a party without you (min: 1 guest) ")
			.max(max_guests, `Woah! that's too many guests! (max: ${max_guests})`)
			.required('Required'),
		check_in: Yup.string().required('Required'),
		check_out: Yup.string().required('Required'),
	});

	let initial_values = {
		check_in: '',
		check_out: '',
		guest_count: '',

		cost_breakdown: {
			days: 0,
			weekends: 0,
			specials: 0,
			extra_guests: 0,
			base_cost: 0,
			coupon: '',
			discount_total: '',
			tax_rate: '',
			total_tax: '',
			total_cost: '',
		},
	};

	// const labels = { guests: 'Guests', date: 'date' };
	// const placeholders = {
	// 	guests: 'All Your Friends',
	// 	date: 'dd/mm/yy',
	// };
	// const types = { guests: 'text', date: 'date' };
	//#endregion

	const handleSubmit = (values) => {
		// Verify Data

		//Get Payment Link
		// Push Data to Checkout Page
		// Navigate to Checkout Page
		// console.log('[BookingForm > handleSubmit] > Form values', values);
		let booking_data = {
			// values = {
			// 	check_in: '',
			// 	check_out: '',
			// 	guest_count: '',
			// 	cost_breakdown: {
			// 		days: 0,
			// 		weekends: 0,
			// 		specials: 0,
			// 		extra_guests: 0,
			// 		base_cost: 0,
			// 		coupon: '',
			// 		discount_total: '',
			// 		tax_rate: '',
			// 		total_tax: '',
			// 		total_cost: '',
			// 	},
			// }
			...values,
			check_in: moment(values.check_in).format(),
			check_out: moment(values.check_out).format(),

			booking_id: '',
			venue_requiresAdminApproval: props.venue.requiresAdminApproval,

			// property: props.venue,
			venue_docref: props.venue.venueDocRef,
			venue_selected: {
				venue_id: props.venue.id,
				venue_pic: props.venue.cover_pic.pic_src,
				venue_name:
					props.venue.property_name + ' ' + props.venue.property_code_number,
				venue_location:
					props.venue.address.area + ', ' + props.venue.address.city,
				// venue_bookings: props.venue.bookings,
				venue_pricing_onBooking: props.venue.pricing,
			},
		};
		dispatch(gotNewBooking({ book: booking_data }));
		// console.log('[BookingForm > handleSubmit] > Booking Data', booking_data);
		// navigate('/cart/', { state: booking_data, replace: true });
		// navigate('/cart/', { state={values}, replace=false })
		alert('Party Venue Added to Cart!');
	};

	return (
		<div className='user_booking_form_container'>
			{props.venue !== null && (
				<Formik
					initialValues={initial_values}
					onSubmit={(values, { setSubmitting }) => {
						// await new Promise((resolve) => setTimeout(resolve, 500));
						setTimeout(() => {
							// alert(JSON.stringify(values, null, 2));
							handleSubmit(values);
							setSubmitting(false);
						}, 400);
					}}
					validationSchema={validation_rules}>
					{(form_props) => (
						<Form className='booking_form'>
							<section className='booking_details'>
								<p className='venue_booking_helper'>
									Add Guest Count & Booking Dates
								</p>
								<div className='form_field_container'>
									<MUITextField
										label='Number of Guests'
										name='guest_count'
										type='number'
										placeholder='5'
									/>
								</div>
								<div className='form_field_container'>
									<Field
										component={DateRangePicker}
										bookings={props.bookings}
										name='DatePickerWithFormik'
										isSmall={false}
										disabled={false}
										required={true}
										calIcon={false}
									/>
								</div>

								{form_props.values.check_in === '' ||
								form_props.values.check_out === '' ||
								form_props.values.guest_count === '' ? (
									<>
										{/* <h4 className='venue_booking_helper'>
											Select Check In & Check Out Dates first
										</h4> */}
										<button
											className='form_submit_button'
											type='button'
											disabled={
												form_props.values.check_in === '' ||
												form_props.values.check_out === '' ||
												form_props.values.guest_count === ''
											}
											onClick={() =>
												costCalculator(
													form_props.values.check_in,
													form_props.values.check_out,
													form_props.values.guest_count,
													props.venue.pricing,
													null,
													form_props.setFieldValue
												)
											}>
											Check Price
										</button>
									</>
								) : (
									<>
										<button
											className='form_submit_button'
											type='button'
											disabled={
												form_props.values.check_in === null ||
												form_props.values.check_out === null
											}
											onClick={() =>
												costCalculator(
													form_props.values.check_in,
													form_props.values.check_out,
													form_props.values.guest_count,
													props.venue.pricing,
													null,
													form_props.setFieldValue
												)
											}>
											Check Price
										</button>

										{form_props.values.cost_breakdown.total_cost !== null &&
										form_props.values.cost_breakdown.total_cost !== '' &&
										form_props.values.cost_breakdown.total_cost !== 0 &&
										form_props.values.cost_breakdown.base_cost !== 0 ? (
											<>
												<div className='prop_cost_break'>
													<p className='booking_form_cost_total'>
														Total Cost:{' '}
														<span className='brek_val'>
															₹{form_props.values.cost_breakdown.total_cost}
														</span>{' '}
													</p>
													<p className='booking_form_cost_taxrate'>
														(including{' '}
														<span className='brek_val'>
															{form_props.values.cost_breakdown.tax_rate * 100}%
														</span>{' '}
														taxes)
													</p>
												</div>
												<button
													className='find_venue_button form_submit_button'
													type='submit'
													disabled={form_props.isSubmitting}>
													Add to Cart
												</button>
												{cart.length > 0 &&
													cart.guest_count !== '' &&
													cart.guest_count !== null && (
														<section className='venue_form_cart_helper'>
															<h3 className='venue_form_cart_size'>
																Items in cart: {cart.length}
															</h3>
															<div className='find_venue_button form_submit_button'>
																<Link className='' to='/cart/'>
																	Go to Cart ➤
																</Link>
															</div>
														</section>
													)}
											</>
										) : null}
									</>
								)}
								{form_props.values.cost_breakdown.total_cost !== null &&
								form_props.values.cost_breakdown.total_cost !== '' &&
								form_props.values.cost_breakdown.total_cost !== 0 &&
								form_props.values.cost_breakdown.base_cost !== 0 ? null : (
									<>
										<Img
											src={iconResolver('max guests')}
											alt={'Party On!'}
											className='form_sections_placeholder'
											width='320px'
											height='100%'
										/>
									</>
								)}

								{/* <ScreenDebugger
									source='[PropertyDetails > BookingForm] > Values'
									values={form_props.values}
									isOpen={false}
								/>
								<ScreenDebugger
									source='[PropertyDetails > BookingForm] > Errors'
									values={form_props.errors}
									isOpen={false}
								/> */}
							</section>
						</Form>
					)}
				</Formik>
			)}
		</div>
	);
};

BookingForm.propTypes = {};

export default BookingForm;
