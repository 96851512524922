import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import GridSuspenseImage from '../common/GridSuspenseImage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faMapMarkerAlt,
	// faRupeeSign
} from '@fortawesome/free-solid-svg-icons';
import { venNameToVID } from '../../commons/utils';

const loc_icon_style = {
	width: '1em',
	height: '1em',
	margin: '0 4px 0 0',
};

const PropertyCard = (props) => {
	const { index, venue_active, venue_select, page, current } = props;
	const {
		pics,
		property_name,
		property_code_number,
		address,
		pricing,
		property_type,
	} = venue_active;
	const [activeStyle, setActiveStyle] = useState('property_card');
	const activeRef = useRef(null);

	useEffect(() => {
		window.scrollTo(window.scrollX, window.scrollY);
	}, []);

	useEffect(() => {
		if (index === current) {
			setActiveStyle('property_card property_card_active');
			if (page !== 'Discover')
				activeRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'center',
				});
		} else setActiveStyle('property_card');
	}, [current, index, page]);

	if (page === 'Discover') {
		let venue_link = venNameToVID(venue_active);
		return (
			<Link to={`/venue/${venue_link}`}>
				<div className='property_card property_card_discover'>
					<GridSuspenseImage src={pics[0].pic_src} />
					<div className='property_overlay_discover'>
						<h2 className='venue_card_name'>
							{property_name + ' ' + property_code_number}
						</h2>
						<p className='venue_card_loc'>
							<FontAwesomeIcon
								icon={faMapMarkerAlt}
								aria-label={'property_location_icon'}
								className='discover_property_location_icon'
								style={loc_icon_style}
							/>
							{address.area + ', ' + address.city}
						</p>
						<p className='venue_card_price'>
							<b className='venue_card_type'>{property_type}</b> from{' '}
							<span className='property_overlay_price_val'>
								₹ {pricing.base}
							</span>
							/night
						</p>
					</div>
				</div>
			</Link>
		);
	} else {
		return (
			<div
				ref={activeRef}
				onClick={() => venue_select(venue_active)}
				className={activeStyle}>
				<GridSuspenseImage src={pics[0].pic_src} />
				<div className='property_overlay_discover'>
					<h2 className='venue_card_name'>
						{property_name + ' ' + property_code_number}
					</h2>
					<p className='venue_card_loc'>
						<FontAwesomeIcon
							icon={faMapMarkerAlt}
							aria-label={'property_location_icon'}
							className='discover_property_location_icon'
							style={loc_icon_style}
						/>
						{address.area + ', ' + address.city}
					</p>
					<p className='venue_card_price'>
						<b className='property_overlay_price_val'>{property_type}</b> from{' '}
						<span className='property_overlay_price_val'>₹ {pricing.base}</span>
						/night
					</p>
				</div>
			</div>
		);
	}
};

const PropertyCardList = (props) => {
	const { properties, selectVenue, page, current } = props;
	const listClass =
		page === 'Discover'
			? 'property_card_container_discover'
			: 'property_card_container';

	return (
		<ul className={listClass}>
			{properties.map((venue, index) => (
				<PropertyCard
					current={current}
					page={page}
					key={index}
					index={index}
					venue_active={venue}
					venue_select={selectVenue}
				/>
			))}
		</ul>
	);
};

PropertyCardList.propTypes = {
	properties: PropTypes.array.isRequired,
	setActive: PropTypes.func,
};

PropertyCard.propTypes = {
	property: PropTypes.object,
};

PropertyCard.defaultProps = {
	property: {
		images: [{ src: '' }],
	},
};

export default PropertyCardList;
