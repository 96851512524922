import React, { useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker as DRP } from 'react-dates';
// import { useField } from "formik";
// import { isSameDay } from './utils'
import '../../xstyles/react_dates_overrides.css';

const DateRangePicker = (props) => {
	// const [field, meta] = useField(props);
	// const { sDate, eDate, setSDate, setEDate } = props;
	const {
		form: {
			setFieldValue,
			// setFieldTouched,
			values,
		},
		isSmall = false,
		bookings = [],
	} = props;
	const [focusInput, setFocusInput] = useState(null);

	// const bookings = [
	// 	{ check_in: '2020-04-26', check_out: '2020-04-27' },
	// 	{ check_in: '2020-04-29', check_out: '2020-04-30' },
	// 	{ check_in: '2020-05-11', check_out: '2020-05-12' },
	// 	{ check_in: '2020-05-06', check_out: '2020-05-07' },
	// 	{ check_in: '2020-05-15', check_out: '2020-05-17' },
	// 	{ check_in: '2020-06-04', check_out: '2020-06-07' },
	// ];

	let booked_dates = bookings.map((item) => {
		return {
			check_in: moment(item.check_in),
			check_out: moment(item.check_out),
		};
	});

	// useEffect(() => {
	//   console.log("booked dates are: ", booked_dates);
	// }, [booked_dates]);

	const isBlockedBetween = (day) => {
		let isBlocked = booked_dates.some((bookeddate) => {
			// console.log('gotfocus:', focusInput);
			if (focusInput === 'startDate') {
				//startDate
				return moment(day).isBetween(
					bookeddate.check_in,
					bookeddate.check_out,
					'day', //If you want to limit the granularity to a unit other than milliseconds, pass the units as the third parameter. Year, month, week, isoWeek, day, hour, minute, and second. null defaults to miliseconds
					'[)' //inclusivity. A [ indicates inclusion of a value. A ( indicates exclusion. If the inclusivity parameter is used, both indicators must be passed.
				);
			} else {
				//endDate
				return moment(day).isBetween(
					bookeddate.check_in,
					bookeddate.check_out,
					'day', //If you want to limit the granularity to a unit other than milliseconds, pass the units as the third parameter. Year, month, week, isoWeek, day, hour, minute, and second. null defaults to miliseconds
					'(]' //inclusivity. A [ indicates inclusion of a value. A ( indicates exclusion. If the inclusivity parameter is used, both indicators must be passed.
				);
			}
		});

		// console.log("Is ", day, "blocked?: ", isBlocked);
		return isBlocked;
	};

	//Function to update calendar week days label
	const handleWeekDays = (day) => {
		//Change week day with custom day array
		day._locale._weekdaysMin = ['S', 'M', 'T', 'W', 'Th', 'F', 'Sa'];
		// return the actual dates value(like 1,2,3 ...) from the moment object.
		return day.format('D');
	};

	// console.log(">> test date is: ", new Date("2020-04-27"));
	return (
		<div>
			<DRP
				small={isSmall}
				displayFormat='ddd, MMM Do'
				//
				// showClearDates
				reopenPickerOnClearDates
				//
				showDefaultInputIcon={props.calIcon}
				inputIconPosition='after'
				customArrowIcon={<>&gt;</>}
				//
				numberOfMonths={1}
				minimumNights={1}
				enableOutsideDays
				//
				disabled={props.disabled}
				startDateId='check_in'
				endDateId='check_out'
				startDatePlaceholderText='Check In'
				endDatePlaceholderText='Check Out'
				startDate={values.check_in === '' ? null : values.check_in} // momentPropTypes.momentObj or null,
				endDate={values.check_out === '' ? null : values.check_out} // momentPropTypes.momentObj or null,
				focusedInput={focusInput}
				//
				isDayBlocked={(day) => isBlockedBetween(day)}
				onFocusChange={(focusedInput) => setFocusInput(focusedInput)}
				//
				renderDayContents={handleWeekDays}
				onDatesChange={({ startDate, endDate }) => {
					setFieldValue('check_in', startDate);
					setFieldValue('check_out', endDate);
					// if (startDate && endDate) {
					// 	console.log(
					// 		'[DRPF] > New Dates are: ',
					// 		startDate.format('Do MMM, YYYY'),
					// 		' - ',
					// 		endDate.format('Do MMM, YYYY')
					// 	);
					// }
				}}
			/>
		</div>
	);
};

DateRangePicker.defaultProps = {
	sDateID: 'check_in',
	eDateID: 'check_out',
};

export default DateRangePicker;
