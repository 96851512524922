import moment from 'moment';
import {
	bookingsCollectionRef,
	mailTriggerColRef,
	usersCollectionRef,
	// mailTriggerColRef,
	// venuesCollectionRef,
	// usersCollectionRef,
} from '../../services/fb';
import axios from 'axios';
import {
	ad_bookingsFetching,
	ad_bookingsFetched,
	ad_bookingsFetchFailed,
	ad_users_fetching,
	ad_users_fetched,
	ad_users_fetchFailed,
} from '../reducers/reducer_admin';
import { generatePaymentLink, writeBookingDatatoDB } from '../../services/back';

const date_format = 'ddd, Do MMM, YYYY';

// const URL_BASE = 'http://localhost:5001/pepspot-webapp-2020-b1/us-central1/app';
const URL_BASE =
	'https://us-central1-pepspot-webapp-2020-b1.cloudfunctions.net/app';
// 'http://localhost:5001/pepspot-webapp-2020-b1/us-central1/app/api/sms/booked'
const URL_SMS_SEND = URL_BASE + '/api/sms/sendSMS/booked';

export const fetchBookings = () => {
	let bookings = [];

	return (dispatch) => {
		dispatch(ad_bookingsFetching);

		bookingsCollectionRef
			.get()
			.then((query_snap) => {
				query_snap.forEach((doc) => {
					const incoming_booking = doc.data();
					bookings.push(incoming_booking);
				});
				dispatch(
					ad_bookingsFetched({
						bookings: bookings,
					})
				);
			})
			.catch((err) => {
				let simple_error = {
					code: err.code,
					msg: err.message,
				};
				console.log(
					'[actions_admin > getBookings] got Error > L1: ',
					JSON.stringify(err)
				);
				console.log(
					'[actions_admin > getBookings] got Error > L2: ',
					simple_error
				);
				dispatch(ad_bookingsFetchFailed(simple_error));
			});
	};
};

export const fetchUsers = () => {
	return (dispatch) => {
		let users_list = [];
		dispatch(ad_users_fetching());
		usersCollectionRef
			// .where('c_uid', '==', user.uid)
			.where('access', '==', 'regsitered')
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					// doc.data() is never undefined for query doc snapshots
					let user = doc.data();
					// console.log(doc.id, ' => ', user, JSON.stringify(user));
					users_list.push({ ...user, docRef: doc.id });
				});

				// console.log(
				// 	'[fetchUsers]: users_list:',
				// 	users_list
				// 	// JSON.stringify(users_list)
				// );
				dispatch(ad_users_fetched(users_list));
			})
			.catch((err) => {
				let simple_error = {
					code: err.code,
					msg: err.message,
				};
				dispatch(ad_users_fetchFailed(simple_error));
				console.log('Error getting documents: ', JSON.stringify(simple_error));
			});
	};
};

export const getSMSMsgForBooking = (status, booking) => {
	switch (status) {
		case 'booked': {
			return `Hey ${booking.billing.customer_name}, Your party venue ${
				booking.property_name
			} has been booked from ${moment(booking.check_in).format(
				date_format
			)}-${moment(booking.check_out).format(date_format)}.`;
		}

		default: {
			return `Hey ${booking.billing.customer_name}, Your party venue ${
				booking.property_name
			} has been booked from ${moment(booking.check_in).format(
				date_format
			)}-${moment(booking.check_out).format(date_format)}.`;
		}
	}
};

const getEmailMessageForBooking = (status, booking) => {
	switch (status) {
		case 'booked': {
			return `Hey ${booking.billing.customer_name}, <br /> Your party venue ${
				booking.property_name
			} has been booked from ${moment(booking.check_in).format(
				date_format
			)}-${moment(booking.check_out).format(
				date_format
			)}. Hope you have a fun time partying with us! Take note of the house rules and feel free to contact us at anytime during your stay with us`;
		}
		default: {
			return `Hey ${booking.billing.customer_name}, <br /> Your party venue ${
				booking.property_name
			} has been booked from ${moment(booking.check_in).format(
				'ddd, Do MMM, YYYY'
			)}-${moment(booking.check_out).format(
				'ddd, Do MMM, YYYY'
			)}. Hope you have a fun time partying with us! Take note of the house rules and feel free to contact us at anytime during your stay with us`;
		}
	}
};

export const sendMail = (to, sub, val) => {
	mailTriggerColRef
		.add({
			to: to,
			message: {
				subject: sub,
				html: getEmailMessageForBooking(val.status, val),
			},
		})
		.then((res) => {
			console.log('[Actions_Admin] > Mail Triggered! > with data: ', res);
		})
		.catch((error) => {
			console.log(
				'[Actions_Admin > Error] > Error Obj is: ',
				JSON.stringify(error)
			);
		});
};

// export const fetchSelectedUser = (uid) => {
// 	return (dispatch) => {
// 		dispatch()
// 		usersCollectionRef
// 	}
// }

//#region Old Api Attempts
// https://api.cashfree.com/api/v1/order/create
// https://api.cashfree.com/api/v1/order/info/status
// https://api.cashfree.com/api/v1/order/email
// https://api.cashfree.com/api/v1/order/refund
// export const base_url = 'https://api.cashfree.com/api/v1/order';

// export const endpoint = {
// 	create: '/create',
// 	status: '/info/status',
// 	email: '/email',
// 	refund: '/refund',
// };

// export const [orderCreate, orderStatus, orderEmail, orderRefund] = [
// 	'create',
// 	'status',
// 	'email',
// 	'refund',
// ];

// export const request_url = (path) => {
// 	return base_url + endpoint[path];
// };

// export const api_instance = axios.create({
// 	baseURL: base_url,
// 	// auth: { username: api_keys.username, password: api_keys.password },
// });

// export const makePayPostRequest = async (path, body) => {
// 	console.log('[makePayPostRequest] > Request Body is:', body);

// 	const req = api_instance({
// 		url: request_url(path),
// 		method: 'POST',
// 		// headers: {
// 		// 	'cache-control': 'no-cache',
// 		// 	'content-type': 'application/x-www-form-urlencoded',
// 		// },
// 		crossDomain: true,
// 		data: body,
// 		redirect: 'follow',
// 	})
// 		.then(async (response) => {
// 			console.log('[makePayPostRequest] > Got Response: ', response.data);
// 			return response.data;
// 		})
// 		.catch((err) => {
// 			console.error('[makePayPostRequest] > Got Error', err);
// 			return err;
// 		});

// 	console.log('[makePayPostRequest] > Request is:', req);
// };

// const makePayPostFetchRequest = async (path, body) => {
// 	let res = await fetch('https://api.cashfree.com/api/v1/order/create', {
// 		method: 'POST',
// 		body: body,
// 	});
// 	console.log('Fetch Request completed as: ', res);

// 	return res.json;
// };
//#endregion

//#region old Paylinkgetter
// let returnUrl = 'https://app.pepspot.com/payment_status';

// let formdata = new FormData();
// formdata.append('appId', _pid);
// formdata.append('secretKey', _pkey);
// formdata.append('orderId', transact_id);
// formdata.append('orderAmount', amount);
// formdata.append('orderCurrency', currency);
// formdata.append('orderNote', order_note);
// formdata.append('customerName', customerName);
// formdata.append('customerEmail', customerEmail);
// formdata.append('customerPhone', customerPhone);
// formdata.append('returnUrl', returnUrl);
// // formdata.append('notifyUrl', 'http://localhost/handlePaymentStatus.php');

// let res = await makePayPostRequest(orderCreate, formdata);
// console.log('Payment Creation Status: ', res);
//#endregion

//#region Older Pg Interactions
// const getPayLink = async () => {};

// let body = {
// 	appId: `${_pid}`,
// 	secretKey: `${_pkey}`,
// 	orderId: order_id,
// 	orderAmount: 10,
// 	orderCurrency: 'INR',
// 	orderNote: 'Test order',
// 	customerEmail: 'devpepspot@gmail.com',
// 	customerName: 'dev_pep',
// 	customerPhone: '7011467321',
// 	returnUrl: 'http://localhost/handleResponse.php',
// 	notifyUrl: 'http://localhost/handlePaymentStatus.php',
// };

// let res_2 = await makePayPostFetchRequest(orderCreate, body);
// console.log('Payment Creation Status: ', res_2);
// {status, paymentLink, reason} = res

// export const checkPayStatus = async (order_id = 'test_may27_01') => {
// 	// let body = {
// 	// 	appId: _pid,
// 	// 	secretKey: _pkey,
// 	// 	orderId: order_id,
// 	// };
// 	// let req = '';
// 	// for (const key in body) {
// 	// 	// console.log(`${key}: ${body[key]}`);
// 	// 	req = req + `${key}=${body[key]}&`;
// 	// }
// 	// console.log('Final string: ', req);
// 	// let res = await makePayPostRequest(orderStatus, req);

// 	let body = new FormData();
// 	body.append('appId', _pid);
// 	body.append('secretKey', _pkey);
// 	body.append('orderId', order_id);

// 	let res = await makePayPostRequest(orderStatus, body);

// 	console.log('Payment Status: ', res);
// 	// {status, paymentLink, reason, txStatus, txTime, paymentDetails} = res
// 	return res;
// };

// export const sendPayLinkMail = async (order_id = 'test_may27_01') => {
// 	// let body = {
// 	// 	appId: _pid,
// 	// 	secretKey: _pkey,
// 	// 	orderId: order_id,
// 	// };
// 	let body = new FormData();
// 	body.append('appId', _pid);
// 	body.append('secretKey', _pkey);
// 	body.append('orderId', order_id);

// 	let res = await makePayPostRequest(orderEmail, body);
// 	console.log('Payment Notif Status: ', res);
// 	// {status, message, reason} = res
// 	return res;
// };
//#endregion

export const sendSms = (num, msg) => {
	let data = JSON.stringify({ dest_number: num, msg: msg });

	let config = {
		method: 'post',
		url: URL_SMS_SEND,
		headers: {
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			console.log(JSON.stringify(response.data));
		})
		.catch(function (error) {
			console.log(error);
		});
};

// export const startRefund = () => {
// 	let body = {
// 		appId: _pid,
// 		secretKey: _pkey,
// 		referenceId: 28759,
// 		refundAmount: 1,
// 		refundNote: 'partial refund',
// 	};
// };

export const handleOrder_admin = (cb) => {
	//cb = checked_booking => Booking approved and checked by the user.
	// handles the appropriate functions calls and dispatches to redux store
	return (dispatch) => {
		generatePaymentLink(cb)
			.then((res) => {
				console.log('4.[handleOrder_user > dispatch] Got Data with Link:', res);
				dispatch(writeBookingDatatoDB(res));
			})
			.catch((e) => {
				console.error(
					'4.e. [handleOrder_user > dispatch] Got Error:',
					e.message
				);
			});
	};
};
