import { createReducer, createAction } from '@reduxjs/toolkit';

export const gettingTest = createAction('GETTING_TEST');
export const gettingVenues = createAction('Getting venues');
export const gotVenues = createAction('Got venues');
export const gotErrorGettingVenues = createAction('Got Error getting venues');
export const addingNewVenue = createAction('Adding a new Venue');
export const addedNewVenue = createAction('Added a new Venue');
export const gotErrorAddingNewVenue = createAction(
	'Got Error adding new Venue'
);
export const gettingProperties = createAction('FETCHING_PROPERTY_LIST');
export const gotProperties = createAction('FETCHED_PROPERTY_LIST');
export const initProperties = createAction('INITIALIZE_PROPERTIES');
export const errorGettingProperties = createAction('ERROR_GETTING_PROPERTIES');

export const delistVenue = createAction('Removing Venue from active list');

export const initPropertyState = {
	fetchingPropertyList: false,
	property_list: [
		{
			id: '',
			name: '',
			description: '',
			short_description: '',
			images: [],
			price: 0,
			ammenities: [{ title: '', isAvailable: '' }],
			location: { address: '', lat: '', long: '', locality: '' },
		},
	],
	// properties_list: [
	// 	{
	// 		venueDocRef: 'delhi_01',
	// 		id: 'delhi_01',
	// 		property_type: 'villa',
	// 		number_bedrooms: 4,
	// 		property_name: 'PepUrban',
	// 		property_code_number: '101',
	// 		owner: 'Shri. Narayan Singh',
	// 		poc_name: 'Mr. Himmat Singh',
	// 		poc_contact_num: '+91 7011467321',
	// 		pics: [
	// 			{
	// 				pic_src:
	// 					'https://images.unsplash.com/photo-1551524164-687a55dd1126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
	// 				pic_label: 'Incoming View',
	// 			},
	// 		],
	// 		cover_pic: {
	// 			pic_src:
	// 				'https://images.unsplash.com/photo-1568314735654-32fb53a5c886?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=100',
	// 			pic_label: 'Front Porch',
	// 		},
	// 		address: {
	// 			id: '01',
	// 			flat_num: '125',
	// 			locality: '23rd Street',
	// 			area: 'Sector 510, Dwarka',
	// 			landmark: 'Metro Pillar 11232',
	// 			region: 'South West Delhi',
	// 			pincode: '110079',
	// 			city: 'New Delhi',
	// 			state: 'Delhi NCR',
	// 			nearest_metro: 'Dwarka Mor',
	// 			longitudes: '77.2173665',
	// 			latitudes: '28.6304656',
	// 			gmaps_link:
	// 				'https://www.google.co.in/maps/place/Rajiv+Chowk/@28.6304656,77.2173665,16.83z/data=!4m5!3m4!1s0x390cfd37ebbdf4af:0x2be7eefccedf4b84!8m2!3d28.6329858!4d77.2193746',
	// 		},
	// 		requiresAdminApproval: true,
	// 		description:
	// 			'A sunfacing property known for its amazing sunrise view over the surrounding parks and newly revived lake.',
	// 		cancellation_policy: 'NaN',
	// 		amenities: [
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				value: true,
	// 				isChargeable: false,
	// 				charges: '',
	// 			},
	// 			{
	// 				id: '03',
	// 				label: 'Music System',
	// 				value: true,
	// 				isChargeable: true,
	// 				charges: '100',
	// 			},
	// 			{
	// 				id: '03',
	// 				label: 'Self Help Kitchen',
	// 				value: true,
	// 				isChargeable: false,
	// 				charges: '',
	// 			},
	// 		],
	// 		house_rules: [
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				value: 'Before 10 pm',
	// 			},
	// 		],
	// 		dates_booked: {
	// 			// keeps booking data of the upcoming 6 months //redux only
	// 			'01': {},
	// 			'02': {},
	// 			'03': {},
	// 		},
	// 		booking_history: {
	// 			//redux only
	// 			'2020_jan': {},
	// 		},
	// 		bookings: [],
	// 		pricing: {
	// 			base: '11000',
	// 			weekend: '14000',
	// 			guest_based: [
	// 				{
	// 					guest_limit_lower: 15,
	// 					cost_per_guest: '15000',
	// 				},
	// 			],
	// 			date_range: [],
	// 			misc: [
	// 				{
	// 					charge_name: 'Extra Mattresses (Per Person)',
	// 					charge_value: '250',
	// 				},
	// 			],
	// 		},
	// 	},
	// 	{
	// 		id: 'gurugram_01',
	// 		property_type: 'villa',
	// 		number_bedrooms: 4,
	// 		property_name: 'PEPCASA BROOKWOOD',
	// 		property_code_number: '103',
	// 		owner: 'Mr. Narayan Singh',
	// 		poc_name: 'Mr. Himmat Singh',
	// 		poc_contact_num: '+91 7011467321',
	// 		pics: [
	// 			{
	// 				pic_src:
	// 					'https://images.unsplash.com/photo-1568314735654-32fb53a5c886?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
	// 				pic_label: 'Incoming View',
	// 			},
	// 		],
	// 		cover_pic: {
	// 			pic_src:
	// 				'https://images.unsplash.com/photo-1551524164-687a55dd1126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
	// 			pic_label: 'Front Porch',
	// 		},
	// 		address: {
	// 			id: '01',
	// 			flat_num: '127',
	// 			locality: '15th Street',
	// 			area: 'Sector 29',
	// 			landmark: 'Rapid Metro Pillar 112',
	// 			region: 'South West Delhi',
	// 			pincode: '110079',
	// 			city: 'Gurugram',
	// 			state: 'Haryana',
	// 			nearest_metro: 'Dwarka Mor',
	// 			longitudes: '',
	// 			latitudes: '',
	// 			gmaps_link: '',
	// 		},
	// 		requiresAdminApproval: false,
	// 		description:
	// 			'A sunfacing property known for its amazing sunrise view over the surrounding parks and newly revived lake.',
	// 		cancellation_policy: 'NaN (Link)',
	// 		amenities: [
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				value: true,
	// 				isChargeable: false,
	// 				charges: '',
	// 			},
	// 		],
	// 		house_rules: [
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				value: 'Before 10 pm',
	// 			},
	// 		],
	// 		dates_booked: {
	// 			// keeps booking data of the upcoming 6 months //redux only
	// 			'01': {},
	// 			'02': {},
	// 			'03': {},
	// 		},
	// 		booking_history: {
	// 			//redux only
	// 			'2020_jan': {},
	// 		},
	// 		bookings: [],
	// 		pricing: {
	// 			base: '13000',
	// 			weekend: '14000',
	// 			guest_based: [
	// 				{
	// 					guest_limit_lower: 15,
	// 					cost_per_guest: '15000',
	// 				},
	// 			],
	// 			date_range: [],
	// 			misc: [],
	// 		},
	// 	},
	// 	{
	// 		id: 'delhi_02',
	// 		property_type: 'villa',
	// 		number_bedrooms: 4,
	// 		property_name: 'PepUrban',
	// 		property_code_number: '101',
	// 		owner: 'Mr. Narayan Singh',
	// 		poc_name: 'Mr Himmat Singh',
	// 		poc_contact_num: '+91 xxxx xxx xxx',
	// 		pics: [
	// 			{
	// 				pic_src:
	// 					'https://images.unsplash.com/photo-1551524164-687a55dd1126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
	// 				pic_label: 'Incoming View',
	// 			},
	// 		],
	// 		cover_pic: {
	// 			pic_src:
	// 				'https://images.unsplash.com/photo-1568314735654-32fb53a5c886?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=100',
	// 			pic_label: 'Front Porch',
	// 		},
	// 		address: {
	// 			id: '01',
	// 			flat_num: '125',
	// 			locality: '23rd Street',
	// 			area: 'Sector 510, Dwarka',
	// 			landmark: 'Metro Pillar 11232',
	// 			region: 'South West Delhi',
	// 			pincode: '110079',
	// 			city: 'New Delhi',
	// 			state: 'Delhi NCR',
	// 			nearest_metro: 'Dwarka Mor',
	// 			longitudes: null,
	// 			latitudes: null,
	// 			gmaps_link: null,
	// 		},
	// 		requiresAdminApproval: true,
	// 		description:
	// 			'A sunfacing property known for its amazing sunrise view over the surrounding parks and newly revived lake.',
	// 		cancellation_policy: 'NaN (Link)',
	// 		amenities: [
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 				isChargeable: false,
	// 				charges: '',
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 		],
	// 		house_rules: [
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				icon: null,
	// 				value: 'Before 10 pm',
	// 			},
	// 		],
	// 		dates_booked: {
	// 			// keeps booking data of the upcoming 6 months //redux only
	// 			'01': {},
	// 			'02': {},
	// 			'03': {},
	// 		},
	// 		booking_history: {
	// 			//redux only
	// 			'2020_jan': {},
	// 		},
	// 		bookings: [],
	// 		pricing: {
	// 			base: '11000',
	// 			weekend: '14000',
	// 			guest_based: [
	// 				{
	// 					guest_limit_lower: 15,
	// 					cost_per_guest: '15000',
	// 				},
	// 			],
	// 			date_range: [],
	// 			misc: [
	// 				{
	// 					charge_name: 'Extra Mattresses (Per Person)',
	// 					charge_value: '250',
	// 				},
	// 			],
	// 		},
	// 	},
	// 	{
	// 		id: 'gurugram_02',
	// 		property_type: 'villa',
	// 		number_bedrooms: 4,
	// 		property_name: 'PEPCASA BROOKWOOD',
	// 		property_code_number: '103',
	// 		owner: 'Mr. Narayan Singh',
	// 		poc_name: 'Mr Himmat Singh',
	// 		poc_contact_num: '+91 xxxx xxx xxx',
	// 		pics: [
	// 			{
	// 				pic_src:
	// 					'https://images.unsplash.com/photo-1568314735654-32fb53a5c886?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
	// 				pic_label: 'Incoming View',
	// 			},
	// 		],
	// 		cover_pic: {
	// 			pic_src:
	// 				'https://images.unsplash.com/photo-1551524164-687a55dd1126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
	// 			pic_label: 'Front Porch',
	// 		},
	// 		address: {
	// 			id: '01',
	// 			flat_num: '127',
	// 			locality: '15th Street',
	// 			area: 'Sector 29',
	// 			landmark: 'Rapid Metro Pillar 112',
	// 			region: 'South West Delhi',
	// 			pincode: '110079',
	// 			city: 'Gurugram',
	// 			state: 'Haryana',
	// 			nearest_metro: 'Dwarka Mor',
	// 			longitudes: null,
	// 			latitudes: null,
	// 			gmaps_link: null,
	// 		},
	// 		requiresAdminApproval: false,
	// 		description:
	// 			'A sunfacing property known for its amazing sunrise view over the surrounding parks and newly revived lake.',
	// 		cancellation_policy: 'NaN (Link)',
	// 		amenities: [
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 				isChargeable: false,
	// 				charges: '',
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 		],
	// 		house_rules: [
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				icon: null,
	// 				value: 'Before 10 pm',
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				icon: null,
	// 				value: 'Before 10 pm',
	// 			},
	// 		],
	// 		dates_booked: {
	// 			// keeps booking data of the upcoming 6 months //redux only
	// 			'01': {},
	// 			'02': {},
	// 			'03': {},
	// 		},
	// 		booking_history: {
	// 			//redux only
	// 			'2020_jan': {},
	// 		},
	// 		bookings: [],
	// 		pricing: {
	// 			base: '13000',
	// 			weekend: '14000',
	// 			guest_based: [
	// 				{
	// 					guest_limit_lower: 15,
	// 					cost_per_guest: '15000',
	// 				},
	// 			],
	// 			date_range: [],
	// 			misc: [],
	// 		},
	// 	},
	// 	{
	// 		id: 'noida_04',
	// 		property_type: 'villa',
	// 		number_bedrooms: 4,
	// 		property_name: 'PEPCASA BROOKWOOD',
	// 		property_code_number: '103',
	// 		owner: 'Mr. Narayan Singh',
	// 		poc_name: 'Mr Himmat Singh',
	// 		poc_contact_num: '+91 xxxx xxx xxx',
	// 		pics: [
	// 			{
	// 				pic_src:
	// 					'https://images.unsplash.com/photo-1568314735654-32fb53a5c886?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
	// 				pic_label: 'Incoming View',
	// 			},
	// 		],
	// 		cover_pic: {
	// 			pic_src:
	// 				'https://images.unsplash.com/photo-1551524164-687a55dd1126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
	// 			pic_label: 'Front Porch',
	// 		},
	// 		address: {
	// 			id: '01',
	// 			flat_num: '127',
	// 			locality: '15th Street',
	// 			area: 'Sector 29',
	// 			landmark: 'Rapid Metro Pillar 112',
	// 			region: 'South West Delhi',
	// 			pincode: '110079',
	// 			city: 'Gurugram',
	// 			state: 'Haryana',
	// 			nearest_metro: 'Dwarka Mor',
	// 			longitudes: null,
	// 			latitudes: null,
	// 			gmaps_link: null,
	// 		},
	// 		requiresAdminApproval: false,
	// 		description:
	// 			'A sunfacing property known for its amazing sunrise view over the surrounding parks and newly revived lake.',
	// 		cancellation_policy: 'NaN (Link)',
	// 		amenities: [
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 				isChargeable: false,
	// 				charges: '',
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 		],
	// 		house_rules: [
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				icon: null,
	// 				value: 'Before 10 pm',
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				icon: null,
	// 				value: 'Before 10 pm',
	// 			},
	// 		],
	// 		dates_booked: {
	// 			// keeps booking data of the upcoming 6 months //redux only
	// 			'01': {},
	// 			'02': {},
	// 			'03': {},
	// 		},
	// 		booking_history: {
	// 			//redux only
	// 			'2020_jan': {},
	// 		},
	// 		bookings: [],
	// 		pricing: {
	// 			base: '13000',
	// 			weekend: '14000',
	// 			guest_based: [
	// 				{
	// 					guest_limit_lower: 15,
	// 					cost_per_guest: '15000',
	// 				},
	// 			],
	// 			date_range: [],
	// 			misc: [],
	// 		},
	// 	},
	// 	{
	// 		id: 'delhi_05',
	// 		property_type: 'villa',
	// 		number_bedrooms: 4,
	// 		property_name: 'PepUrban',
	// 		property_code_number: '101',
	// 		owner: 'Mr. Narayan Singh',
	// 		poc_name: 'Mr Himmat Singh',
	// 		poc_contact_num: '+91 xxxx xxx xxx',
	// 		pics: [
	// 			{
	// 				pic_src:
	// 					'https://images.unsplash.com/photo-1551524164-687a55dd1126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=80',
	// 				pic_label: 'Incoming View',
	// 			},
	// 		],
	// 		cover_pic: {
	// 			pic_src:
	// 				'https://images.unsplash.com/photo-1568314735654-32fb53a5c886?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=100',
	// 			pic_label: 'Front Porch',
	// 		},
	// 		address: {
	// 			id: '01',
	// 			flat_num: '125',
	// 			locality: '23rd Street',
	// 			area: 'Sector 510, Dwarka',
	// 			landmark: 'Metro Pillar 11232',
	// 			region: 'South West Delhi',
	// 			pincode: '110079',
	// 			city: 'New Delhi',
	// 			state: 'Delhi NCR',
	// 			nearest_metro: 'Dwarka Mor',
	// 			longitudes: null,
	// 			latitudes: null,
	// 			gmaps_link: null,
	// 		},
	// 		requiresAdminApproval: true,
	// 		description:
	// 			'A sunfacing property known for its amazing sunrise view over the surrounding parks and newly revived lake.',
	// 		cancellation_policy: 'NaN (Link)',
	// 		amenities: [
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 				isChargeable: false,
	// 				charges: '',
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 		],
	// 		house_rules: [
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				icon: null,
	// 				value: 'Before 10 pm',
	// 			},
	// 		],
	// 		dates_booked: {
	// 			// keeps booking data of the upcoming 6 months //redux only
	// 			'01': {},
	// 			'02': {},
	// 			'03': {},
	// 		},
	// 		booking_history: {
	// 			//redux only
	// 			'2020_jan': {},
	// 		},
	// 		bookings: [],
	// 		pricing: {
	// 			base: '11000',
	// 			weekend: '14000',
	// 			guest_based: [
	// 				{
	// 					guest_limit_lower: 15,
	// 					cost_per_guest: '15000',
	// 				},
	// 			],
	// 			date_range: [],
	// 			misc: [],
	// 		},
	// 	},
	// 	{
	// 		id: 'noida_05',
	// 		property_type: 'villa',
	// 		number_bedrooms: 4,
	// 		property_name: 'PEPFARM ANUPAMAA',
	// 		property_code_number: '103',
	// 		owner: 'Mr. Narayan Singh',
	// 		poc_name: 'Mr Himmat Singh',
	// 		poc_contact_num: '+91 xxxx xxx xxx',
	// 		pics: [
	// 			{
	// 				pic_src:
	// 					'https://images.unsplash.com/photo-1568314735654-32fb53a5c886?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
	// 				pic_label: 'Incoming View',
	// 			},
	// 		],
	// 		cover_pic: {
	// 			pic_src:
	// 				'https://images.unsplash.com/photo-1551524164-687a55dd1126?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
	// 			pic_label: 'Front Porch',
	// 		},
	// 		address: {
	// 			id: '01',
	// 			flat_num: '127',
	// 			locality: '15th Street',
	// 			area: 'Sector 29',
	// 			landmark: 'Rapid Metro Pillar 112',
	// 			region: 'South West Delhi',
	// 			pincode: '110079',
	// 			city: 'Gurugram',
	// 			state: 'Haryana',
	// 			nearest_metro: 'Dwarka Mor',
	// 			longitudes: null,
	// 			latitudes: null,
	// 			gmaps_link: null,
	// 		},
	// 		requiresAdminApproval: false,
	// 		description:
	// 			'A sunfacing property known for its amazing sunrise view over the surrounding parks and newly revived lake.',
	// 		cancellation_policy: 'NaN (Link)',
	// 		amenities: [
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 				isChargeable: false,
	// 				charges: '',
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Living Area',
	// 				icon: null,
	// 				value: true,
	// 			},
	// 		],
	// 		house_rules: [
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				icon: null,
	// 				value: 'Before 10 pm',
	// 			},
	// 			{
	// 				id: '01',
	// 				label: 'Music Timings',
	// 				icon: null,
	// 				value: 'Before 10 pm',
	// 			},
	// 		],
	// 		dates_booked: {
	// 			// keeps booking data of the upcoming 6 months //redux only
	// 			'01': {},
	// 			'02': {},
	// 			'03': {},
	// 		},
	// 		booking_history: {
	// 			//redux only
	// 			'2020_jan': {},
	// 		},
	// 		bookings: [],
	// 		pricing: {
	// 			base: '23000',
	// 			weekend: '27000',
	// 			guest_based: [
	// 				{
	// 					guest_limit_lower: 15,
	// 					cost_per_guest: '15000',
	// 				},
	// 			],
	// 			date_range: [],
	// 			misc: [],
	// 		},
	// 	},
	// ],
	venue_list_status: false,
	properties_list: [
		// {
		// 	id: '',
		// 	property_type: '',
		// 	number_bedrooms: '',
		// 	property_name: '',
		// 	property_code_number: '',
		// 	owner: '',
		// 	poc_name: '',
		// 	poc_contact_num: '',
		// 	added_on: '',
		// 	is_published: false,
		// 	published_on: '',
		// 	pics: [
		// 		{
		// 			pic_src: '',
		// 			pic_label: '',
		// 		},
		// 	],
		// 	cover_pic: {
		// 		pic_src: '',
		// 		pic_label: '',
		// 	},
		// 	address: {
		// 		flat_num: '',
		// 		locality: '',
		// 		area: '',
		// 		landmark: '',
		// 		region: '',
		// 		pincode: '',
		// 		city: '',
		// 		state: '',
		// 		nearest_metro: '',
		// 		longitudes: '',
		// 		latitudes: '',
		// 		gmaps_link: '',
		// 	},
		// 	requiresAdminApproval: true,
		// 	description: '',
		// 	cancellation_policy: '',
		// 	amenities: [
		// 		{
		// 			label: '',
		// 			icon: '',
		// 			value: false,
		// 			isChargeable: false,
		// 			charges: '',
		// 		},
		// 	],
		// 	house_rules: [
		// 		{
		// 			label: '',
		// 			icon: '',
		// 			value: '',
		// 		},
		// 	],
		// 	pricing: {
		// 		base: '',
		// 		weekend: '',
		// 		guest_limit_lower: '',
		// 		cost_per_guest: '',
		// 		taxrate: '0.18',
		// 		date_range: [
		// 			{
		// 				start_date: '',
		// 				end_date: '',
		// 				price: '',
		// 				//Affects only the base price. Other prices are scaled according to base price.
		// 				message: '',
		// 			},
		// 		],
		// 		misc: [
		// 			{
		// 				charge_name: '',
		// 				charge_value: '',
		// 			},
		// 		],
		// 	},
		// 	bookings: [
		// 		{
		// 			id: '',
		// 			check_in: '',
		// 			check_out: '',
		// 			booked_on: '',
		// 			booked_by_id: '',
		// 			booked_by_name: '',
		// 			booked_for_days: '',
		// 		},
		// 	],
		// },
	],
	venues_list: [
		{
			id: '',
			property_type: '',
			number_bedrooms: '',
			property_name: '',
			property_code_number: '',
			owner: '',
			poc_name: '',
			poc_contact_num: '',
			added_on: '',
			is_published: false,
			published_on: '',
			pics: [
				{
					pic_src: '',
					pic_label: '',
				},
			],
			cover_pic: {
				pic_src: '',
				pic_label: '',
			},
			address: {
				flat_num: '',
				locality: '',
				area: '',
				landmark: '',
				region: '',
				pincode: '',
				city: '',
				state: '',
				nearest_metro: '',
				longitudes: '',
				latitudes: '',
				gmaps_link: '',
			},
			requiresAdminApproval: true,
			description: '',
			cancellation_policy: '',
			amenities: [
				{
					label: '',
					icon: '',
					value: false,
					isChargeable: false,
					charges: '',
				},
			],
			house_rules: [
				{
					label: '',
					icon: '',
					value: '',
				},
			],
			pricing: {
				base: '',
				weekend: '',
				guest_limit_lower: '',
				cost_per_guest: '',
				taxrate: '0.18',
				date_range: [
					{
						start_date: '',
						end_date: '',
						price: '',
						//Affects only the base price. Other prices are scaled according to base price.
						message: '',
					},
				],
				misc: [
					{
						charge_name: '',
						charge_value: '',
					},
				],
			},
			bookings: [
				{
					id: '',
					check_in: '',
					check_out: '',
					booked_on: '',
					booked_by_id: '',
					booked_by_name: '',
					booked_for_days: '',
				},
			],
		},
	],
	serviced_cities: [],
	property_types: [],
};

function removeItem(array, item) {
	return array.filter((list_item) => list_item.id !== item.id);
}

export const propertyReducer = createReducer(initPropertyState, {
	[gettingProperties]: (state) => {
		state.fetchingPropertyList = true;
	},
	[gotProperties]: (state) => {
		state.fetchingPropertyList = false;
	},
	[initProperties]: (state, action) => {
		state.property_list = action.payload;
	},
	[errorGettingProperties]: () => {
		console.log(
			`[propertyReducer > ${errorGettingProperties.type}] > Got error getting venues`
		);
	},
	[gettingTest]: (state) => {
		state.testing = true;
	},
	[gettingVenues.type]: (state) => {
		// console.log(`[propertyReducer] > Fetching Venues`);
		state.venue_list_status = 'Fetching Venues';
		state.fetchingPropertyList = true;
	},
	[gotVenues.type]: (state, action) => {
		state.venue_list_status = 'Got Venues';
		// console.log(
		// 	'[propertyReducer > ',
		// 	state.venue_list_status,
		// 	'] >',
		// 	action.payload
		// );
		state.venues_list = action.payload.venues;
		state.properties_list = action.payload.properties;
		state.serviced_cities = [
			...new Set(action.payload.properties.map((item) => item.address.city)),
		];
		state.property_types = [
			...new Set(action.payload.properties.map((item) => item.property_type)),
		];
		state.fetchingPropertyList = false;
	},
	[gotErrorGettingVenues.type]: (state, action) => {
		state.venue_list_status = 'Error Fetching Venues';
		console.log(
			'[propertyReducer] > ',
			state.venue_list_status,
			': ',
			action.payload
		);
	},
	[addingNewVenue.type]: (state, action) => {
		state.status = 'Async Task Started';
	},
	[addedNewVenue.type]: (state, action) => {
		state.status = 'Async Task ended in Success';
		state.venues_list.push(action.payload.new_venue);
	},
	[gotErrorAddingNewVenue.type]: (state, action) => {
		state.status = 'Async Task ended with Error';
		state.error = action.payload.error;
	},
	[delistVenue.type]: (state, action) => {
		state.status = 'Async Task ended with Error';
		state.error = action.payload.error;
	},
});
