import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import TextField from '@material-ui/core/TextField';

const TextFieldMUI = ({ label, variant, required, helpertext, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<div className='field_container'>
			{/* <label className='cont_form_tf_label' htmlFor={props.id || props.name}>
				{label}
			</label>
      <input className='cont_form_tf' /> */}
			<TextField
				className={'cont_form_tf'}
				variant={variant}
				required={required === false ? false : true}
				label={label}
				helperText={helpertext}
				{...field}
				{...props}
				// multiline
				// rowsMax="4"
				// value={field.value}
				// onChange={field.handleChange}
			/>
			{meta.touched && meta.error ? (
				<div className='cont_form_error'>{meta.error}</div>
			) : null}
		</div>
	);
};

TextFieldMUI.propTypes = {
	label: PropTypes.string,
};

TextFieldMUI.defaultProps = {
	variant: 'outlined',
};

export default TextFieldMUI;
